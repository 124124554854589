import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import { withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next";

const Status = (props) => {

    const { item, _processList } = props
    const { _id } = item;

    const [status, setStatus] = useState(null);

    useEffect(() => {

        const element = _processList?.find(item => item._id === _id);
        if(element){
            const { processName, info, status, done, createdAt, updatedAt, completionDate } = element;
            setStatus(status)
        }

    }, [_processList])

    return (
        <React.Fragment>
          {`${status}`}
        </React.Fragment>
    );
};

Status.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ ProcessMonitor }) => {

    const _processList = ProcessMonitor?.processList

    return {
        _processList,
    };
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Status)));

