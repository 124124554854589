import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  SOCIAL_LOGIN,
} from "./actionTypes"


import {
  EVENT_LOGIN_USER, REQUEST_LOGIN_USER, REQUEST_LOGIN_SUCCESS, REQUEST_LOGIN_FAIL,
  EVENT_LOGOUT_USER, REQUEST_LOGOUT_SUCCESS, REQUEST_LOGOUT_FAIL,
  
  //Checkin Login
  CHECKIN_LOGIN, CHECKIN_LOGIN_SUCCESS, CHECKIN_LOGIN_FAIL
} from "./actionTypes"

// export const loginUser = (user, history) => {

//   console.log("Loginn!!!!!----------")

//   return {
//     type: LOGIN_USER,
//     payload: { user, history },
//   }
// }

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}

//Checkin Login
// export const checkinLogin = () => ({ type: CHECKIN_LOGIN });
export const checkinLogin = () => {
  return { type: CHECKIN_LOGIN }
};
export const RequestCheckinLoginSuccess = (payload, message) => ({ type: CHECKIN_LOGIN_SUCCESS, payload, message });
export const RequestCheckinLoginFail = () => ({ type: CHECKIN_LOGIN_FAIL }); 

export const loginUser = (user, history) => ({ type: EVENT_LOGIN_USER, payload: { user, history } });
export const RequestLoginUser = () => ({ type: REQUEST_LOGIN_USER });
export const RequestLoginUserSuccess = (user) => ({ type: REQUEST_LOGIN_SUCCESS, payload: user });
export const RequestLoginUserFail = (message) => ({ type: REQUEST_LOGIN_FAIL, payload: message });

// export const logoutUser = (history) => ({ type: EVENT_LOGOUT_USER, payload: { history } });
// export const RequestLogoutUserSuccess = () => ({ type: REQUEST_LOGOUT_SUCCESS, payload: {} });
// export const RequestLogoutUserFail = (message) => ({ type: REQUEST_LOGOUT_FAIL, payload: message });