import { takeEvery, put, call } from "redux-saga/effects"

import {
  EVENT_BUYERS,
} from "./actionTypes";

import {
  requestBuyers,
  requestBuyersFail,
  requestBuyersSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getBuyersApi,
} from "../../helpers/fakebackend_helper"

function* onGetBuyers() {
  try {

    console.log("onGetBuyers!!!!xxx!!!!")

    yield put(requestBuyers())

    const response = yield call(getBuyersApi)
    yield put(requestBuyersSuccess(response))
  } catch (error) {
    yield put(requestBuyersFail(error))
  }
}

function* buyerSaga() {
  yield takeEvery(EVENT_BUYERS, onGetBuyers)
}

export default buyerSaga
