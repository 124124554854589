import {
  EVENT_GET_PROCESS_LIST,
  REQUEST_PROCESS_LIST,
  REQUEST_PROCESS_LIST_FAIL,
  REQUEST_PROCESS_LIST_SUCCESS,

  EVENT_GET_PROCESS_LIST_SILENT,
  REQUEST_PROCESS_LIST_SILENT,
  REQUEST_PROCESS_LIST_SILENT_FAIL,
  REQUEST_PROCESS_LIST_SILENT_SUCCESS,


} from "./actionTypes";

export const getProcessList = (payload) => ({ type: EVENT_GET_PROCESS_LIST, payload });

export const requestProcessList = () => ({ type: REQUEST_PROCESS_LIST });
export const requestProcessListFail = (payload) => ({ type: REQUEST_PROCESS_LIST_FAIL, payload });
export const requestProcessListSuccess = (payload) => ({ type: REQUEST_PROCESS_LIST_SUCCESS, payload });

export const getProcessListSilent = (payload) => ({ type: EVENT_GET_PROCESS_LIST_SILENT, payload });

export const requestProcessListSilent = () => ({ type: REQUEST_PROCESS_LIST_SILENT });
export const requestProcessListSilentFail = (payload) => ({ type: REQUEST_PROCESS_LIST_SILENT_FAIL, payload });
export const requestProcessListSilentSuccess = (payload) => ({ type: REQUEST_PROCESS_LIST_SILENT_SUCCESS, payload });