import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import "./test.css"

import DataTable from "./dataTable";


import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import { Table } from 'reactstrap';

import axios from "axios";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import EditModal from './EditModal';

// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import Spinner from '../../components/Common/spinner/Spin';

import { useFormik } from "formik";

import { TagsInput } from "react-tag-input-component";

import {
  Form,
  FormFeedback,
  Label,
} from "reactstrap";

import { config } from "../../config";

import * as Yup from "yup";

import styled from '@emotion/styled'

const StyledTable = styled(Table)`
  th, td {
    background-color: white;
    /* Otros estilos para los elementos <th> si es necesario */
  }

  .updated {
    background-color: #79f0b0cf;
    /* Otros estilos para los elementos <th> si es necesario */
  }

  .notFound {
    background-color: #f07983cf;
    /* Otros estilos para los elementos <th> si es necesario */
  }
`;


function generateQueryString(data) {

  if (!data?.length)
    return '';

  const queryString = data.map(item => `${item.name}=${encodeURIComponent(item.value)}`).join('&');
  return `?${queryString}`;
}



const RawData = (props) => {

  const { BACKEND_URL } = config

  const { buyers, fileList, lineChart, dispLoading } = props

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [domainSlections, setDomainSlections] = useState([])
  const [userAgentSlections, setUserAgentSlections] = useState([])
  const [requestUrl, setRequestUrl] = useState('')
  const [selected, setSelected] = useState([]);
  const [paramsList, setParamsList] = useState([]);

  const [rawDataList, setRawDataList] = useState([]);

  const [serverRawDataList, setServerRawDataList] = useState(null);



  const [isLoading, setIsLoading] = useState(false);

  const [isLoading2, setIsLoading2] = useState(false);

  const [count, setCount] = useState(0);

  const [clientUrl, setClientUrl] = useState("");

  const getDomainselections = async () => {

    if (!isLoading) {

      setIsLoading(true);

      setCount(count + 1)

      let url = `${BACKEND_URL}/domain/get-selections`
      let { data } = await axios.get(url)

      console.log(`data${count}: `, data)

      if (data?.success) {
        const { domainSlections } = data

        setDomainSlections(domainSlections)
      }

      setIsLoading(false);

    }

  }

  useEffect(() => {

    getDomainselections()

  }, [])

  useEffect(() => {

  }, [paramsList])


  useEffect(() => {

    const urlParams = generateQueryString(paramsList);
    const url = urlParams

    setClientUrl(url)

  })



  const getRawData = async () => {
    if (!isLoading) {

      setIsLoading(true);

      let _count = count + 1

      setCount(_count)

      let url = `${BACKEND_URL}/db/custom`
      let { data } = await axios.get(url)

      console.log(`data-${count}: `, data)

      if (data?.success) {
        const { respData } = data

        setRawDataList(respData)
      }

      setIsLoading(false);
    }
  }

  const getServerRawData = async () => {
    if (!isLoading2) {

      setIsLoading2(true);

      let _count = count + 1

      setCount(_count)

      let payload = {
        "type": "dynamic"
    }
      let url = `http://localhost:8000/api/feed_monitor`
      let { data } = await axios.post(url, payload)

      console.log("datazzxx", data)

      if (data) {
        setServerRawDataList(data)
      }

      setIsLoading2(false);
    }
  }

  const sampleData = [
    { id: 1, Domain: 'Value 1A', 
    expandedContent: [
      { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F' },
      { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F' },
      { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F' }] },
    { id: 2, Domain: 'Value 2A' },
    { id: 3, Domain: 'Value 3A' },
    { id: 4, Domain: 'Value 3A' },
    { id: 5, Domain: 'Value 3A' },
    { id: 6, Domain: 'Value 3A' },
    { id: 7, Domain: 'Value 3A' },
    { id: 8, Domain: 'Value 3A' },
    { id: 9, Domain: 'Value 3A' },
    { id: 10, Domain: 'Value 3A' },
    { id: 11, Domain: 'Value 3A' },
    { id: 12, Domain: 'Value 3A' },
    { id: 13, Domain: 'Value 3A' },
    { id: 14, Domain: 'Value 3A' },
    { id: 15, Domain: 'Value 3A' },
    // ... puedes agregar más filas según sea necesario
  ];
  

  // , 

  // useEffect(() => {

  //   getRawData();

  // }, [])

  // useEffect(() => {
  //   const onGetRawData = setInterval(() => {
  //     getRawData();
  //   }, 1000);

  //   // Llamar a la función inicial
  //   // getRawData();

  //   // Limpiar el intervalo cuando el componente se desmonta
  //   return () => {
  //     clearInterval(onGetRawData);
  //   };
  // }, []);


  // useEffect(() => {
  //   const onGetServerRawData = setInterval(() => {
  //     getServerRawData();
  //   }, 1000);

  //   // Llamar a la función inicial
  //   // getRawData();

  //   // Limpiar el intervalo cuando el componente se desmonta
  //   return () => {
  //     clearInterval(onGetServerRawData);
  //   };
  // }, []);


  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '100vh',
        }}
      >
        <Container fluid style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '80vh',
          // border: '1px solid black'
        }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Raw Data")}
            breadcrumbItem={props.t("Raw Data")}
          />
          {/* <Row style={{
            margin: 10, height: "100%",
            // border: "1px solid red" 
          }}>
            <Col sm="12">
              <Card style={{ height: "100%" }} >
                <CardBody>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="my-2 h2">Data</div>
                  </div>

                  {
                    rawDataList?.length ?
                      rawDataList?.map((item, key) => {
                        return (<div key={key}>
                          {`${item['name']}: ${item['count']}`}
                        </div>)
                      }) :
                      <div>{'Loading...'}</div>
                  }

                  <br/>

                  {
                  serverRawDataList?
                  Object.entries(serverRawDataList).map(([name, value]) => (
                    <div key={name}>
                      {`${name}: ${value}`}
                    </div>
                  )): 
                  <div>{'Loading...'}</div>
                }


                </CardBody>
              </Card>
            </Col>
          </Row> */}

          <DataTable data={sampleData}/>

        </Container>
      </div>
      <EditModal
        toggle={toggle}
        isOpen={modal}
        onSubmit={async (values) => {

          console.log("Value", values)

          const data = userAgentSlections
          data?.push({ id: userAgentSlections?.length, name: values.agent_name, value: values.agent_string })
          setUserAgentSlections(data)

          setModal(false)
        }}
      />
    </React.Fragment>
  );
};

RawData.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {


  return {

  };
}

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(RawData)));

