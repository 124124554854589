import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';
import { UncontrolledTooltip } from "reactstrap";

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

import axios from "axios";


export const Actions = (props) => {

    const { onDelete, onEdit, cell } = props
    // console.log("cell", cell)
    // console.log("cell?.value", cell?.row?.values)
    return <>
        <ul className="list-unstyled hstack gap-1 mb-0">


            {(true) && (<li data-bs-toggle="tooltip" data-bs-placement="top">
                <Link to="#" className="btn btn-sm btn-soft-info"
                    onClick={() => onEdit(cell?.row?.original)}
                >
                    <i className="mdi mdi-pencil-outline" id="editcompanytooltip" />
                    {/* <UncontrolledTooltip placement="top" target="editcompanytooltip">{`${t("Edit Company")} ${title && (title)}`}</UncontrolledTooltip> */}
                </Link>
            </li>)}

            {(true) && (<li data-bs-toggle="tooltip" data-bs-placement="top">
                <Link to="#"
                    onClick={() => onDelete(cell?.row?.original?.id)}
                    className="btn btn-sm btn-soft-primary">
                    <i className="bx  bx-trash" id="deletetooltip" ></i>
                    {/* <UncontrolledTooltip placement="top" target="viewtooltip">{`${t("View Company")} ${title && (title)}`}</UncontrolledTooltip> */}
                </Link>
            </li>)}

        </ul>
    </>;
}


export const DomainName = (cell) => {

    console.log("Domain Name", cell.value)

    return cell.value ? cell.value : '';
}

export const Hosted = (cell) => {

    return cell.value ? `${cell.value}` : '';
}

export const PubExclusive = (cell) => {

    return cell.value;
}


export const LandersCount = (cell) => {

    return cell.value;
}


export const PublisherName = ({ cell }) => {
    //console.log("cell", cell)
    const { row } = cell
    const data = row?.original;

    const [publisherList, setPublisherList] = useState([]);
    const [publisherName, setPublisherName] = useState();

    const getPublisherList = async () => {
        let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
        let url = `https://api.ringba.com/v2/RA27f335147c2d487fba01c2732feaecb0/Publishers`
        let { data } = await axios.get(url, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })

        if (data) {
            const { publishers } = data
            setPublisherList(publishers)
        }

    }

    useEffect(() => {
        getPublisherList()
    }, [])

    useEffect(() => {
        const publisherName = publisherList?.filter((item) => item?.id == cell.value)[0]?.name

        setPublisherName(publisherName)
    }, [publisherList])

    return publisherName;
}
export const PublisherId = (cell) => {

    return cell.value ? cell.value : '';
}

export const Registrar = (cell) => {

    return cell.value ? cell.value : '';
}

export const Status = (cell) => {
    return <Badge
        className={"font-size-11 badge-soft-" +
            (cell.value? "success" : "danger")}
    >
        {cell.value ? 'Available' : 'Not Available'}
    </Badge>;
};

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
        str === "" || str === undefined ? "" : str.toLowerCase()
    );
};

const CheckBox = (cell) => {
    return cell.value ? cell.value : '';
};

const OrderId = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const BillingName = (cell) => {
    return cell.value ? cell.value : '';
};

const Date = (cell) => {
    return cell.value ? cell.value : '';
};

const Total = (cell) => {
    return cell.value ? cell.value : '';
};

const PaymentStatus = (cell) => {
    return (
        <Badge
            className={"font-size-11 badge-soft-" +
                (cell.value === "Paid" ? "success" : "danger" && cell.value === "Refund" ? "warning" : "danger")}
        >
            {cell.value}
        </Badge>
    )
};
const PaymentMethod = (cell) => {
    return (
        <span>
            <i
                className={
                    (cell.value === "Paypal" ? "fab fa-cc-paypal me-1" : "" ||
                        cell.value === "COD" ? "fab fas fa-money-bill-alt me-1" : "" ||
                            cell.value === "Mastercard" ? "fab fa-cc-mastercard me-1" : "" ||
                                cell.value === "Visa" ? "fab fa-cc-visa me-1" : ""
                    )}
            />{" "}
            {cell.value}
        </span>
    )
};
export {
    CheckBox,
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod
};