import {
  REQUEST_BUYERS,
  REQUEST_BUYERS_FAIL,
  REQUEST_BUYERS_SUCCESS,
 
} from "./actionTypes";

const INIT_STATE = {
  buyers: [],
  message: '',
  loading: false,
  failed: false
};

const buyer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_BUYERS:
      return {
        ...state,
        buyers: [],
        message: '',
        loading: true,
        failed: false
      };
    
    case REQUEST_BUYERS_SUCCESS:
      console.log("REQUEST_BUYERS_SUCCESS", action.payload)
      return {
        ...state,
        failed: false,
        loading: false,
        message: action.message,
        buyers: action.payload?.data
      };
  
    case REQUEST_BUYERS_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };

    default:
      return state;
  }
};

export default buyer;
