import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import 'bootstrap/dist/css/bootstrap.min.css'; // Asegúrate de importar el CSS de Bootstrap

function DateInput({ label, initialValue, onDateChange, name, id, type, refresh, style }) {
  const [startDate, setStartDate] = useState(initialValue || '');
  const [maxDate, setMaxDate] = useState('');

  useEffect(() => {
    const today = new Date();
    setMaxDate(format(today, 'yyyy-MM-dd'));
  }, []);

  const handleDateChange = (event) => {
    const newDate = new Date(event.target.value);
    setStartDate(event.target.value);

    if (onDateChange) {
      onDateChange(newDate);
    }
  };

  useEffect(() => {
    if (refresh) {
      setStartDate(new Date());
      onDateChange && onDateChange(null);
    }
  }, [refresh]);

  return (
    <div className="form-group" style={{ ...style, marginTop: "-20px" }}>
      <label 
      style={{ marginBottom: "0px" }}
      htmlFor={id ?? "start"}>{label ?? "Start date:"}</label>
      <input
        className="form-control"
        type="date"
        id={id ?? "start"}
        name={name ?? "trip-start"}
        value={startDate}
        max={maxDate}
        onChange={handleDateChange}
      />
    </div>
  );
}

export default DateInput;