import React, { useEffect, useState, ChangeEvent } from "react"
import csv from 'csvtojson';
import FCard from './fileCard';
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
// import { Row, Col, BreadcrumbItem } from "reactstrap"
import { withRouter } from "react-router-dom";
// import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";

import { css } from '@emotion/react'

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Badge,
} from "reactstrap";


import styled from '@emotion/styled'

const SCard = styled(Card)`
  ${({isDragging})=>{

    if(isDragging)
      return css`
        transition: transform 0.5s;
        transform: scale(1.05);
      `
  }}
  transition: transform 0.5s;
  &:hover {
    color: #10C772;
    transform: scale(1.05);
  }
`;


const DropZone = props => {

  const [jsonData, setJsonData] = useState([]);
  const [file, setFile] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [imageUrl] = useState("https://www.flaticon.com/free-icon/csv-file-format_1263920");

  const color = 'white'
  useEffect(() => {
    setFile(null)
  }, [])


  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files?.[0];
    if (file) {
      await convertCsvToJson(file);
      setFile(file)
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      await convertCsvToJson(file);
      setFile(file)

    }
  };

  const convertCsvToJson = async (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const contents = e.target?.result;
      const jsonArray = await csv().fromString(contents);
      setJsonData(jsonArray);
    };

    reader.readAsText(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleClick = () => {
    const fileInput = document.getElementById('csvFileInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <Row style={{ marginTop: 50 }}>
      <Col lg="6"

        style={{ margin: "Auto" }}>
        <SCard isDragging={isDragging}
          style={{
            height: 250,
            borderRadius: 50,
          }}
        >

          <CardBody style={{ display: 'grid', placeItems: 'center' }}>
            {
              file ? <>
                <FCard
                  title={file?.name}
                  // imageUrl={true}
                  clear={() => {
                    setFile(null)
                  }}
                />
              </> :
                <>
                  <div
                    className={`drop-file-upload ${isDragging ? 'dragging' : ''}`}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onClick={handleClick}
                    style={{
                      width: '95%',
                      margin: '0 auto',
                      display: 'inline-block',
                      border: '2px dashed gray',
                      height: '200px',
                      borderRadius: '50px',
                      padding: '20px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      ...(isDragging && { borderColor: "#77DD77" })
                    }}
                  >
                    <input
                      type="file"
                      accept=".csv"
                      onChange={handleFileUpload}
                      style={{ display: 'none', cursor: 'pointer', }}
                      id="csvFileInput"
                    />
                    <p style={{
                      cursor: 'pointer',
                      fontSize: "20px",
                      marginTop: "40px",
                      letterSpacing: "3px",
                      lineHeight: "26px",
                      fontWeight: 400,
                      textAlign: "center",
                      ...(isDragging && { color: "#77DD77" })
                    }}>
                      {"Haz clic aquí o".toUpperCase()} <br /> <span>{"Arrastra un archivo".toUpperCase()}</span>
                    </p>
                  </div>
                </>
            }
          </CardBody>
        </SCard>
      </Col>
    </Row>
  )
}

DropZone.propTypes = {

}

export default DropZone
