import React from 'react';

import Spinner from 'react-bootstrap/Spinner';

function Loader(props) {
    const { isLoading, style } = props;
    if (isLoading)
        return (
            <div style={{ ...style, marginLeft: "5px", paddingLeft: "10px", paddingRight: "10px"  }}>
                <Spinner animation="border" role="status" variant="danger">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );

    return null
}

export default Loader;