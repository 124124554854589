import React, { useEffect, useState } from 'react';
import './styles.css'; // Asegúrate de tener un archivo de estilos

const LogList = ({ cards, onChange }) => {
  const [activeCard, setActiveCard] = useState(null);
  const [selected, setSelected] = useState("");
  
  const handleCardClick = (cardId) => {
    setActiveCard(cardId === activeCard ? null : cardId);
  };

  useEffect(()=>{
    onChange(selected)
  }, [selected])

  return (
    <div style={{ display: 'flex' }}>
      {cards?.map((card) => (
        <div
          key={card.id}
          style={{ marginRight: '20px' }}
          onClick={() => {
            handleCardClick(card?.id)
            setSelected(card?.title)
          }}
        >
          <div
            className={`listCard ${activeCard === card.id ? 'activeCard' : ''}`}
            style={{ padding: '10px' }}
          >
            <h5>{card.title}</h5>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LogList;
