import React, { useEffect, useState, useMemo } from "react";
import Switch from '@mui/material/Switch';
import CSVExportButton from "./CSVExportButton";

import moment from "moment";
import {
    Table,
    Badge,
    Button,
    Row,
    Col,
} from "reactstrap";

import { withTranslation } from "react-i18next";

import DateSelector from '../DateSelector/DateSelector';


import styled from '@emotion/styled'

const StyledTable = styled(Table)`
  th, td {
    background-color: white;
    /* Otros estilos para los elementos <th> si es necesario */
  }

  .updated {
    background-color: #79f0b0cf;
    /* Otros estilos para los elementos <th> si es necesario */
  }

  .notFound {
    background-color: #f07983cf;
    /* Otros estilos para los elementos <th> si es necesario */
  }

  .outOfRange {
    background-color: #f0e679cf;
    /* Otros estilos para los elementos <th> si es necesario */
  }
`;

//#79f093cf

const formateDate = (date, format) => {
    if ((date == "NONE") || (!date)) {
        return "--";
    }

    const dateFormat = format ? format : "MM/DD/Y hh:mm";
    const date1 = moment(new Date(date)).format(dateFormat);
    if (date1 == "Invalid date")
        return "--"
    return date1;
};

const BootstrapTable = ({ data, selectedInfo, title, onPageChange, dataLength }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(false);
    const [currentItems, setCurrentItems] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [totalNumber, setTotalNumber] = useState(0)


    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const handleChangePage = (newPage) => {
        setCurrentPage(newPage);
    };

    const status = (item) => {
        if (item?.updated && checkSelected("Updated"))
            return "updated"

        if (item?.notFound && checkSelected("Not Found"))
            return "notFound"

        if (item?.outOfRange && checkSelected("Out of Range"))
            return "outOfRange"
    }

    const checkSelected = (data) => {
        if (selectedInfo) {
            for (const item of selectedInfo) {
                if (item.title === data) {
                    return true;
                }
            }
        }
        return false
    }

    const filtrar = (data, checked) => {

        if (checked) {
            return data?.filter((item) => status(item));
        }

        return data;

    }

    useEffect(() => {
        const itemsPerPage = 10;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;

        const filteredData = filtrar(data, checked)

        const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

        const totalPages = Math.ceil(filteredData.length / itemsPerPage);

        setCurrentItems(currentItems)
        setTotalPages(totalPages)
    }, [data, checked, selectedInfo, currentPage])


    useEffect(() => {
        const itemsPerPage = 10;
        const totalNumber = Math.ceil(dataLength / itemsPerPage);
        setTotalNumber(totalNumber)
    }, [dataLength])


    useEffect(() => {
        if( currentPage >= totalPages){
            handleChangePage(totalPages)
        } else if((totalPages>0)&&(currentPage<1)){
            handleChangePage(1)
        }
    }, [totalPages])

    useEffect(() => {
        onPageChange && onPageChange({currentPage, totalPages})
    }, [currentPage])
    
    useEffect(() => {
        setCurrentPage(1)
        console.log("setCurrentPage(1)::", dataLength)
    }, [dataLength])

    return (
        <React.Fragment>
            <h3>{title}</h3>
            <Row>
                <Col sm={2}>
                    <div>
                        <Switch
                            size="small" // Tamaño pequeño
                            checked={checked}
                            onClick={handleChange}
                            color="primary" // Cambia el color del switch si lo deseas
                            inputProps={{ 'aria-label': 'small switch' }}
                        /> Activate Filter
                    </div>
                </Col>
                <Col className="d-flex align-items-center">
                    <CSVExportButton data={data} filename={title} />
                    <div className="mx-2"></div>
                    <div className="mx-2"></div>
                </Col>
            </Row>
            <StyledTable>
                <thead>
                    <tr >
                        <th>No.</th>
                        <th >PhoneNumber</th>
                        <th>Date</th>
                        <th>date_time</th>
                        <th>Sales</th>
                        <th>BuyerID</th>
                        <th>BuyerName</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item, index) => (

                        <tr key={index}>
                            <th className={status(item)} scope="row">{index + (10 * (currentPage - 1))}</th>
                            <td className={status(item)} >{item?.PhoneNumber}</td>
                            <td className={status(item)}  >{item?.Date}</td>
                            <td className={status(item)} style={{ textAlign: 'center' }}>{formateDate(item?.date_time)}</td>
                            <td className={status(item)}  >{item?.Sales}</td>
                            <td className={status(item)}  >{item?.BuyerID}</td>
                            <td className={status(item)}  >{item?.BuyerName ? item?.BuyerName : '-'}</td>
                        </tr>

                    ))}
                </tbody>
            </StyledTable>
            <div>
                <Button style={{ margin: 10 }}
                    onClick={() => handleChangePage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </Button>
                <span>Page {currentPage} of {totalNumber}</span>
                <Button style={{ margin: 10 }}
                    onClick={() => handleChangePage(currentPage + 1)}
                    disabled={(currentPage >= totalPages)}
                >
                    Next
                </Button>
            </div>
        </React.Fragment>
    );
};

BootstrapTable.propTypes = {

};

export default withTranslation()(BootstrapTable);
