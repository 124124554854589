import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import { withRouter } from "react-router-dom"

import {
    Progress as RProgress
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

const Progress = (props) => {

    const { item, _processList } = props
    const { _id } = item;

    const [processName, setProcessName] = useState(null);
    const [status, setStatus] = useState(null);
    const [porsentage, setPorsentage] = useState(null);

    useEffect(() => {

        const element = _processList?.find(item => item._id === _id);
        if(element){
            const { processName, info, status, done, createdAt, updatedAt, completionDate } = element;
            const { completed, total, type, progressPercentage, progressTotal, progresscount } = info
            const porsentage = (type == "Disposition") ? progressPercentage : (((completed) / total) * 100)
    
            setProcessName(processName)
            setStatus(status)
            setPorsentage(porsentage)
        }

    }, [_processList])


    return (
        <React.Fragment>
            {processName}
            <RProgress
                animated={(status == "InProgress")}
                value={porsentage}
                style={{
                    backgroundColor: '#ebebeb',
                    // '--bs-progress-bar-bg': '#616161' 
                    // '--bs-progress-bar-bg': '#d7424770'
                }}
            />
        </React.Fragment>
    );
};

Progress.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ ProcessMonitor }) => {

    const _processList = ProcessMonitor?.processList

    return {
        _processList,
    };
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Progress)));

