import {
  REQUEST_RTB_FILE_LIST,
  REQUEST_RTB_FILE_LIST_FAIL,
  REQUEST_RTB_FILE_LIST_SUCCESS,

  REQUEST_RTB_LOGS_FILE_LIST,
  REQUEST_RTB_LOGS_FILE_LIST_FAIL,
  REQUEST_RTB_LOGS_FILE_LIST_SUCCESS,

  REQUEST_RTB_LOGS_PUBLISHER,
  REQUEST_RTB_LOGS_PUBLISHER_FAIL,
  REQUEST_RTB_LOGS_PUBLISHER_SUCCESS,

} from "./actionTypes";

const INIT_STATE = {
  fileList: [],
  logsFileList: [],
  publisherList: [],
  message: '',
  loading: false,
  failed: false
};

const rtbFile = (state = INIT_STATE, action) => {
  switch (action.type) {

    case REQUEST_RTB_FILE_LIST:
      return {
        ...state,
        fileList: [],
        message: '',
        loading: true,
        failed: false
      };

    case REQUEST_RTB_FILE_LIST_SUCCESS:
      return {
        ...state,
        failed: false,
        loading: false,
        message: action.message,
        fileList: action.payload?.data
      };

    case REQUEST_RTB_FILE_LIST_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };

    //--------------

    case REQUEST_RTB_LOGS_FILE_LIST:
      return {
        ...state,
        logsFileList: [],
        message: '',
        loading: true,
        failed: false
      };

    case REQUEST_RTB_LOGS_FILE_LIST_SUCCESS:
      return {
        ...state,
        failed: false,
        loading: false,
        message: action.message,
        logsFileList: action.payload?.data
      };

    case REQUEST_RTB_LOGS_FILE_LIST_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };

    //--------------

    case REQUEST_RTB_LOGS_PUBLISHER:
      return {
        ...state,
        publisherList: [],
        message: '',
        loading: true,
        failed: false
      };

    case REQUEST_RTB_LOGS_PUBLISHER_SUCCESS:
      return {
        ...state,
        failed: false,
        loading: false,
        message: action.message,
        publisherList: action.payload?.data
      };

    case REQUEST_RTB_LOGS_PUBLISHER_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };

    default:
      return state;
  }
};

export default rtbFile;
