import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";

// import Logger from "../../helpers/Logger/Logger";

import FCard from "../../components/Common/Import/fileCard";

import ScreenA from "./ScreenA";
import ScreenB from "./ScreenB";
import ScreenC from "./ScreenC";
import ScreenD from "./ScreenD";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import Select from 'react-select'

import { Link } from "react-router-dom";
// import Card from 'react-bootstrap/Card';

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//import action
import { getChartsData as onGetChartsData, } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch, connect } from "react-redux";

import Spinner from '../../components/Common/spinner/Spin';

import { config } from "../../config";

import DateSelector from '../../components/Common/DateSelector/DateSelector';
import DateInput from '../../components/Common/DateInput/DateInput';
// import PolicyBindLogger from "policybindlogger";

// const logger = new PolicyBindLogger('dispositionSite');
// logger.init()

import Switch from '@mui/material/Switch';



export const HomeScreen = (props) => {

  const { buyers, fileList, lineChart, dispLoading } = props
  const { onGetBuyers, onCheckinLogin, onGetFileHistory, onSetProcess, onSetProcessNew, onGetLineChart, onSetDateFilter } = props

  const [buyerList, setBuyerList] = useState(buyers);
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadScreen, setUploadScreen] = useState(true);
  const [data, setData] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [buyer, setBuyer] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const [index, setIndex] = useState(2);

  const [refresh, setRefresh] = useState(false);

  const [checked, setChecked] = useState(true);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const { BACKEND_URL } = config

  // const [progres, setProgres] = useState(0);

  useEffect(() => {

    console.log("Hola mundo")

    console.log("startDate::", startDate)
    console.log("endDate::", endDate)

    onSetDateFilter({ startDate, endDate })

  }, [startDate, endDate]);

  useEffect(() => {
    dispatch(onGetChartsData("yearly"));
  }, [dispatch]);

  useEffect(() => {
    onCheckinLogin()
    onGetBuyers()
    onGetFileHistory("?offset=0&limit=6")
    onGetLineChart()
  }, []);

  useEffect(() => {
    // console.log("buyers", buyers)
    setBuyerList(buyers)
  }, [buyers]);

  //meta title
  document.title = "Home | React Admin";

  useEffect(() => {
    fileUploadRef.current.value = null;
    setFile(null)
    setIsLoading(false)
    setData(false)
  }, [])

  useEffect(() => {
    isLoading && setTimeout(() => {

      setData({
        total: {
          title: "Total Numbers Procesed",
          value: 100
        },
        notFound: {
          title: "Numbers Not Found",
          value: 15
        }
      })

      fileUploadRef.current.value = null;
      setFile(null)

      setIsLoading(false);
    }, 1000);
  }, [isLoading])

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      await convertCsvToJson(file);
      setFile(file)
    }
  };

  const convertCsvToJson = async (file) => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      const contents = e.target?.result;
      const jsonArray = await csv().fromString(contents);
      setJsonData(jsonArray);
    };
    reader.readAsText(file);
  };

  const handleClick = () => {
    const fileInput = document.getElementById('csvFileInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleClickFix = async () => {
    let url = `${BACKEND_URL}/distposition/fix`
    let { data } = await axios.get(url)

    if (data?.success) {
      console.log("handleClickFix::data::", data?.data)
      onGetFileHistory()
    }

  };

  const fileUploadRef = useRef(null)

  useEffect(() => {
    console.log("file", file)
  }, [file])

  useEffect(() => {
    console.log("file", file?.name)
    console.log("jsonData", jsonData)
    console.log("buyer", buyer)
  }, [jsonData, file, buyer])


  useEffect(() => {
    if (!file) {
      setJsonData([])
    }
  }, [file])


  useEffect(() => {
    if (!dispLoading) {
      fileUploadRef.current.value = null;
      setFile(null)
      onGetLineChart()
    }
  }, [dispLoading])

  useEffect(() => {
    // console.log("lineChartxxx", lineChart)

  }, [lineChart])

  useEffect(() => {
    console.log("buyer--", buyer)
  }, [buyer])

  useEffect(() => {
    console.log("DATE **endDate: ", endDate)
    console.log("DATE **startDate: ", startDate)
  }, [endDate, startDate])


  // useEffect(() => {
  //   console.log("fileList:: ", fileList)

  // }, [fileList[0]?.data])

  useEffect(() => {

    if (refresh) {
      setRefresh(false)
    }

  }, [refresh])


  useEffect(() => {
    console.log("buyerList:: ", buyerList)

  }, [buyerList])

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '100vh',
          // border: '1px solid black',
          // overflow: 'hidden'
        }}
      >
        <Container fluid style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '80vh',
          // border: '1px solid black'
        }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Homes")}
            breadcrumbItem={props.t("Home")}
          />
          <Row style={{ margin: 10 }}>
            <Col sm="3">
              <div>
                <Switch
                  size="small" // Tamaño pequeño
                  checked={checked}
                  onClick={handleChange}
                  color="primary" // Cambia el color del switch si lo deseas
                  inputProps={{ 'aria-label': 'small switch' }}
                /> asynchronous for large files
              </div>
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col sm="3">
              <Select options={buyerList}
                onChange={(value) => {
                  setBuyer({
                    id: value?.value,
                    name: value?.label
                  })
                }}
              />
            </Col>
            <Col sm="1">
              {
                file ?
                  <Button color="success" disabled={isLoading} onClick={async () => {
                    // setIsLoading(true)

                    if ((jsonData?.length > 10000) && checked) {
                      await onSetProcessNew({
                        fileName: file?.name,
                        data: jsonData,
                        buyer: buyer
                      })
                    } else {
                      await onSetProcess({
                        fileName: file?.name,
                        data: jsonData,
                        buyer: buyer
                      })
                    }

                  }}>
                    Send
                  </Button>
                  : <Button disabled={!buyer} onClick={handleClick}>
                    import
                  </Button>
              }
              <input
                ref={fileUploadRef}
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                style={{ display: 'none', cursor: 'pointer', }}
                id="csvFileInput"
              />
            </Col>
            <Col sm="3" className="d-flex align-items-center">
              {/* Resto del contenido */}
              <DateInput
                refresh={refresh}
                onDateChange={(date) => {
                  setStartDate(date)
                }}
              />
              <div className="mx-2"></div>
              <div className="mx-2"></div>
              <DateInput
                label={"End date:"}
                refresh={refresh}
                onDateChange={(date) => {
                  console.log("endDate::--", date)
                  setEndDate(date)
                }}
              />
              <div className="mx-2"></div>
              <Button
                color="info"
                size="sm"
                className="example-custom-input"
                onClick={() => {
                  setRefresh(true)
                }}
              >
                {"Refresh"}
              </Button>
              <Spinner isLoading={dispLoading} />
            </Col>
            <Col sm={2}>

            </Col>
          </Row>

          {file && <Row style={{ margin: 10 }}>
            <Col sm="4">
              <FCard
                title={file?.name}
                // imageUrl={true}
                clear={() => {
                  fileUploadRef.current.value = null;
                  setFile(null)
                }}
              />
            </Col>
          </Row>
          }

          {/* 
            Aqui debe haber una seccion que te permita ver el progreso de el upload de la data
          */}


          <Row style={{
            margin: 10,
            // border: '1px solid black'
          }}>
            <Col>
              <ScreenC dateRange={{ endDate, startDate }} data={fileList} chart={lineChart} />
            </Col>
          </Row>

          {/* <ScreenB /> */}

        </Container>
      </div>
    </React.Fragment>
  );
};

HomeScreen.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};



