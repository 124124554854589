import React from 'react';
import './styles.css'; // Asegúrate de tener un archivo de estilos
import LogTable from '../LogTable';

const Tarjeta = ({logScreen, logs}) => {

  return (
    <div className="tarjeta">
      <h2 className="titulo">Log Screen: {logScreen}</h2>
      <LogTable logs={logs} />
    </div>
  );
};

export default Tarjeta;