import { takeEvery, put, call } from "redux-saga/effects"

import {
  EVENT_GET_FILE_LIST, EVENT_PROCESS,
  EVENT_GET_FILE_LIST_SCROLL,
  EVENT_GET_LIST_DATA,
  EVENT_SET_DATE_FILTER,
  EVENT_GET_DATA_DATE,
  EVENT_DELETE_FILE,
  EVENT_PROCESS_NEW
} from "./actionTypes";

import {
  requestFileHistory,
  requestFileHistoryFail,
  requestFileHistorySuccess,

  requestProcess,
  requestProcessFail,
  requestProcessSuccess,

  requestFileHistoryScroll,
  requestFileHistoryScrollFail,
  requestFileHistoryScrollSuccess,

  requestListData,
  requestListDataSuccess,
  requestListDataFail,

  requestSetDateFilter,

  requestOneDataDate,
  requestOneDataDateFail,
  requestOneDataDateSuccess,

  setDeleteFile,

  requestProcessNew,
  requestProcessNewFail,
  requestProcessNewSuccess

} from "./actions"

//Include Both Helper File with needed methods
import {
  getFileHistoryApi,
  setProcessApi,
  getListDataApi,
  getOneDataDateApi,
  setProcessNewApi
} from "../../helpers/fakebackend_helper"

function* onGetFileHistory({ payload: body }) {
  try {
    yield put(requestFileHistory())
    const query = body ? body : ""

    console.log("onGetFileHistory::", query)

    const response = yield call(getFileHistoryApi, query)
    yield put(requestFileHistorySuccess(response))
  } catch (error) {
    yield put(requestFileHistoryFail(error))
  }
}

function* onGetListData({ payload: body }) {
  try {
    yield put(requestListData())

    const query = body ? body : ""
    const response = yield call(getListDataApi, query)

    yield put(requestListDataSuccess(response))
  } catch (error) {
    yield put(requestListDataFail(error))
  }
}

function* onGetOneDataDate({ payload: body }) {
  try {
    yield put(requestOneDataDate())

    const query = body? body : ""

    console.log("query:::", query)

    const response = yield call(getOneDataDateApi, query)

    yield put(requestOneDataDateSuccess(response))
  } catch (error) {
    yield put(requestOneDataDateFail(error))
  }
}

function* onSetDateFilter({ payload: body }) {
  try {

    yield put(requestSetDateFilter(body))
    
  } catch (error) {
    console.log("onSetDateFilter error:", error)
  }
}

function* onGetFileHistoryScroll({ payload: body }) {
  try {
    yield put(requestFileHistoryScroll())
    const query = body ? body : ""

    console.log("onGetFileHistory::", query)

    const response = yield call(getFileHistoryApi, query)
    yield put(requestFileHistoryScrollSuccess(response))
  } catch (error) {
    yield put(requestFileHistoryScrollFail(error))
  }
}

function* onSetProcess({ payload: body }) {
  try {
    yield put(requestProcess())
    const response = yield call(setProcessApi, body)

    console.log("onSetProcess::", response)

    yield put(requestProcessSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(requestProcessFail(error))
  }
}


function* onSetProcessNew({ payload: body }) {
  try {
    console.log("onSetProcess---")
    yield put(requestProcessNew())
    const response = yield call(setProcessNewApi, body)

    console.log("responsexxx", response)

    yield put(requestProcessNewSuccess(response))
  } catch (error) {
    console.log("onSetProcess---", error)
    yield put(requestProcessNewFail(error))
  }
}

function* onDeleteFile({ payload: data }) {
  try {

    console.log("SET_DATE_FILTER:: onGetOneDataDate data:", data)

    yield put(setDeleteFile(data))

  } catch (error) {
    console.log("onGetOneDataDate error:", error)
  }
}


function* dispositionSaga() {
  yield takeEvery(EVENT_GET_FILE_LIST, onGetFileHistory);
  yield takeEvery(EVENT_GET_FILE_LIST_SCROLL, onGetFileHistoryScroll);
  yield takeEvery(EVENT_PROCESS, onSetProcess);
  yield takeEvery(EVENT_GET_LIST_DATA, onGetListData);
  yield takeEvery(EVENT_SET_DATE_FILTER, onSetDateFilter);

  yield takeEvery(EVENT_GET_DATA_DATE, onGetOneDataDate);

  yield takeEvery(EVENT_DELETE_FILE, onDeleteFile);


  yield takeEvery(EVENT_PROCESS_NEW, onSetProcessNew);



}





export default dispositionSaga
