import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";

import { useSelector, useDispatch, connect } from "react-redux";

import { getFileHistory, setProcess, getFileHistoryScroll, getListData, getOneDataDate, deleteFile } from "../../store/disposition/actions";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import Select from 'react-select'

import Table from "../../components/Common/Tabla/BostrapTable";

import LineChart from "../../components/Common/Charts/LineChart";

import { withTranslation } from "react-i18next";

import ContainerWithScroll from "../../components/Common/scrollContent/ContainerWithScroll";

import moment from "moment";

import ScreenA from "./ScreenA"

import styled from '@emotion/styled'

import { css } from '@emotion/react'
import { config } from "../../config";

import Logger from "../../helpers/Logger/Logger";


import "./loaders.css"
import { forEach } from "lodash";


const SCard = styled(Card)`
  ${({ selected, disabled }) =>
    (selected && !disabled) ?
      css`
        color: #10c772;
        outline: none; /* Para quitar el contorno predeterminado del enfoque 
        border: 2px solid green;*/
        box-shadow: 0 0 5px 2px rgba(0, 255, 0, 0.5);
      `:
      `box-shadow: 0 0 5px 2px rgba(128, 128, 128, 0.2);`
  }

  ${({ selected, disabled }) =>
    (!disabled) ?
      css`
        transition: transform 0.2s;
        &:hover {
          transform: scale(1.05);
        }
        
        /* New animation for click */
        transition: transform 0.2s;
        &.click-animation {
          transform: scale(0.95);
        }`: ``
  }
  
  ${({ selected, disabled }) =>
    (disabled) ?
      css`
      opacity: 0.5;
      cursor: default;
      `: `cursor: 'pointer'`
  }`;


const CardHeader = ({ title, onDelete, isMaster }) => {

  return (<div className="mx-3 mt-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
    <h4 className="card-title">{title}</h4>
    {isMaster && <button
      onClick={(e) => {
        e.stopPropagation();
        onDelete()
        // onDelete(key);
      }}
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        color: 'red',
      }}
    >

      <i className="fa fa-times" aria-hidden="true"></i>
    </button>
    }
  </div>
  )
}

const ScreenC = props => {

  const { setDateRange, dateRange, data, chart, dateFilter, onDeleteFile,
    onGetFileHistory, isMaster, onGetFileHistoryScroll, onGetOneDataDate,
    loading_srcoll, onGetListData, loading_list_data } = props

  // const [selectedFile, setSelectedFile] = useState({ id: 0, ...data[0] } || {})
  const [selectedFile, setSelectedFile] = useState(null)

  const [selectedId, setSelectedId] = useState(0)
  // const [selected_Id, setSelected_Id] = useState(data[0]?._id)
  const [selected_Id, setSelected_Id] = useState(null)

  // const [dataLength, setDataLength] = useState(data[0]?.dataLength)
  const [dataLength, setDataLength] = useState(null)


  const [dataList, setDataList] = useState([])
  const [chartData, setChartData] = useState([])
  const [selectedInfo, setSelectedInfo] = useState(null)

  const [currentPage, setCurrentPage] = useState(null)
  const [totalPages, setTotalPages] = useState(null)

  const [outOfRangeCount, setOutOfRangeCount] = useState(0)

  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(0);

  const [tableIndex, setTableIndex] = useState(1);


  const { BACKEND_URL } = config

  const scrollViewRef = useRef(null);

  useEffect(() => {
    console.log("REQUEST_LIST_DATA:: selectedData dataxx", data?.length)
    console.log("REQUEST_LIST_DATA:: selectedData dataxx", data)
    console.log("REQUEST_LIST_DATA:: selectedData dataxx", (!selectedFile && data?.length))
    console.log("REQUEST_LIST_DATA:: selectedData dataxx", (data?.length))
    console.log("REQUEST_LIST_DATA:: selectedData dataxx", (!selectedFile))
    console.log("REQUEST_LIST_DATA:: selectedData dataxx", selectedFile)


    if(!selectedFile && data?.length){
      const selectedData = data.find(item => item.completed === true);

      console.log("REQUEST_LIST_DATA:: selectedData", selectedData)

      if(selectedData){
        console.log("REQUEST_LIST_DATA:: selectedData", selectedData)
        // selectedData
        setSelectedFile({ id: 0, ...selectedData })
        setSelected_Id(selectedData?._id)
        setDataLength(selectedData?.dataLength)
      }
    }

  }, [data])

  useEffect(() => {
    if (selectedFile) {
      const indexToUpdate = data.findIndex(file => file._id === selectedFile?._id);
      console.log("fileList:: ", indexToUpdate)
      setSelectedId(indexToUpdate)
    }
  }, [selectedFile])

  useEffect(() => {
    if (selectedFile) {
      console.log("SET_DATE_FILTER:: ", dateFilter)
    }
  }, [dateFilter])

  useEffect(() => {

    const charData = chart?.map((item) => {
      return { x: item?.day, y: item?.count }
    })

    console.log("chart:--", chart)

    setChartData(charData)
  }, [chart])

  useEffect(() => {

    console.log("REQUEST_LIST_DATA:: dataList", dataList)

  }, [dataList])

  useEffect(() => {

    if (selectedFile) {
      const { data } = selectedFile

      if (data?.length) {
        setDataList(data)
      }

      console.log("REQUEST_LIST_DATA:: selectedFile,data", data)
      console.log("selectedFile", selectedFile)

      console.log("REQUEST_LIST_DATA:: selectedFile", selectedFile)
    }

  }, [selectedFile])


  const onDelete = async (_id) => {

    console.log("_id::", _id)

    let url = `${BACKEND_URL}/distposition/delete/${_id} `
    let { data } = await axios.delete(url)

    if (data?.success) {
      console.log("data::", data?.data)
      onDeleteFile({ _id })
    }
  }

  useEffect(() => {

    console.log("REQUEST_LIST_DATA::", selectedFile)

  }, [selectedFile])

  const fetchData = useCallback(async () => {
    if (isLoading) return;
    if (loading_srcoll) return;

    // alert("Listo")

    setIsLoading(true);

    // onGetFileHistory(`? offset = ${ index } 0 & limit=2`)
    console.log("onGetFileHistoryScroll000...")
    Logger?.logger("onGetFileHistoryScroll000...")

    onGetFileHistoryScroll(`? offset = ${index * 5}& limit=5`)
    console.log("onGetFileHistoryScroll...")
    Logger?.logger("onGetFileHistoryScroll000...")

    setIndex((prevIndex) => prevIndex + 1);

    setIsLoading(false);

    console.log("Completo...")
    Logger?.logger("Completo...")


  }, [index, isLoading, loading_srcoll]);

  useEffect(() => {
    console.log("index::", index)
    Logger?.logger("index", index)

  }, [index]);

  useEffect(() => {
    const scrollElement = scrollViewRef.current;

    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = scrollElement;

      if (scrollTop + clientHeight >= scrollHeight - 20) {
        fetchData();
      }
    };

    scrollElement.addEventListener("scroll", handleScroll);
    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, [fetchData]);

  const fetchListData = useCallback(async () => {
    if (loading_list_data) return;
    const { startDate, endDate } = dateFilter;

    const startDateString = `&startDate=${startDate}`
    const endDateString = `&endDate=${endDate}`

    onGetListData(`?id=${selectedFile?._id}${startDateString}${endDateString}&offset=${tableIndex * 50}&limit=50`)
    console.log("fetchListData:: fetchListData!!!!!!!!!", `?id=${selectedFile?._id}${startDateString}${endDateString}&offset=${tableIndex * 50}&limit=50`)

    setTableIndex((prevIndex) => prevIndex + 1);
  }, [currentPage]);

  useEffect(() => {

    console.log("fetchListData::", currentPage)
    console.log("fetchListData::", totalPages)
    console.log("fetchListData::tableIndex", tableIndex)

    console.log("fetchListData::--", totalPages && (currentPage >= (totalPages - 1)))

    console.log("fileList:: selectedFile?.dataLength", selectedFile?.dataLength)
    console.log("fileList:: selectedFile?.data?.length", selectedFile?.data?.length)
    console.log("fileList:: (selectedFile?.data?.length<selectedFile?.dataLength)", (selectedFile?.data?.length < selectedFile?.dataLength))
    console.log("fileList:: (selected_Id == selectedFile?._id)", (selected_Id == selectedFile?._id))


    console.log("fetchListData:: fetchListData!!!!!!!!! (selectedFile?.data?.length < selectedFile?.dataLength)", (selectedFile?.data?.length < selectedFile?.dataLength))

    console.log("fetchListData:: fetchListData!!!!!!!!! selectedFile?.data?.length", selectedFile?.data?.length)
    console.log("fetchListData:: fetchListData!!!!!!!!! selectedFile?.dataLength", selectedFile?.dataLength)

    console.log("fetchListData:: fetchListData!!!!!!!!! (currentPage >= (totalPages - 1))", (currentPage >= (totalPages - 1)))

    if (selected_Id == selectedFile?._id) {
      if (selectedFile?.data?.length < selectedFile?.dataLength) {
        if (currentPage >= (totalPages - 1)) {

          console.log("fetchListData:: fetchListData!!!!!!!!!----")

          fetchListData();
        }
      }
    }

    if (selected_Id != selectedFile?._id) {
      setSelected_Id(selectedFile?._id)
      setTableIndex(1)
    }

  }, [fetchListData]);

  useEffect(() => {

    console.log("REQUEST_LIST_DATA:: loading_list_data", loading_list_data)

  }, [loading_list_data]);


  useEffect(() => {
    console.log("fileList:: data[selectedId]", data[selectedId])

    console.log("fileList:: selectedFile?.dataLength", selectedFile?.dataLength)
    console.log("fileList:: selectedFile?.data?.length", selectedFile?.data?.length)
    console.log("fileList:: (selectedFile?.data?.length<selectedFile?.dataLength)", (selectedFile?.data?.length < selectedFile?.dataLength))

    if(selectedFile){
      setSelectedFile({ id: selectedId, ...data[selectedId] })
    }
    

  }, [data[selectedId]?.data])


  useEffect(() => {
    console.log("setCurrentPage(1):: --", dataLength)
    console.log("setCurrentPage(1):: --", dataLength)

    setDataLength(data[selectedId]?.dataLength)
  }, [data[selectedId]])

  useEffect(() => {

    console.log("setCurrentPage(1):: ", dataLength)

  }, [dataLength])

  const fetchDataDate = useCallback(async () => {
    if (loading_list_data) return;
    const { startDate, endDate } = dateFilter;

    console.log("REQUEST_ONE_DATA_DATE_SUCCESS:: ", dateFilter)

    const startDateString = `&startDate=${startDate}`
    const endDateString = `&endDate=${endDate}`

    onGetOneDataDate(`?id=${selectedFile?._id}${startDateString}${endDateString}&offset=${0}&limit=50`)

  }, [dateFilter]);

  useEffect(() => {
    fetchDataDate()
  }, [fetchDataDate]);

  useEffect(() => {

    console.log("DATE ** dateFilter:: ", dateFilter)

  }, [dateFilter])

  useEffect(() => {

    console.log("selectedFile::?.id", selectedFile?.id)
    console.log("selectedFile::", selectedFile)

  })

  useEffect(() => {

    console.log("data::data", data)
    console.log("data::data", data[0])

  },[data])
  

  //meta title
  document.title = "Home | React Admin";

  return (
    <React.Fragment>
      <Row>
        <Col>
          <ScreenA data={selectedFile?.info} onSelected={(data) => {
            console.log("data--", data)
            setSelectedInfo(data)
          }} />
        </Col>
      </Row>
      <Row style={{
        height: "67vh",
        //  borderWidth: 1, borderColor: 'black', borderStyle: 'solid',
      }}>
        <Col sm="3" style={{
          height: "105%",
          // borderWidth: "1px", flex: 1 
        }}>
          <ContainerWithScroll ref={scrollViewRef}>
            <Row >
              <Col>
                {
                  data && data?.map((item, key) => {

                    const { fileName, userName, updatedAt, completed } = item

                    let selected = false;

                    if (key == selectedFile?.id) // Aqui
                      selected = true


                      console.log("item::item", item)

                    return <SCard key={key} selected={selected} disabled={!completed} onClick={() => {
                      completed && setSelectedFile({ id: key, ...item })
                    }} style={{ cursor: !completed ? 'default' : 'pointer' }}>
                      <CardHeader title={fileName} onDelete={() => {  
                        if(completed){
                          onDelete(item?._id) 
                        }  
                        
                      }}
                        isMaster={true}
                      // isMaster={isMaster} 
                      />
                      <CardBody className="pt-1">
                        <p className="card-title-desc mb-2">
                          {userName}{" "}
                          {moment(updatedAt).format('DD/MM/YYYY hh:mm')}
                        </p>
                        <Row className="icon-demo-content" id="newIcons"></Row>
                      </CardBody>
                    </SCard>
                  })
                }

                {loading_srcoll ? <Row>
                  <Col className="text-center">
                    <div className="snippet" data-title="dot-elastic">
                      <div className="stage">
                        <div className="dot-elastic"></div>
                      </div>
                    </div>
                  </Col>
                </Row> : null}

              </Col>
            </Row>
          </ContainerWithScroll>
        </Col>
        <Col sm="8" style={{
          height: "100%",
          // borderWidth: "1px", flex: 1 
        }}>
          <ContainerWithScroll>
            <Row >
              <Col>
                <Card style={{ height: 280 }}>
                  <CardBody style={{ padding: '10px' }}>
                    <LineChart chart={chartData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table data={dataList} selectedInfo={selectedInfo}
                  dataLength={dataLength}
                  title={selectedFile?.fileName ? `${selectedFile?.fileName} - ${moment(selectedFile?.updatedAt).format('DD/MM/YYYY hh:mm')} ` : ''}
                  onPageChange={({ currentPage, totalPages }) => {

                    setCurrentPage(currentPage);
                    setTotalPages(totalPages);
                    console.log("fileList:: ::currentPage::", currentPage)
                    console.log("fileList:: ::totalPages::", totalPages)

                  }}
                />
              </Col>
            </Row>
          </ContainerWithScroll>
        </Col>
      </Row>
    </React.Fragment>
  );
};

ScreenA.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};


const mapStateToProps = ({ Login, Disposition }) => {

  const username = Login?.auth?.username
  const isMaster = ((username == "test") || (username == "GilbertoPB")) ? true : false;
  const loading_srcoll = Disposition?.loading_srcoll
  const loading_list_data = Disposition?.loading_list_data
  const dateFilter = Disposition?.dateFilter

  return {
    isMaster,
    loading_srcoll,
    loading_list_data,
    dateFilter
  };
}

const mapDispatchToProps = (dispatch) => ({
  onGetFileHistory: (body) => dispatch(getFileHistory(body)),
  onDeleteFile: (body) => dispatch(deleteFile(body)),
  onGetFileHistoryScroll: (body) => dispatch(getFileHistoryScroll(body)),
  onGetListData: (body) => dispatch(getListData(body)),
  onGetOneDataDate: (body) => dispatch(getOneDataDate(body))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ScreenC)));

// export default withTranslation()(withRouter(ScreenA));
