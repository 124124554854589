import {
  REQUEST_LINE_CHART,
  REQUEST_LINE_CHART_FAIL,
  REQUEST_LINE_CHART_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  lineChart: [],
  message: '',
  loading: false,
  failed: false
};

const chart = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REQUEST_LINE_CHART:
      return {
        ...state,
        lineChart: [],
        message: '',
        loading: true,
        failed: false
      };
    
    case REQUEST_LINE_CHART_SUCCESS:
      return {
        ...state,
        failed: false,
        loading: false,
        message: action.message,
        lineChart: action.payload?.data
      };
  
    case REQUEST_LINE_CHART_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };

    default:
      return state;
  }
};

export default chart;
