import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useCallback } from "react";

import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess, getListData, setDateFilter, setProcessNew } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";

import { HomeScreen } from './HomeScreen';


const HomeContainer = (props) => {

  return <HomeScreen {...props} />;
};

HomeContainer.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
  onGetListData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {

  const buyerLoading = Buyer?.loading
  const dispLoading = Disposition?.loading

  // console.log("lineChart", Chart?.lineChart)

  return {
    buyers: Buyer?.buyers,
    fileList: Disposition?.fileList,
    lineChart: Chart?.lineChart,
    dispLoading: dispLoading
  };
}

const mapDispatchToProps = (dispatch) => ({
  onGetBuyers: () => dispatch(getBuyers()),
  onCheckinLogin: () => dispatch(checkinLogin()),
  onGetFileHistory: (body) => dispatch(getFileHistory(body)),
  onSetProcess: (body) => dispatch(setProcess(body)),
  onSetProcessNew: (body) => dispatch(setProcessNew(body)),
  onGetLineChart: () => dispatch(getLineChart()),
  onSetDateFilter: (body) => dispatch(setDateFilter(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(HomeContainer)));

