/* EVENTS */
export const EVENT_GET_FILE_LIST = "EVENT_GET_FILE_LIST";
export const REQUEST_FILE_LIST = "REQUEST_FILE_LIST";
export const REQUEST_FILE_LIST_FAIL = "REQUEST_FILE_LIST_FAIL";
export const REQUEST_FILE_LIST_SUCCESS = "REQUEST_FILE_LIST_SUCCESS";


export const EVENT_PROCESS = "EVENT_PROCESS";
export const REQUEST_PROCESS = "REQUEST_PROCESS";
export const REQUEST_PROCESS_FAIL = "REQUEST_PROCESS_FAIL";
export const REQUEST_PROCESS_SUCCESS = "REQUEST_PROCESS_SUCCESS";



export const EVENT_GET_FILE_LIST_SCROLL = "EVENT_GET_FILE_LIST_SCROLL";

export const REQUEST_FILE_LIST_SCROLL = "REQUEST_FILE_LIST_SCROLL";
export const REQUEST_FILE_LIST_SCROLL_FAIL = "REQUEST_FILE_LIST_SCROLL_FAIL";
export const REQUEST_FILE_LIST_SCROLL_SUCCESS = "REQUEST_FILE_LIST_SCROLL_SUCCESS";

export const EVENT_GET_LIST_DATA = "EVENT_GET_LIST_DATA";
export const REQUEST_LIST_DATA = "REQUEST_LIST_DATA";
export const REQUEST_LIST_DATA_FAIL = "REQUEST_LIST_DATA_FAIL";
export const REQUEST_LIST_DATA_SUCCESS = "REQUEST_LIST_DATA_SUCCESS";

export const EVENT_SET_DATE_FILTER = "EVENT_SET_DATE_FILTER";
export const SET_DATE_FILTER = "SET_DATE_FILTER";

export const EVENT_GET_DATA_DATE = "EVENT_GET_DATA_DATE";
export const REQUEST_ONE_DATA_DATE = "REQUEST_ONE_DATA_DATE";
export const REQUEST_ONE_DATA_DATE_FAIL = "REQUEST_ONE_DATA_DATE_FAIL";
export const REQUEST_ONE_DATA_DATE_SUCCESS = "REQUEST_ONE_DATA_DATE_SUCCESS";

export const EVENT_DELETE_FILE = "EVENT_DELETE_FILE";
export const SET_DELETE_FILE = "SET_DELETE_FILE";



export const EVENT_PROCESS_NEW = "EVENT_PROCESS_NEW";
export const REQUEST_PROCESS_NEW = "REQUEST_PROCESS_NEW";
export const REQUEST_PROCESS_NEW_FAIL = "REQUEST_PROCESS_NEW_FAIL";
export const REQUEST_PROCESS_NEW_SUCCESS = "REQUEST_PROCESS_NEW_SUCCESS";

