import {
  EVENT_BUYERS,
  REQUEST_BUYERS,
  REQUEST_BUYERS_FAIL,
  REQUEST_BUYERS_SUCCESS,
} from "./actionTypes";


export const getBuyers = () => ({ type: EVENT_BUYERS });
export const requestBuyers = () => ({ type: REQUEST_BUYERS });
export const requestBuyersFail = (payload) => ({ type: REQUEST_BUYERS_FAIL, payload });
export const requestBuyersSuccess = (payload) => ({ type: REQUEST_BUYERS_SUCCESS, payload });