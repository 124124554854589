import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  InputGroup
} from "reactstrap";

import { Form as FForm } from "react-bootstrap";

import * as moment from "moment";

import DatePicker from "react-datepicker";

import Flatpickr from "react-flatpickr";

import { config } from "../../config";

import axios from "axios";
import { withRouter } from "react-router-dom"

import { TagsInput } from "react-tag-input-component";

// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";

import Spinner from '../../components/Common/spinner/Spin';
// import { latestTransaction } from "../../common/data/dashboard";
import { useFormik } from "formik";

import * as Yup from "yup";

import {
  Form,
  FormFeedback,
  Label,
} from "reactstrap";

import CheckBox from "./checkBox";

function convertTimeToTimestamp(timeString) {
  const [hours, minutes] = timeString.split(':');
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  return date.getTime(); // Returns the timestamp in milliseconds
}

function convertTimeToMySQLDatetime(timeString) {
  const currentDate = new Date(); // Puedes usar la fecha actual o una fecha específica
  const [hours, minutes] = timeString.split(':');

  currentDate.setHours(parseInt(hours, 10));
  currentDate.setMinutes(parseInt(minutes, 10));
  currentDate.setSeconds(0); // Establece los segundos en 0

  // Formatea la fecha en el formato de DATETIME de MySQL
  const mysqlDatetime = currentDate.toISOString().slice(0, 19).replace('T', ' ');

  return mysqlDatetime;
}

const EditModal = (props) => {

  const { toggle, isOpen, policyFile, isEdit, onSubmit } = props




  useEffect(() => {

    console.log("policyFile::", policyFile)

  }, [policyFile])

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      fullName: (policyFile && policyFile.fullName) || '',
      email: (policyFile && policyFile.email) || '',
      publisherID: (policyFile && policyFile.publisherID) || '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Please Select a Publisher"),
      email: Yup.string().required("Please Enter a dnc_number_pool_script_url"),
      publisherID: Yup.string().required("Please Enter a dnc_number_to_replace"),
    }),
    onSubmit: (values) => {

      const value = {
        id: policyFile?._id,
        ...values,
      };

      console.log("values::", values)

      onSubmit(value)
    },
  });

  const handleClearErrors = () => {
    validation.setErrors({}); // Esto limpiará todos los errores
  };

  useEffect(() => {

    handleClearErrors()

  }, [isOpen])

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className={props.className}
      centered
    >
      <ModalHeader toggle={toggle} tag="h5" className="py-3 px-4 border-bottom-0">
        {!!isEdit ? "Edit Lander" : "Add Lander"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Full Name</Label>
                <Input
                  name="fullName"
                  type="text"
                  // value={event ? event.title : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.fullName || ""}
                  invalid={
                    validation.touched.fullName && validation.errors.fullName ? true : false
                  }
                />
                {validation.touched.fullName && validation.errors.fullName ? (
                  <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Email</Label>
                <Input
                  name="email"
                  type="text"
                  // value={event ? event.title : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email ? true : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Publisher ID</Label>
                <Input
                  name="publisherID"
                  type="text"
                  // value={event ? event.title : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.publisherID || ""}
                  invalid={
                    validation.touched.publisherID && validation.errors.publisherID ? true : false
                  }
                />
                {validation.touched.publisherID && validation.errors.publisherID ? (
                  <FormFeedback type="invalid">{validation.errors.publisherID}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-6">
              {/* 
            {!!isEdit && (
              <button
                type="button"
                className="btn btn-danger me-2"
                onClick={() => { deleteModal(policyFile) }}
              >
                Delete
              </button>
            )}
          */}
            </Col>
            <Col className="col-6 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

EditModal.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {


  return {

  };
}

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(EditModal)));

