import { takeEvery, put, call } from "redux-saga/effects"

import {
  EVENT_GET_LINE_CHART
} from "./actionTypes";

import {
  requestLineChart,
  requestLineChartFail,
  requestLineChartSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getLineChartApi,
} from "../../helpers/fakebackend_helper"

function* onGetLineChart() {
  try {
    yield put(requestLineChart())
    const response = yield call(getLineChartApi)
    yield put(requestLineChartSuccess(response))
  } catch (error) {
    yield put(requestLineChartFail(error))
  }
}


function* chartSaga() {
  yield takeEvery(EVENT_GET_LINE_CHART, onGetLineChart);
}

export default chartSaga
