import React from "react";
import "./styles.css";

const LogRow = ({ dateTime, logLevel, message }) => {
  return (
    <div className="log-row">
      <div className="log-column log-narrow">{dateTime}</div>
      <div className="log-column log-small">{logLevel}</div>
      <div className="log-column log-large log-message">{message}</div>
      <div className="log-column log-small log-action">
        <button className="close-button">&#10006;</button>
      </div>
    </div>
  );
};

export default LogRow;
