import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';
import { UncontrolledTooltip } from "reactstrap";
import axios from "axios";

import Progress from './components/Progress';
import Porsentage from './components/PercentageCompleted';
import Files from './components/CompletedFiles';
import CustomStatus from './components/Status';


export const Actions = (props) => {

    const { onDelete, onEdit, cell } = props
    // console.log("cell", cell)
    // console.log("cell?.value", cell?.row?.values)
    return <>
        <ul className="list-unstyled hstack gap-1 mb-0">


            {(false) && (<li data-bs-toggle="tooltip" data-bs-placement="top">
                <Link to="#" className="btn btn-sm btn-soft-info"
                    onClick={() => onEdit(cell?.row?.original)}
                >
                    <i className="mdi mdi-pencil-outline" id="editcompanytooltip" />
                    {/* <UncontrolledTooltip placement="top" target="editcompanytooltip">{`${t("Edit Company")} ${title && (title)}`}</UncontrolledTooltip> */}
                </Link>
            </li>)}
            {(true) && (<li data-bs-toggle="tooltip" data-bs-placement="top">
                <button to="#" className="btn btn-sm btn-soft-success"
                    onClick={() => onEdit(cell?.row?.original)}
                    disabled
                >
                    <i className="bx bx-play" id="playtooltip" style={{ fontSize: '15px' }} />
                    {/* <UncontrolledTooltip placement="top" target="editcompanytooltip">{`${t("Edit Company")} ${title && (title)}`}</UncontrolledTooltip> */}
                </button>
            </li>)}
            {(true) && (<li data-bs-toggle="tooltip" data-bs-placement="top">
                <button to="#" className="btn btn-sm btn-soft-dark"
                    onClick={() => onEdit(cell?.row?.original)}
                    disabled
                >
                    <i className="bx bx-pause" id="stoptooltip" style={{ fontSize: '15px' }} />
                    {/* <UncontrolledTooltip placement="top" target="editcompanytooltip">{`${t("Edit Company")} ${title && (title)}`}</UncontrolledTooltip> */}
                </button>
            </li>)}
            {(true) && (<li data-bs-toggle="tooltip" data-bs-placement="top">
                <button to="#" disabled
                    onClick={() => onDelete(cell?.row?.original?.id)}
                    className="btn btn-sm btn-soft-primary">
                    <i className="bx  bx-trash" id="deletetooltip" ></i>
                    {/* <UncontrolledTooltip placement="top" target="viewtooltip">{`${t("View Company")} ${title && (title)}`}</UncontrolledTooltip> */}
                </button>
            </li>)}
        </ul>
    </>;
}

export const ProcessName = ({ cell, count }) => {
    const { row } = cell
    const item = row?.original;

    return <>
        <Progress item={{ ...item, info: { ...item?.info, completed: 1, total: 1 } }} />
    </>

}

export const PercentageCompleted = (cell) => {
    const { row } = cell
    const item = row?.original;
    return <>
        <Porsentage item={item} />
    </>;


    // return cell.value ? cell.value : 'N/A';
}

export const CompletedFiles = (cell) => {
    const { row } = cell
    const item = row?.original;

    if (item) {
        const { info } = item;
    
        if(info){
            const { inserted_rec } = info;
    
            return <>
                {`${inserted_rec}`}
            </>;
        }
    }
}

export const TotalFiles = (cell) => {
    const { row } = cell
    const item = row?.original;

    if (item) {
        const { info } = item;
    
        if(info){
            const { updated_rec } = info;
    
            return <>
                {`${updated_rec}`}
            </>;
        }
    }

}

export const Status = (cell) => {
    const { row } = cell
    const item = row?.original;

    return <>
        <CustomStatus item={item} />
    </>;
}

export const CreationDate = (cell) => {
    const { row } = cell
    const item = row?.original;

    // console.log("item::", item)


    if (item) {
        const { info } = item;
    
        if(info){
            const { process_start } = info;
    
            return <>
                {`${process_start}`}
            </>;
        }
    
    }
    return <>
        {``}
    </>;
}

export const CompletionDate = (cell) => {
    const { row } = cell
    const item = row?.original;

    if (item) {
        const { info } = item;

        if(info){
            const { process_finish } = info;

            return <>
                {`${process_finish}`}
            </>;
        }

    }
    return <>
        {``}
    </>;
}


export const Error = (cell) => {
    const { row } = cell
    const item = row?.original;

    if (item) {
        const { info } = item;

        if(info){
            const { error_displayed } = info;

            return <>
                {`${error_displayed?error_displayed:'None'}`}
            </>;
        }
    }
    return <>
        {``}
    </>;
}

export const Retriies = (cell) => {
    const { row } = cell
    const item = row?.original;

    if (item) {
        const { info } = item;

        if(info){
            const { retries } = info;

            return <>
                {`${retries?retries:0}`}
            </>;
        }
    }
    return <>
        {``}
    </>;
}