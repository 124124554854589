/* EVENTS */
export const EVENT_GET_PROCESS_LIST = "EVENT_GET_PROCESS_LIST";
export const REQUEST_PROCESS_LIST = "REQUEST_PROCESS_LIST";
export const REQUEST_PROCESS_LIST_FAIL = "REQUEST_PROCESS_LIST_FAIL";
export const REQUEST_PROCESS_LIST_SUCCESS = "REQUEST_PROCESS_LIST_SUCCESS";


export const EVENT_GET_PROCESS_LIST_SILENT = "EVENT_GET_PROCESS_LIST_SILENT";
export const REQUEST_PROCESS_LIST_SILENT = "REQUEST_PROCESS_LIST_SILENT";
export const REQUEST_PROCESS_LIST_SILENT_FAIL = "REQUEST_PROCESS_LIST_SILENT_FAIL";
export const REQUEST_PROCESS_LIST_SILENT_SUCCESS = "REQUEST_PROCESS_LIST_SUCCESS";