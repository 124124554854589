import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";

import Logger from "../../helpers/Logger/Logger";

import FCard from "../../components/Common/Import/fileCard";

import ScreenA from "./ScreenA";
import ScreenB from "./ScreenB";
import ScreenC from "./ScreenC";
import ScreenD from "./ScreenD";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import Select from 'react-select'

import { Link } from "react-router-dom";
// import Card from 'react-bootstrap/Card';

import classNames from "classnames";

//import Charts
import StackedColumnChart from "./StackedColumnChart";

//import action
import { getChartsData as onGetChartsData } from "../../store/actions";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

//redux
import { useSelector, useDispatch, connect } from "react-redux";

import Table from "../../components/Common/Tabla/BostrapTable";

import { checkinLogin } from "../../store/auth/login/actions";

import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";

import Spinner from '../../components/Common/spinner/Spin';

import { config } from "../../config";

import DateSelector from '../../components/Common/DateSelector/DateSelector';

import LogPannel from '../../components/LogComponent/LogScreen';

import LogList from '../../components/LogComponent/LogList';

import moment from 'moment';


const Logs = (props) => {
  const [cards, setCards] = useState([]);
  const [logs, setLogs] = useState([]);
  const [selected, setSelected] = useState("");


  const { BACKEND_URL } = config



  const getLogList = async () => {
    let url = `${BACKEND_URL}logger/log-list`;
    let { data } = await axios.get(url)

    let { logList } = data

    let logListCard = logList?.map((item, k) => {

      return { id: k, title: item }
    })

    setCards(logListCard)
  }

  // const getLog = async (serviceName) => {
  //   let url = `${BACKEND_URL}logger/get-log`;
  //   const payLoad = {
  //     "service_name": serviceName
  //   }

  //   let { data } = await axios.post(url, payLoad)

  //   let { logs } = data

  //   let logsList = logs?.map(({ date_time, log_level, message }) => {
  //     const fechaMoment = moment(date_time);
  //     const fechaFormateada = fechaMoment.format('YYYY-MM-DD HH:mm:ss');

  //     return {
  //       dateTime: fechaFormateada,
  //       logLevel: log_level,
  //       message: message
  //     }
  //   })

  //   console.log("logsList", logsList)

  //   setLogs(logsList)
  // }

  useEffect(() => {
    getLogList()
  }, [])

  useEffect(() => {
    console.log("cards", cards)
  }, [cards])

  useEffect(() => {

    const fetchLogs = async () => {
      try {
        let url = `${BACKEND_URL}logger/get-log`;
        const payLoad = {
          "service_name": selected
        };

        let { data } = await axios.post(url, payLoad);

        let { logs } = data;

        let logsList = logs?.map(({ date_time, log_level, message }) => {
          const fechaMoment = moment(date_time);
          const fechaFormateada = fechaMoment.format('YYYY-MM-DD HH:mm:ss');

          return {
            dateTime: fechaFormateada,
            logLevel: log_level,
            message: message
          };
        });

        // Check if logsList is different from the current value of logs
        if (JSON.stringify(logsList) !== JSON.stringify(logs)) {
          setLogs(logsList);
        }
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    // Fetch logs initially
    fetchLogs();

    // Set up interval to fetch logs every 30 seconds
    const intervalId = setInterval(() => {
      fetchLogs();
    }, 30000);

    return () => clearInterval(intervalId);

  }, [selected])

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '100vh',
          // border: '1px solid black',
          // overflow: 'hidden'
        }}
      >
        <Container fluid style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '80vh',
          // border: '1px solid black'
        }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Logs")}
            breadcrumbItem={props.t("Logs")}
          />
          <Row style={{ margin: 10, marginTop: 0 }}>
            <Col>
              <LogList cards={cards}
                onChange={(selected) => {
                  setSelected(selected)
                }}
              />
            </Col>
          </Row>
          <Row style={{ margin: 10 }}>
            <Col>
              <LogPannel logs={logs} logScreen={selected} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Logs.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {

  const buyerLoading = Buyer?.loading

  const dispLoading = Disposition?.loading

  // console.log("lineChart", Chart?.lineChart)

  return {
    buyers: Buyer?.buyers,
    fileList: Disposition?.fileList,
    lineChart: Chart?.lineChart,
    dispLoading: dispLoading
  };
}

const mapDispatchToProps = (dispatch) => ({
  onGetBuyers: () => dispatch(getBuyers()),
  onCheckinLogin: () => dispatch(checkinLogin()),
  onGetFileHistory: (body) => dispatch(getFileHistory(body)),
  onSetProcess: (body) => dispatch(setProcess(body)),
  onGetLineChart: () => dispatch(getLineChart())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Logs)));

