import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import TableContainer from "../../components/Common/TableContainer";

import {
  Actions,
  DomainId,
  AllowDesktop,
  AllowMobile,
  AllowParams,
  DncNumberPoolScript,
  DncNumberToReplace,
  DsPlatform,
  PublisherId,
  PublisherName,
  NumberPoolScriptUrl,
  NumberToReplace,
  Slug,
  Vertical
} from "./LatestTranactionCol";

// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { TagsInput } from "react-tag-input-component";

//i18n
import { withTranslation } from "react-i18next";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";

import Spinner from '../../components/Common/spinner/Spin';
// import { latestTransaction } from "../../common/data/dashboard";
import { useFormik } from "formik";

import * as Yup from "yup";

import {
  Form,
  FormFeedback,
  Label,
} from "reactstrap";

function tieneFormatoDeDominio(texto) {
  // Utilizamos una expresión regular para verificar si el texto sigue el formato de un dominio
  var regex = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  // Probamos si la expresión regular coincide con el texto
  return regex.test(texto);
}

const EditModal = (props) => {

  const { toggle, isOpen, onSubmit, isLoading } = props



  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      agent_name: '',
      agent_string: '',
    },
    validationSchema: Yup.object({
      
    }),
    onSubmit: (values) => {
      onSubmit(values)
    },
  });

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className={props.className}
      centered
    >
      <ModalHeader toggle={toggle} tag="h5" className="py-3 px-4 border-bottom-0">
        {"Create User Agent"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">User Agent Name</Label>
                <Input
                  name="agent_name"
                  type="text"
                  // value={event ? event.title : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.agent_name || ""}
                // invalid={
                //   ((validation.touched.domain_name && validation.errors.domain_name)) ? true : false
                // }
                />

                {/* {validation.touched.domain_name && validation.errors.domain_name ? (
                  <FormFeedback type="invalid">{validation.errors.domain_name}</FormFeedback>
                ) : null} */}

              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">User Agent String</Label>
                <Input
                  name="agent_string"
                  type="text"
                  // value={event ? event.title : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.agent_string || ""}
                  // invalid={
                  //   ((validation.touched.domain_name && validation.errors.domain_name)) ? true : false
                  // }
                />

                {/* {validation.touched.domain_name && validation.errors.domain_name ? (
                  <FormFeedback type="invalid">{validation.errors.domain_name}</FormFeedback>
                ) : null} */}

              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-6">
              {/*{!!isEdit && (
                <button
                  type="button"
                  className="btn btn-danger me-2"
                  onClick={()=>{ deleteModal(domain) }}
                >
                  Delete
                </button>
              )}*/}
            </Col>
            <Col className="col-6 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <Button type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>

  );
};

EditModal.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {


  return {

  };
}

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(EditModal)));

