import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import TableContainer from "../../components/Common/TableContainer";
import Select from 'react-select'

const RTBTable = (props) => {

  const { logFiles, selectedFile, publisherDataList, onFilter, onExport } = props

  const [isCompleted, setIsComplete] = useState(false)
  const [publisherList, setPublisherList] = useState(false)
  const [publisher, setPublisher] = useState(null)

  useEffect(() => {

    if (selectedFile) {
      const { state_status } = selectedFile
      const isComplete = (state_status == "Completed")
      setIsComplete(isComplete);
    }
  }, [selectedFile])

  useEffect(() => {

    const publisherList = publisherDataList?.map((item) => {


      return { value: item?.value, label: item?.name }

    })
    setPublisherList(publisherList)
  }, [publisherDataList])


  useEffect(() => {

    console.log("publisherDataList::", publisherList)
    console.log("buyerList::", publisherList)

  }, [publisherList])


  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   },
      // },
      {
        Header: "Id",
        accessor: "_id",
        disableFilters: true,
        hidden: true,
        Cell: cellProps => {
          const { row } = cellProps?.cell
          const { values } = row
          // console.log("cellProps", cellProps)
          return <>{values?._id}</>;
        },
      },
      {
        Header: "BidID",
        accessor: "BidID",
        disableFilters: true,
        Cell: cellProps => {
          const { row } = cellProps?.cell
          const { values } = row
          // console.log("cellProps", cellProps)
          return <>{values["BidID"]}</>;
        },
      },
      {
        Header: "Date",
        accessor: "Date",
        disableFilters: true,
        Cell: cellProps => {
          const { row } = cellProps?.cell
          const { values } = row
          // console.log("cellProps", cellProps)
          return <>{values["Date"]}</>;
        },
      },
      {
        Header: "State",
        accessor: "State",
        disableFilters: true,
        Cell: cellProps => {
          const { row } = cellProps?.cell
          const { values } = row
          // console.log("cellProps", cellProps)
          return <>{values["State"]}</>;
        },
      },
      {
        Header: "Publisher Name",
        accessor: "Publisher Name",
        disableFilters: true,
        Cell: cellProps => {
          const { row } = cellProps?.cell
          const { values } = row
          // console.log("cellProps", cellProps)
          return <>{values["Publisher Name"]}</>;
        },
      }
    ],
    []
  );

  return <Row style={{ margin: 10 }}>
    <Col sm="12">

      <Row style={{ margin: 10 }}>
        <Col sm="4">
          <Select
            options={publisherList}
            onChange={(value) => {
              setPublisher({
                value: value?.value,
                name: value?.label
              })
            }}
          />
        </Col>
        <Col sm="2">
          <Button onClick={async () => {
            onFilter&&onFilter(publisher)
          }}>
            filter
          </Button>
          {" "}
          <Button onClick={async () => {
            onFilter&&onFilter(null)
          }}>
            clear
          </Button>
        </Col>

        <Col sm="2">
          <Button
            disabled={!isCompleted}
            onClick={async () => {

              onExport && onExport();

            }}>
            export csv
          </Button>
        </Col>
      </Row>
      <Card>
        <CardBody>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          </div>
          <TableContainer
            columns={columns}
            data={logFiles}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={10}
          />
        </CardBody>
      </Card>
    </Col>
  </Row>;
};

RTBTable.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

export default RTBTable;