import React from "react";
import LogRow from "../LogRow";
import "./styles.css";

const LogTable = ({logs}) => {
  // Ejemplo de datos de registro

  return (
    <div className="log-table">
      {logs?.map((log, index) => (
        <LogRow key={index} {...log} />
      ))}
      {logs?.map((log, index) => (
        <LogRow key={index} {...log} />
      ))}
    </div>
  );
};

export default LogTable;
