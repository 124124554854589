import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { Badge } from 'reactstrap';
import { UncontrolledTooltip } from "reactstrap";
import axios from "axios";

import {
    Container,
    Row,
    Col,
    Button,
    Card,
    CardBody,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";

export const Actions = (props) => {

    const { onDelete, onEdit, cell } = props
    // console.log("cell", cell)
    // console.log("cell?.value", cell?.row?.values)
    return <>
        <ul className="list-unstyled hstack gap-1 mb-0">


            {(true) && (<li data-bs-toggle="tooltip" data-bs-placement="top">
                <Link to="#" className="btn btn-sm btn-soft-info"
                    onClick={() => onEdit(cell?.row?.original)}
                >
                    <i className="mdi mdi-pencil-outline" id="editcompanytooltip" />
                    {/* <UncontrolledTooltip placement="top" target="editcompanytooltip">{`${t("Edit Company")} ${title && (title)}`}</UncontrolledTooltip> */}
                </Link>
            </li>)}

            {(true) && (<li data-bs-toggle="tooltip" data-bs-placement="top">
                <Link to="#"
                    onClick={() => onDelete(cell?.row?.original?.id)}
                    className="btn btn-sm btn-soft-primary">
                    <i className="bx  bx-trash" id="deletetooltip" ></i>
                    {/* <UncontrolledTooltip placement="top" target="viewtooltip">{`${t("View Company")} ${title && (title)}`}</UncontrolledTooltip> */}
                </Link>
            </li>)}

        </ul>
    </>;
}

export const DomainId = (cell) => {
    const { row } = cell
    console.log("cellProps::", row?.original)

    return cell.value ? cell.value : '';
}

export const DomainName = (cell) => {
    const { row } = cell

    const item = row?.original;


    return <>
        {item?.domain?.domain_name}
    </>

    return cell.value ? cell.value : '';
}

export const AllowDesktop = (cell) => {

    //console.log("cell", cell)

    return cell.value ? cell.value : '';
}


export const Options = (cell) => {
    const { row } = cell
    //console.log("cell", cell)
    const item = row?.original;
    const listStyle = {
        listStyleType: 'none', // Elimina los puntos de la lista
        padding: 0, // Elimina el espacio de relleno por defecto
    };

    const boldText = {
        fontWeight: 'bold', // Aplica negrita al texto
    };

    return <>
        <ul style={listStyle}>
            {(item?.allow_desktop == 1) && <li style={boldText}>allow_desktop</li>}
            {(item?.allow_mobile == 1) && <li style={boldText}>allow_mobile</li>}
            {(item?.allow_params == 1) && <li style={boldText}>allow_params</li>}
        </ul>
    </>
}

export const AllowMobile = (cell) => {
    //console.log("cell", cell)

    return cell.value ? cell.value : '';
}

export const AllowParams = (cell) => {
    //console.log("cell", cell)

    return cell.value ? cell.value : '';
}

export const DncNumberPoolScript = (cell) => {
    //console.log("cell", cell)

    return cell.value ? cell.value : '';
}

export const DncNumber = (cell) => {
    const { row } = cell

    const listStyle = {
        listStyleType: 'none', // Elimina los puntos de la lista
        padding: 0, // Elimina el espacio de relleno por defecto
    };

    const boldText = {
        fontWeight: 'bold', // Aplica negrita al texto
    };

    const item = row?.original;

    return <>
        {(item?.dnc_number_pool_script_url) && <div className='mb-2'><span style={boldText}>{"Pool Script: "}</span>{item?.dnc_number_pool_script_url}</div>}
        {(item?.dnc_number_to_replace) && <div><span style={boldText}>{"To Replace: "}</span>{item?.dnc_number_to_replace}</div>}
    </>
}

export const Number = (cell) => {
    const { row } = cell

    const listStyle = {
        listStyleType: 'none', // Elimina los puntos de la lista
        padding: 0, // Elimina el espacio de relleno por defecto
    };

    const boldText = {
        fontWeight: 'bold', // Aplica negrita al texto
    };

    const item = row?.original;

    return <>
        {(item?.number_pool_script_url) && <div className='mb-2'><span style={boldText}>{"Pool Script: "}</span>{item?.number_pool_script_url}</div>}
        {(item?.number_to_replace) && <div><span style={boldText}>{"To Replace: "}</span>{item?.number_to_replace}</div>}
    </>
}

export const DncNumberToReplace = (cell) => {
    //console.log("cell", cell)

    return cell.value ? cell.value : '';
}

export const DsPlatform = (cell) => {
    //console.log("cell", cell)

    return cell.value ? cell.value : '';
}

export const PublisherId = (cell) => {
    //console.log("cell", cell)

    return cell.value ? cell.value : '';
}


export const PublisherName = ({ cell }) => {
    //console.log("cell", cell)
    // const { row } = cell
    // const data = row?.original;

    const [publisherList, setPublisherList] = useState([]);
    const [publisherName, setPublisherName] = useState();

    const getPublisherList = async () => {
        let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
        let url = `https://api.ringba.com/v2/RA27f335147c2d487fba01c2732feaecb0/Publishers`
        let { data } = await axios.get(url, {
            headers: {
                'Authorization': `Token ${token}`
            }
        })

        if (data) {
            const { publishers } = data
              setPublisherList(publishers)
        }

    }

    useEffect(() => {
        getPublisherList()
    }, [])
    
    useEffect(() => {
        const publisherName = publisherList?.filter((item)=> item?.id==cell?.value)[0]?.name
        
        setPublisherName(publisherName)
    }, [publisherList])
    
    return publisherName;
}

export const NumberPoolScriptUrl = (cell) => {
    //console.log("cell", cell)

    return cell.value ? cell.value : '';
}

export const NumberToReplace = (cell) => {
    //console.log("cell", cell)

    return cell.value ? cell.value : '';
}

export const ParamList = (cell) => {
    const { row } = cell
    const item = row?.original;
    const param_list = item?.param_list ? JSON.parse(item?.param_list) : []

    return <>
        {
            param_list?.map((item, key) => {
                return <span key={key}><h5><Badge style={{ minWidth: 40 }} color="info" >{item}</Badge></h5></span>
            })
        }
    </>;
}

export const Slug = (cell) => {
    //console.log("cell", cell)

    return cell.value ? cell.value : '';
}

export const Time = (cell) => {
    const { row } = cell

    const listStyle = {
        listStyleType: 'none', // Elimina los puntos de la lista
        padding: 0, // Elimina el espacio de relleno por defecto
    };

    const boldText = {
        fontWeight: 'bold', // Aplica negrita al texto
    };

    const item = row?.original;

    return <>
        {(item?.hours_open) && <div><span style={boldText}>{"Open: "}</span>{formateTime(item?.hours_open)}</div>}
        {(item?.hours_closed) && <div><span style={boldText}>{"Close: "}</span>{formateTime(item?.hours_closed)}</div>}
    </>
}

export const Vertical = (cell) => {
    //console.log("cell", cell)

    return cell.value ? cell.value : '';
}

const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};

const formateTime = (time, format) => {
    const timeFormat = format ? format : "HH:mm";
    const timeFormatted = moment(time).format(timeFormat); // Quité "new Date()"
    return timeFormatted;
};

const toLowerCase1 = str => {
    return (
        str === "" || str === undefined ? "" : str.toLowerCase()
    );
};

const CheckBox = (cell) => {
    return cell.value ? cell.value : '';
};

const OrderId = (cell) => {
    return (
        <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ''}</Link>
    );
};

const BillingName = (cell) => {
    return cell.value ? cell.value : '';
};

const Date = (cell) => {
    return cell.value ? cell.value : '';
};

const Total = (cell) => {
    return cell.value ? cell.value : '';
};

const PaymentStatus = (cell) => {
    return (
        <Badge
            className={"font-size-11 badge-soft-" +
                (cell.value === "Paid" ? "success" : "danger" && cell.value === "Refund" ? "warning" : "danger")}
        >
            {cell.value}
        </Badge>
    )
};
const PaymentMethod = (cell) => {
    return (
        <span>
            <i
                className={
                    (cell.value === "Paypal" ? "fab fa-cc-paypal me-1" : "" ||
                        cell.value === "COD" ? "fab fas fa-money-bill-alt me-1" : "" ||
                            cell.value === "Mastercard" ? "fab fa-cc-mastercard me-1" : "" ||
                                cell.value === "Visa" ? "fab fa-cc-visa me-1" : ""
                    )}
            />{" "}
            {cell.value}
        </span>
    )
};
export {
    CheckBox,
    OrderId,
    BillingName,
    Date,
    Total,
    PaymentStatus,
    PaymentMethod
};