import {
  REQUEST_FILE_LIST,
  REQUEST_FILE_LIST_FAIL,
  REQUEST_FILE_LIST_SUCCESS,

  REQUEST_PROCESS,
  REQUEST_PROCESS_FAIL,
  REQUEST_PROCESS_SUCCESS,

  REQUEST_FILE_LIST_SCROLL,
  REQUEST_FILE_LIST_SCROLL_FAIL,
  REQUEST_FILE_LIST_SCROLL_SUCCESS,

  REQUEST_LIST_DATA,
  REQUEST_LIST_DATA_FAIL,
  REQUEST_LIST_DATA_SUCCESS,

  SET_DATE_FILTER,

  REQUEST_ONE_DATA_DATE,
  REQUEST_ONE_DATA_DATE_FAIL,
  REQUEST_ONE_DATA_DATE_SUCCESS,

  SET_DELETE_FILE,


  REQUEST_PROCESS_NEW,
  REQUEST_PROCESS_NEW_FAIL,
  REQUEST_PROCESS_NEW_SUCCESS,

} from "./actionTypes";

const INIT_STATE = {
  fileList: [],
  message: '',
  loading: false,
  failed: false
};

const disposition = (state = INIT_STATE, action) => {
  switch (action.type) {

    case SET_DATE_FILTER:
      return {
        ...state,
        dateFilter: action.payload
      };

      case SET_DELETE_FILE:

        console.log("SET_DELETE_FILE::", action.payload)
        const ondelete = action.payload
        const onDeletedFileList = state?.fileList?.filter(item => item._id !== ondelete?._id);

        return {
          ...state,
          fileList: onDeletedFileList
        };

    case REQUEST_FILE_LIST:
      return {
        ...state,
        fileList: [],
        message: '',
        loading: true,
        failed: false
      };

    case REQUEST_FILE_LIST_SUCCESS:
      return {
        ...state,
        failed: false,
        loading: false,
        message: action.message,
        fileList: action.payload?.data
      };

    case REQUEST_FILE_LIST_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };

    case REQUEST_PROCESS:
      return {
        ...state,
        // fileList: [],
        message: '',
        loading: true,
        failed: false
      };

    case REQUEST_PROCESS_SUCCESS:
      return {
        ...state,
        failed: false,
        loading: false,
        message: action.message,
        fileList: [action.payload?.data, ...state?.fileList]
      };

    case REQUEST_PROCESS_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };

    //======================================================
    case REQUEST_PROCESS_NEW:
      return {
        ...state,
        message: '',
        loading: true,
        failed: false
      };

    case REQUEST_PROCESS_NEW_SUCCESS:
      console.log("REQUEST_PROCESS_SUCCESS::", action.payload?.data)

      return {
        ...state,
        failed: false,
        loading: false,
        message: action.message,
        fileList: [action.payload?.data, ...state?.fileList]
      };

    case REQUEST_PROCESS_NEW_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };
    //======================================================

    case REQUEST_FILE_LIST_SCROLL:
      return {
        ...state,
        loading_srcoll: true,
        failed_srcoll: false,
        message_srcoll: '',
      };

    case REQUEST_FILE_LIST_SCROLL_SUCCESS:

      return {
        ...state,
        failed_srcoll: false,
        loading_srcoll: false,
        message: action.message,
        fileList: [...state?.fileList, ...action.payload?.data]
      };

    case REQUEST_FILE_LIST_SCROLL_FAIL:
      return {
        ...state,
        failed_srcoll: true,
        loading_srcoll: false,
        message_srcoll: action.message,
      };

    case REQUEST_PROCESS_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };

    case REQUEST_FILE_LIST_SCROLL:
      return {
        ...state,
        loading_srcoll: true,
        failed_srcoll: false,
        message_srcoll: '',
      };

    case REQUEST_FILE_LIST_SCROLL_SUCCESS:

      return {
        ...state,
        failed_srcoll: false,
        loading_srcoll: false,
        message: action.message,
        fileList: [...state?.fileList, ...action.payload?.data]
      };
    //------------------------

    case REQUEST_LIST_DATA:
      return {
        ...state,
        loading_list_data: true,
        failed_list_data: false,
        message_list_data: '',

      };

    case REQUEST_LIST_DATA_FAIL:
      return {
        ...state,
        loading_list_data: false,
        failed_list_data: true,
        message_list_data: action.message,
      };

    case REQUEST_LIST_DATA_SUCCESS:
      const selected = action.payload?.data
      const fileList = state?.fileList;

      console.log("REQUEST_LIST_DATA:: REQUEST_LIST_DATA_SUCCESS")

      if (fileList) {
        const indexToUpdate = fileList.findIndex(file => file._id === selected?._id);
        if (indexToUpdate !== -1) {
          // Se encontró el elemento con _id igual a 100
          fileList[indexToUpdate].data = [...fileList[indexToUpdate]?.data, ...selected?.data];
        }

        console.log("REQUEST_LIST_DATA:: response", fileList[indexToUpdate])

      }

      return {
        ...state,
        loading_list_data: false,
        failed_list_data: false,
        message_list_data: action.message,
        fileList: fileList
      };

    //---------------------------

    case REQUEST_ONE_DATA_DATE:
      return {
        ...state,
        // fileList: [],
        message: '',
        loading: true,
        failed: false
      };

    case REQUEST_ONE_DATA_DATE_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };

    case REQUEST_ONE_DATA_DATE_SUCCESS:

      const selected2 = action.payload?.data;
      const fileList2 = state?.fileList;

      console.log("REQUEST_ONE_DATA_DATE_SUCCESS:: REQUEST_LIST_DATA_SUCCESS--", fileList2[0]);
      console.log("REQUEST_ONE_DATA_DATE_SUCCESS:: REQUEST_LIST_DATA_SUCCESS--", selected2);

      // Comprobaciones adicionales
      if (fileList2 && selected2) {
        const indexToUpdate2 = fileList2.findIndex(file => file && file._id === selected2._id);

        console.log("REQUEST_ONE_DATA_DATE_SUCCESS:: indexToUpdate2--", indexToUpdate2);
        fileList2[indexToUpdate2] = selected2;
        // Resto del código...

        console.log("REQUEST_ONE_DATA_DATE_SUCCESS::", fileList2);

      }

      return {
        ...state,
        failed: false,
        loading: false,
        message: action.message,
        fileList: fileList2
      };


    default:
      return state;
  }
};

export default disposition;
