import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useCallback } from "react";

import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess, getListData, setDateFilter } from "../../store/disposition/actions";
import { getFileList, getLogsFileList, getLogsPublisher } from "../../store/rtbFiles/actions";

import { getLineChart } from "../../store/chart/actions";

// import { RTBFileScreen } from './RTBFileScreen';
import { RTBFileScreen } from './RTBFileScreen';

const RTBFileContainer = (props) => {

  return <RTBFileScreen {...props} />;
};

RTBFileContainer.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
  onGetListData: PropTypes.func,
};

const mapStateToProps = ({ Login, Buyer, Disposition, RtbFile, Chart }) => {

  const buyerLoading = Buyer?.loading
  const dispLoading = Disposition?.loading

  //-------------------------------

  const username = Login?.auth?.username
  const isMaster = ((username == "test") || (username == "GilbertoPB")) ? true : false;
  const loading_srcoll = Disposition?.loading_srcoll
  const loading_list_data = Disposition?.loading_list_data
  const dateFilter = Disposition?.dateFilter

  // console.log("lineChart", Chart?.lineChart)

  return {
    buyers: Buyer?.buyers,
    fileList: Disposition?.fileList,
    data: RtbFile?.fileList,
    logFiles: RtbFile?.logsFileList,
    publisherDataList: RtbFile?.publisherList,

    dispLoading: dispLoading,
    //-------------------------------
    isMaster,
    loading_srcoll,
    loading_list_data,
    dateFilter
  };
}

const mapDispatchToProps = (dispatch) => ({
  onGetBuyers: () => dispatch(getBuyers()),
  onCheckinLogin: () => dispatch(checkinLogin()),
  onGetRtbFileList: (body) => dispatch(getFileList(body)),
  onSetProcess: (body) => dispatch(setProcess(body)),
  onGetLineChart: () => dispatch(getLineChart()),
  onSetDateFilter: (body) => dispatch(setDateFilter(body)),
  onGetLogsFileList: (body) => dispatch(getLogsFileList(body)),
  onGetLogsPublisher: (body) => dispatch(getLogsPublisher(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(RTBFileContainer)));

