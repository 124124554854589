
import {
  REQUEST_SIGNUP_USER, REQUEST_SIGNUP_SUCCESS, REQUEST_SIGNUP_FAIL,
} from "./actionTypes"

const initialState = {
  message: '',
  loading: false,
  failed: false
}

const singUp = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_SIGNUP_USER:
      return { 
        ...state, 
        message: '', 
        loading: true, 
        failed: false 
      }

    case REQUEST_SIGNUP_SUCCESS:
      return { 
        ...state, 
        loading: false, 
        failed: false 
      }

    case REQUEST_SIGNUP_FAIL:
      return { 
        ...state, 
        message: action.payload, 
        loading: false, 
        failed: true 
      }

    default:
      return { ...state }
  }
}

export default singUp
