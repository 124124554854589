import {
  REQUEST_PROCESS_LIST,
  REQUEST_PROCESS_LIST_FAIL,
  REQUEST_PROCESS_LIST_SUCCESS,

  REQUEST_PROCESS_LIST_SILENT,
  REQUEST_PROCESS_LIST_SILENT_FAIL,
  REQUEST_PROCESS_LIST_SILENT_SUCCESS,

} from "./actionTypes";

const INIT_STATE = {
  processList: [],
  message: '',
  loading: false,
  failed: false
};

const disposition = (state = INIT_STATE, action) => {
  switch (action.type) {

    case REQUEST_PROCESS_LIST:
      return {
        ...state,
        processList: [],
        message: '',
        loading: true,
        failed: false
      };

    case REQUEST_PROCESS_LIST_SUCCESS:
      return {
        ...state,
        failed: false,
        loading: false,
        message: action.message,
        processList: action.payload?.data
      };

    case REQUEST_PROCESS_LIST_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };


    case REQUEST_PROCESS_LIST_SILENT:
      return {
        ...state,
        message: '',
        loading: true,
        failed: false
      };

    case REQUEST_PROCESS_LIST_SILENT_SUCCESS:
      return {
        ...state,
        failed: false,
        loading: false,
        message: action.message,
        processList: action.payload?.data
      };

    case REQUEST_PROCESS_LIST_SILENT_FAIL:
      return {
        ...state,
        failed: true,
        loading: false,
        message: action.message,
      };


    default:
      return state;
  }
};

export default disposition;
