import React, { useState, useEffect } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch, connect } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.png";

import { singUpUser } from "../../store/auth/signUp/actions"


const Register = (props) => {

  const { onSingUpUser } = props
  const { errorMessage, isError } = props

  const [isLoginError, setIsLoginError] = useState(false)

  useEffect(() => {

    if (isError) {
      setIsLoginError(isError);

      // Establecer el temporizador para desactivar el mensaje de error después de 23 segundos
      setTimeout(() => {
        setIsLoginError(false);
      }, 2500); // 23 segundos en milisegundos
    }

  }, [isError])

  //meta title
  document.title = "Register | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      username: '',
      password: '',
      fname: '',
      lname: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
      fname: Yup.string().required("Please Enter Your First Name"),
      lname: Yup.string().required("Please Enter Your Last Name"),
    }),
    onSubmit: async (values) => {
      console.log("values", values)
      onSingUpUser(values, props.history)
    }
  });

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));

  // useEffect(() => {
  //   dispatch(apiError(""));
  // }, []);

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Free Register</h5>
                        <p>Get your free Skote account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}

                        {isLoginError ? (
                          <p style={{ color: "red", marginTop: 10 }}>{errorMessage}</p>
                        ) : null}

                      </div>

                      <Row>
                        <Col>
                          <div className="mb-3">
                            <Label className="form-label">First Name</Label>
                            <Input
                              id="fname"
                              name="fname"
                              className="form-control"
                              placeholder="Enter First Name"
                              type="fname"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.fname || ""}
                              invalid={
                                validation.touched.fname && validation.errors.fname ? true : false
                              }
                            />
                            {validation.touched.fname && validation.errors.fname ? (
                              <FormFeedback type="invalid">{validation.errors.fname}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col>
                          <div className="mb-3">
                            <Label className="form-label">Last Name</Label>
                            <Input
                              id="lname"
                              name="lname"
                              className="form-control"
                              placeholder="Enter Last Name"
                              type="lname"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.lname || ""}
                              invalid={
                                validation.touched.lname && validation.errors.lname ? true : false
                              }
                            />
                            {validation.touched.lname && validation.errors.lname ? (
                              <FormFeedback type="invalid">{validation.errors.lname}</FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                      <div className="mb-3">
                        <Label className="form-label">Username</Label>
                        <Input
                          name="username"
                          type="text"
                          placeholder="Enter username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Register
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                {/* <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};



const mapStateToProps = ({ SignUp }) => {

  const errorMessage = SignUp?.message
  const isError = SignUp?.failed

  return {
    errorMessage,
    isError
  };
}

const mapDispatchToProps = (dispatch) => ({
  onSingUpUser: (body) => dispatch(singUpUser(body)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));
