import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  InputGroup
} from "reactstrap";

import { Form as FForm } from "react-bootstrap";

import * as moment from "moment";

import DatePicker from "react-datepicker";

import Flatpickr from "react-flatpickr";

import { config } from "../../config";

import axios from "axios";
import { withRouter } from "react-router-dom"

import { TagsInput } from "react-tag-input-component";

// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";

import Spinner from '../../components/Common/spinner/Spin';
// import { latestTransaction } from "../../common/data/dashboard";
import { useFormik } from "formik";

import * as Yup from "yup";

import {
  Form,
  FormFeedback,
  Label,
} from "reactstrap";

import CheckBox from "./checkBox";

function convertTimeToTimestamp(timeString) {
  const [hours, minutes] = timeString.split(':');
  const date = new Date();
  date.setHours(parseInt(hours, 10));
  date.setMinutes(parseInt(minutes, 10));
  return date.getTime(); // Returns the timestamp in milliseconds
}

function convertTimeToMySQLDatetime(timeString) {
  const currentDate = new Date(); // Puedes usar la fecha actual o una fecha específica
  const [hours, minutes] = timeString.split(':');
  
  currentDate.setHours(parseInt(hours, 10));
  currentDate.setMinutes(parseInt(minutes, 10));
  currentDate.setSeconds(0); // Establece los segundos en 0

  // Formatea la fecha en el formato de DATETIME de MySQL
  const mysqlDatetime = currentDate.toISOString().slice(0, 19).replace('T', ' ');
  
  return mysqlDatetime;
}

const EditModal = (props) => {

  const { toggle, isOpen, lander, publisherList, isEdit, onSubmit } = props

  const {BACKEND_URL} = config

  const [selected, setSelected] = useState((lander?.param_list && JSON.parse(lander?.param_list)) || []);

  const [verticalSlections] = useState([
    { id: "ACA", name: "ACA" },
    { id: "medicare", name: "Medicare" }
  ])

  const [deviceSlections] = useState([
    { id: "everflow", name: "Everflow" },
    { id: "red_trak", name: "Red Trak" }
  ])
  const [domainSlections, setDomainSlections] = useState([])

  useEffect(() => {
    const getDomainselections = async () => {
      let url = `${BACKEND_URL}/domain/get-selections`;
      
      let { data } = await axios.get(url)
  
      console.log("getDomainselections data::", data)
  
      if (data?.success) {
        const { domainSlections } = data
  
        setDomainSlections(domainSlections)
      }
  
    }

    getDomainselections()

  }, [BACKEND_URL])

  useEffect(() => {
    setSelected(lander?.param_list ? JSON.parse(lander.param_list) : []);
  }, [lander]);

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,
    initialValues: {
      publisher_id: (lander && lander.publisher_id) || '',
      domain_id: lander ? lander.domain_id : "",
      allow_desktop: (lander?.allow_desktop == 1) || false,
      allow_mobile: (lander?.allow_mobile == 1) || false,
      allow_params: (lander?.allow_params == 1) || false,
      dnc_number_pool_script_url: (lander && lander.dnc_number_pool_script_url) || '',
      dnc_number_to_replace: (lander && lander.dnc_number_to_replace) || '',
      slug: (lander && lander.slug) || '',
      ds_platform: (lander && lander.ds_platform) || '',
      number_pool_script_url: (lander && lander.number_pool_script_url) || '',
      number_to_replace: (lander && lander.number_to_replace) || '',
      vertical: (lander && lander.vertical) || '',
      hours_open: moment(lander?.hours_open).format("hh:mm") || '',
      hours_closed: moment(lander?.hours_closed).format("hh:mm") || '',
    },
    validationSchema: Yup.object({
      publisher_id: Yup.string().required("Please Select a Publisher"),
      domain_id: Yup.string().required("Please Select a Domain"),
      dnc_number_pool_script_url: Yup.string().required("Please Enter a dnc_number_pool_script_url"),
      dnc_number_to_replace: Yup.string().required("Please Enter a dnc_number_to_replace"),
      slug: Yup.string().required("Please Enter a slug"),
      ds_platform: Yup.string().required("Please Select a ds_platform"),
      number_pool_script_url: Yup.string().required("Please Enter A number_pool_script_url"),
      number_to_replace: Yup.string().required("Please Enter A number_to_replace"),
      vertical: Yup.string().required("Please Select a ds_platform"),
    }),
    onSubmit: (values) => {

      console.log("onSubmit---")

      const value = {
        id: lander?.id,
        ...values,
        hours_open: convertTimeToMySQLDatetime(values?.hours_open),
        hours_closed: convertTimeToMySQLDatetime(values?.hours_closed),
        param_list: JSON.stringify(selected),
        allow_desktop: values?.allow_desktop ? 1 : 0,
        allow_mobile: values?.allow_mobile ? 1 : 0,
        allow_params: values?.allow_params ? 1 : 0,
      };

      onSubmit(value)
    },
  });

  const handleClearErrors = () => {
    validation.setErrors({}); // Esto limpiará todos los errores
  };

  useEffect(() => {

    handleClearErrors()

  }, [isOpen])

  useEffect(() => {

    // console.log("values::", validation.values)

  }, [validation.values])
  

  useEffect(() => {

    console.log("lander::", lander)

  }, [lander])


  useEffect(() => {
      validation.setValues({
        publisher_id: (lander && lander.publisher_id) || '',
        domain_id: lander ? lander.domain_id : "",
        allow_desktop: (lander?.allow_desktop == 1) || false,
        allow_mobile: (lander?.allow_mobile == 1) || false,
        allow_params: (lander?.allow_params == 1) || false,
        dnc_number_pool_script_url: (lander && lander.dnc_number_pool_script_url) || '',
        dnc_number_to_replace: (lander && lander.dnc_number_to_replace) || '',
        slug: (lander && lander.slug) || '',
        ds_platform: (lander && lander.ds_platform) || '',
        number_pool_script_url: (lander && lander.number_pool_script_url) || '',
        number_to_replace: (lander && lander.number_to_replace) || '',
        vertical: (lander && lander.vertical) || '',
        hours_open: moment(lander?.hours_open).format("hh:mm") || '',
        hours_closed: moment(lander?.hours_closed).format("hh:mm") || '',
      });
    
  }, [lander]);
  
  const handleReset = () => {
    // Limpia los valores del formulario
    validation.resetForm();
  };
  
  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className={props.className}
      centered
    >
      <ModalHeader toggle={toggle} tag="h5" className="py-3 px-4 border-bottom-0">
        {!!isEdit ? "Edit Lander" : "Add Lander"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Publisher</Label>
                <Input
                  type="select"
                  name="publisher_id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.publisher_id || ""}
                  invalid={validation.touched.publisher_id && validation.errors.publisher_id ? true : false}
                >
                  <option value="" disabled>Select an option</option> {/* Placeholder */}
                  {
                    publisherList?.map((item, key) => {
                      return <option value={`${item?.id}`} key={key} >{`${item?.name}`}</option>
                    })
                  }
                </Input>
                {validation.touched.publisher_id && validation.errors.publisher_id ? (
                  <FormFeedback type="invalid">{validation.errors.publisher_id}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col >
              <div id="timepicker1">
                <Row>
                  <Col>
                    <h5>
                      Start Time
                    </h5>
                  </Col>
                  <Col>
                    <h5>
                      End Time
                    </h5>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FForm.Control
                      type="time"
                      name="hours_open"
                      id="hoursOpen"
                      value={validation.values.hours_open}
                      onChange={validation.handleChange}
                    />
                  </Col>
                  <Col>
                    <FForm.Control
                      type="time"
                      name="hours_closed"
                      id="hoursClosed"
                      value={validation.values.hours_closed}
                      onChange={validation.handleChange}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Domain List</Label>
                <Input
                  type="select"
                  name="domain_id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.domain_id || ""}
                  invalid={validation.touched.domain_id && validation.errors.domain_id ? true : false}
                >
                  <option value="" disabled>Select an option</option> {/* Placeholder */}
                  {
                    domainSlections?.map((item, key) => {
                      return <option value={`${item?.id}`} key={key} >{`${item?.name}`}</option>
                    })
                  }
                </Input>
                {validation.touched.domain_id && validation.errors.domain_id ? (
                  <FormFeedback type="invalid">{validation.errors.domain_id}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col >
              <div className="form-check" >
                <CheckBox
                  className="form-check-input"
                  id="allowDesktopCheckbox"
                  name="allow_desktop"
                  label={"Allow Desktop"}
                  checked={validation?.values.allow_desktop}
                  onChange={({ name, value }) => {
                    validation?.setFieldValue(name, value)
                  }}
                />
              </div>
            </Col>
            <Col >
              <div className="form-check">
                <CheckBox
                  className="form-check-input"
                  id="allowMobilCheckbox"
                  name="allow_mobile"
                  label={"Allow Mobil"}
                  checked={validation?.values.allow_mobile}
                  onChange={({ name, value }) => {
                    validation?.setFieldValue(name, value)
                  }}
                />
              </div>
            </Col>
            <Col >
              <div className="form-check mb-3">
                <CheckBox
                  className="form-check-input"
                  id="allowParamCheckbox"
                  name="allow_params"
                  label={"Allow Params"}
                  checked={validation?.values.allow_params}
                  onChange={({ name, value }) => {
                    validation?.setFieldValue(name, value)
                  }}
                />
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">dnc_number_pool_script_url</Label>
                <Input
                  name="dnc_number_pool_script_url"
                  type="text"
                  // value={event ? event.title : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.dnc_number_pool_script_url || ""}
                  invalid={
                    validation.touched.dnc_number_pool_script_url && validation.errors.dnc_number_pool_script_url ? true : false
                  }
                />
                {validation.touched.dnc_number_pool_script_url && validation.errors.dnc_number_pool_script_url ? (
                  <FormFeedback type="invalid">{validation.errors.dnc_number_pool_script_url}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">dnc_number_to_replace</Label>
                <Input
                  name="dnc_number_to_replace"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.dnc_number_to_replace || ""}
                  invalid={
                    validation.touched.dnc_number_to_replace && validation.errors.dnc_number_to_replace ? true : false
                  }
                />
                {validation.touched.dnc_number_to_replace && validation.errors.dnc_number_to_replace ? (
                  <FormFeedback type="invalid">{validation.errors.dnc_number_to_replace}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Param List</Label>
                <TagsInput
                  value={selected}
                  onChange={setSelected}
                  name="param_list"
                  placeHolder="Enter Slug"
                />
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">slug</Label>
                <Input
                  name="slug"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.slug || ""}
                  invalid={
                    validation.touched.slug && validation.errors.slug ? true : false
                  }
                />
                {validation.touched.slug && validation.errors.slug ? (
                  <FormFeedback type="invalid">{validation.errors.slug}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Vertical</Label>
                <Input
                  type="select"
                  name="vertical"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.vertical || ""}
                  invalid={validation.touched.vertical && validation.errors.vertical ? true : false}
                >
                  <option value="" disabled>Select an option</option> {/* Placeholder */}
                  {
                    verticalSlections?.map((item, key) => {
                      return <option value={`${item?.id}`} key={key} >{`${item?.name}`}</option>
                    })
                  }
                </Input>
                {validation.touched.vertical && validation.errors.vertical ? (
                  <FormFeedback type="invalid">{validation.errors.vertical}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">ds_platform</Label>
                <Input
                  type="select"
                  name="ds_platform"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.ds_platform || ""}
                  invalid={validation.touched.ds_platform && validation.errors.ds_platform ? true : false}
                >
                  <option value="" disabled>Select an option</option> {/* Placeholder */}
                  {
                    deviceSlections?.map((item, key) => {
                      return <option value={`${item?.id}`} key={key} >{`${item?.name}`}</option>
                    })
                  }
                </Input>
                {validation.touched.ds_platform && validation.errors.ds_platform ? (
                  <FormFeedback type="invalid">{validation.errors.ds_platform}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">number_pool_script_url</Label>
                <Input
                  name="number_pool_script_url"
                  type="text"
                  // value={event ? event.title : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.number_pool_script_url || ""}
                  invalid={
                    validation.touched.number_pool_script_url && validation.errors.number_pool_script_url ? true : false
                  }
                />
                {validation.touched.number_pool_script_url && validation.errors.number_pool_script_url ? (
                  <FormFeedback type="invalid">{validation.errors.number_pool_script_url}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">number_to_replace</Label>
                <Input
                  name="number_to_replace"
                  type="text"
                  // value={event ? event.title : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.number_to_replace || ""}
                  invalid={
                    validation.touched.number_to_replace && validation.errors.number_to_replace ? true : false
                  }
                />
                {validation.touched.number_to_replace && validation.errors.number_to_replace ? (
                  <FormFeedback type="invalid">{validation.errors.number_to_replace}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-6">
              {/* 
            {!!isEdit && (
              <button
                type="button"
                className="btn btn-danger me-2"
                onClick={() => { deleteModal(lander) }}
              >
                Delete
              </button>
            )}
          */}
            </Col>
            <Col className="col-6 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-info me-2"
                onClick={handleReset}
              >
                Clear
              </button>
              
              <button type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>

  );
};

EditModal.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {


  return {

  };
}

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(EditModal)));

