import { takeEvery, put, call } from "redux-saga/effects"

import {
  EVENT_GET_PROCESS_LIST,
  EVENT_GET_PROCESS_LIST_SILENT
} from "./actionTypes";

import {
  requestProcessList,
  requestProcessListFail,
  requestProcessListSuccess,

  requestProcessListSilent,
  requestProcessListSilentFail,
  requestProcessListSilentSuccess
} from "./actions"

//Include Both Helper File with needed methods
import {
  getProcessListApi,
} from "../../helpers/fakebackend_helper"

function* onGetProcessList({ payload: body }) {
  try {
    yield put(requestProcessList())
    const response = yield call(getProcessListApi)
    yield put(requestProcessListSuccess(response))
  } catch (error) {
    yield put(requestProcessListFail(error))
  }
}

function* onGetProcessListSilent({ payload: body }) {
  try {
    yield put(requestProcessListSilent())
    const response = yield call(getProcessListApi)
    yield put(requestProcessListSilentSuccess(response))
  } catch (error) {
    yield put(requestProcessListSilentFail(error))
  }
}

function* processSaga() {
  yield takeEvery(EVENT_GET_PROCESS_LIST, onGetProcessList);
  yield takeEvery(EVENT_GET_PROCESS_LIST_SILENT, onGetProcessListSilent);
}

export default processSaga
