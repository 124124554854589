import React, { useEffect, useState, useMemo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";

import { useSelector, useDispatch, connect } from "react-redux";

import { getFileHistory, setProcess, getFileHistoryScroll } from "../../store/disposition/actions";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import Select from 'react-select'

import Table from "../../components/Common/Tabla/BostrapTable";

import LineChart from "../../components/Common/Charts/LineChart";

import { withTranslation } from "react-i18next";

import ContainerWithScroll from "../../components/Common/scrollContent/ContainerWithScroll";

import moment from "moment";

import ScreenA from "./ScreenA"

import styled from '@emotion/styled'

import { css } from '@emotion/react'
import { config } from "../../config";

import Logger from "../../helpers/Logger/Logger";


import "./loaders.css"


const SCard = styled(Card)`
  ${({ selected }) =>
    selected ?
      css`
        color: #10c772;
        outline: none; /* Para quitar el contorno predeterminado del enfoque 
        border: 2px solid green;*/
        box-shadow: 0 0 5px 2px rgba(0, 255, 0, 0.5);
      `:
      `box-shadow: 0 0 5px 2px rgba(128, 128, 128, 0.2);`
  }
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
  
  /* New animation for click */
  transition: transform 0.2s;
  &.click-animation {
    transform: scale(0.95);
  }
`;


const CardHeader = ({ title, onDelete, isMaster }) => {

  return (<div className="mx-3 mt-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
    <h4 className="card-title">{title}</h4>
    {isMaster && <button
      onClick={(e) => {
        e.stopPropagation();
        onDelete()
        // onDelete(key);
      }}
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        color: 'red',
      }}
    >

      <i className="fa fa-times" aria-hidden="true"></i>
    </button>
    }
  </div>
  )
}

const ScreenC = props => {

  const { data, chart, onGetFileHistory, isMaster, onGetFileHistoryScroll, loading_srcoll } = props

  const [selectedFile, setSelectedFile] = useState({ id: 0, ...data[0] } || [])
  const [dataList, setDataList] = useState([])
  const [chartData, setChartData] = useState([])
  const [selectedInfo, setSelectedInfo] = useState(null)

  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(0);

  const { BACKEND_URL } = config

  const scrollViewRef = useRef(null);


  useEffect(() => {
    console.log("dataxx", data?.length)
    console.log("dataxx", data)
    setSelectedFile({ id: 0, ...data[0] })
  }, [data])

  useEffect(() => {

    const charData = chart?.map((item) => {
      return { x: item?.day, y: item?.count }
    })

    console.log("chart:--", chart)

    setChartData(charData)
  }, [chart])

  useEffect(() => {

    console.log("dataList", dataList)

  }, [dataList])

  useEffect(() => {

    if (selectedFile) {
      const { data } = selectedFile

      if (data?.length) {
        setDataList(data)
      }

      console.log("selectedFile,data", data)

    }

    console.log("selectedFile", selectedFile)

    console.log("selectedFile", selectedFile)

  }, [selectedFile])


  const onDelete = async (_id) => {

    console.log("_id::", _id)

    let url = `${BACKEND_URL}/distposition/delete/${_id}`
    let { data } = await axios.delete(url)

    if (data?.success) {
      console.log("data::", data?.data)
      onGetFileHistory()
    }

  }


  useEffect(() => {

    // if (scrollViewRef.current){
    //   console.log("scrollViewRef.current", scrollViewRef.current)
    // }

    console.log("scrollViewRef.current", scrollViewRef)
    const { scrollTop, clientHeight, scrollHeight } = scrollViewRef.current
    console.log("scrollViewRef.current", scrollHeight)


  })


  const fetchData = useCallback(async () => {
    if (isLoading) return;
    if (loading_srcoll) return;

    
    // alert("Listo")

    setIsLoading(true);

    // onGetFileHistory(`?offset=${index}0&limit=2`)
    console.log("onGetFileHistoryScroll000...")
    Logger?.logger("onGetFileHistoryScroll000...")

    onGetFileHistoryScroll(`?offset=${index * 5}&limit=5`)
    console.log("onGetFileHistoryScroll...")
    Logger?.logger("onGetFileHistoryScroll000...")

    setIndex((prevIndex) => prevIndex + 1);

    setIsLoading(false);

    console.log("Completo...")
    Logger?.logger("Completo...")


  }, [index, isLoading, loading_srcoll]);

  useEffect(() => {
    console.log("index", index)
    Logger?.logger("index", index)

  }, [index]);

  useEffect(() => {
    const scrollElement = scrollViewRef.current;

    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = scrollElement;

      if (scrollTop + clientHeight >= scrollHeight - 20) {
        fetchData();
      }
    };

    scrollElement.addEventListener("scroll", handleScroll);
    return () => {
      scrollElement.removeEventListener("scroll", handleScroll);
    };
  }, [fetchData]);


  //meta title
  document.title = "Home | React Admin";

  return (
    <React.Fragment>
      <Row>
        <Col>
          <ScreenA data={selectedFile?.info} onSelected={(data) => {
            console.log("data--", data)
            setSelectedInfo(data)
          }} />
        </Col>
      </Row>
      <Row style={{
        height: "67vh",
        //  borderWidth: 1, borderColor: 'black', borderStyle: 'solid',
      }}>
        <Col sm="3" style={{
          height: "105%",
          // borderWidth: "1px", flex: 1 
        }}>
          <ContainerWithScroll ref={scrollViewRef}>
            <Row >
              <Col>
                {
                  data && data?.map((item, key) => {

                    const { fileName, userName, updatedAt } = item

                    let selected = false;

                    if (key == selectedFile?.id)
                      selected = true

                    return <SCard key={key} selected={selected} onClick={() => {
                      setSelectedFile({ id: key, ...item })
                    }} style={{ cursor: 'pointer' }}>
                      <CardHeader title={fileName} onDelete={() => { onDelete(item?._id) }}
                        isMaster={true}
                      // isMaster={isMaster} 
                      />
                      <CardBody className="pt-1">
                        <p className="card-title-desc mb-2">
                          {userName}{" "}
                          {moment(updatedAt).format('DD/MM/YYYY hh:mm')}
                        </p>
                        <Row className="icon-demo-content" id="newIcons"></Row>
                      </CardBody>
                    </SCard>
                  })
                }

                {loading_srcoll ? <Row>
                  <Col className="text-center">
                    <div className="snippet" data-title="dot-elastic">
                      <div className="stage">
                        <div className="dot-elastic"></div>
                      </div>
                    </div>
                  </Col>
                </Row> : null}
                
              </Col>
            </Row>
          </ContainerWithScroll>
        </Col>
        <Col sm="8" style={{
          height: "100%",
          // borderWidth: "1px", flex: 1 
        }}>
          <ContainerWithScroll>
            <Row >
              <Col>
                <Card style={{ height: 280 }}>
                  <CardBody style={{ padding: '10px' }}>
                    <LineChart chart={chartData} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Table data={dataList} selectedInfo={selectedInfo} title={selectedFile?.fileName ? `${selectedFile?.fileName} - ${moment(selectedFile?.updatedAt).format('DD/MM/YYYY hh:mm')}` : ''} />
              </Col>
            </Row>
          </ContainerWithScroll>
        </Col>
      </Row>
    </React.Fragment>
  );
};

ScreenA.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};


const mapStateToProps = ({ Login, Disposition }) => {

  const username = Login?.auth?.username

  const isMaster = ((username == "test") || (username == "GilbertoPB")) ? true : false;

  const loading_srcoll = Disposition?.loading_srcoll


  return {
    isMaster,
    loading_srcoll
  };
}

const mapDispatchToProps = (dispatch) => ({
  onGetFileHistory: (body) => dispatch(getFileHistory(body)),
  onGetFileHistoryScroll: (body) => dispatch(getFileHistoryScroll(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ScreenC)));

// export default withTranslation()(withRouter(ScreenA));
