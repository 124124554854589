import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import { withRouter } from "react-router-dom"

import {
    Progress as RProgress
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

const Progress = (props) => {

    const { data } = props

    const [processName, setProcessName] = useState(null);
    const [status, setStatus] = useState(null);
    const [porsentage, setPorsentage] = useState(null);

    useEffect(() => {
        const info = data?.info
        const processName = data?.processName
        const status = data?.status

        setProcessName(processName)
        setStatus(status)
        if(info){
            const {total, completed} = info
            const porsentage = (completed/total)*100
            setPorsentage(porsentage)
        }
        
        

    }, [data])


    return (
        <React.Fragment>
            {processName}
            <RProgress
                animated={(status == "InProgress")}
                value={porsentage}
                style={{
                    backgroundColor: '#ebebeb',
                    // '--bs-progress-bar-bg': '#616161' 
                    // '--bs-progress-bar-bg': '#d7424770'
                }}
            />
        </React.Fragment>
    );
};

Progress.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ ProcessMonitor }) => {

    const _processList = ProcessMonitor?.processList

    return {
        _processList,
    };
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(Progress)));

