import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes"

import {
  REQUEST_LOGIN_USER, REQUEST_LOGIN_SUCCESS, REQUEST_LOGIN_FAIL,
  EVENT_LOGOUT_USER, REQUEST_LOGOUT_SUCCESS, REQUEST_LOGOUT_FAIL,
  CHECKIN_LOGIN, CHECKIN_LOGIN_SUCCESS, CHECKIN_LOGIN_FAIL
} from "./actionTypes"

var auth_string = localStorage.getItem('authUser'); // Usuario autenticado
var token_string = localStorage.getItem('token');
var auth_json = JSON.parse(auth_string);
const final_auth = auth_json && auth_json._id ? auth_json : {}

const initialState = {
  loggedIn: (token_string && auth_string) ? true : false,
  auth: final_auth,

  message: '',
  loading: false,
  failed: false
}


const login = (state = initialState, action) => {
  switch (action.type) {
    //Checkin Login
    case CHECKIN_LOGIN:
      return { ...state }

    case CHECKIN_LOGIN_SUCCESS:
      const roles = action.payload.user.profile.roles || [];
      return { ...state, roles: roles }

    case CHECKIN_LOGIN_FAIL:
      return { 
        ...state, 
        loggedIn: false,
        auth: {},
      
        message: '',
        loading: false,
        failed: false
      }

    case REQUEST_LOGIN_USER:
      console.log(REQUEST_LOGIN_USER)
      return { ...state, message: '', loading: true, failed: false }

    case REQUEST_LOGIN_SUCCESS:
      return { ...state, loading: false, failed: false }

    case REQUEST_LOGIN_FAIL:
      console.log("--REQUEST_LOGIN_FAIL", REQUEST_LOGIN_FAIL)
      return { ...state, 
        message: action.payload, 
        loading: false, 
        failed: true 
      }

    case EVENT_LOGOUT_USER:
      return { ...state }

    case REQUEST_LOGOUT_SUCCESS:
      return {
        loggedIn: false,
        auth: null,
        roles : [],
        message: '',
        loading: false,
        failed: false
      }

    case LOGOUT_USER_SUCCESS:
      return {
        loggedIn: false,
        auth: null,
        roles : [],
        message: '',
        loading: false,
        failed: false
      }

    case REQUEST_LOGOUT_FAIL:
      return { ...state, message: action.payload, loading: false }

    default:
      return { ...state }
  }
}

// const login = (state = initialState, action) => {
//   switch (action.type) {
//     case LOGIN_USER:
//       state = {
//         ...state,
//         loading: true,
//       }
//       break
//     case LOGIN_SUCCESS:
//       state = {
//         ...state,
//         loading: false,
//       }
//       break
//     case LOGOUT_USER:
//       state = { ...state }
//       break
//     case LOGOUT_USER_SUCCESS:
//       state = { ...state }
//       break
//     case API_ERROR:
//       state = { ...state, error: action.payload, loading: false }
//       break
//     default:
//       state = { ...state }
//       break
//   }
//   return state
// }

export default login
