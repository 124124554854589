import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import "./test.css"

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import { Table } from 'reactstrap';

import axios from "axios";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import EditModal from './EditModal';

// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import Spinner from '../../components/Common/spinner/Spin';

import { useFormik } from "formik";

import { TagsInput } from "react-tag-input-component";

import {
  Form,
  FormFeedback,
  Label,
} from "reactstrap";

import * as Yup from "yup";

import styled from '@emotion/styled'

const StyledTable = styled(Table)`
  th, td {
    background-color: white;
    /* Otros estilos para los elementos <th> si es necesario */
  }

  .updated {
    background-color: #79f0b0cf;
    /* Otros estilos para los elementos <th> si es necesario */
  }

  .notFound {
    background-color: #f07983cf;
    /* Otros estilos para los elementos <th> si es necesario */
  }
`;


function generateQueryString(data) {

  if(!data?.length)
    return '';

  const queryString = data.map(item => `${item.name}=${encodeURIComponent(item.value)}`).join('&');
  return `?${queryString}`;
}

const DomainConfig = (props) => {

  const { buyers, fileList, lineChart, dispLoading } = props

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [domainSlections, setDomainSlections] = useState([])
  const [userAgentSlections, setUserAgentSlections] = useState([])
  const [requestUrl, setRequestUrl] = useState('')
  const [selected, setSelected] = useState([]);
  const [paramsList, setParamsList] = useState([]);

  const [clientUrl, setClientUrl] = useState("");

  const getDomainselections = async () => {
    let url = `${BACKEND_URL}/domain/get-selections`
    let { data } = await axios.get(url)

    console.log("data", data)

    if (data?.success) {
      const { domainSlections } = data

      setDomainSlections(domainSlections)
    }

  }

  useEffect(() => {

    getDomainselections()

  }, [])

  useEffect(() => {

  }, [paramsList])


  useEffect(() => {

    const urlParams = generateQueryString(paramsList);
    const url = urlParams

    setClientUrl(url)

  })

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      domain_name: '',
      hosted: false,
      pub_exclusive: false,
      publisher_id: '',
      publisher_name: '',
      registrar: '',
      paramKey: '',
      paramValue: '',
    },
    validationSchema: Yup.object({
      domain_name: Yup.string().required("Please Enter a domain_name"),
      publisher_id: Yup.string().required("Please Enter a publisher_id"),
      publisher_name: Yup.string().required("Please Enter publisher_name"),
      registrar: Yup.string().required("Please Enter a registrar"),
    }),
    onSubmit: (values) => {

    },
  });

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '100vh',
        }}
      >
        <Container fluid style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '80vh',
          // border: '1px solid black'
        }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Lander Testing")}
            breadcrumbItem={props.t("Lander Testing")}
          />
          <Row style={{
            margin: 10, height: "100%",
            // border: "1px solid red" 
          }}>
            <Col sm="6">
              <Card style={{ height: "100%" }} >
                <CardBody>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="my-2 h2">Test Form</div>
                  </div>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    <Row>
                      <Col className="col-12">
                        <p>{`Client URL: ${clientUrl}`}</p>
                      </Col>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Request URL </Label>
                          <Input
                            name="domain_name"
                            type="text"
                            // value={event ? event.title : ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.domain_name || ""}
                            invalid={
                              (validation.touched.domain_name && validation.errors.domain_name) ? true : false
                            }
                          />
                          {validation.touched.domain_name && validation.errors.domain_name ? (
                            <FormFeedback type="invalid">{validation.errors.domain_name}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">User Agent</Label>
                          <Row>
                            <Col className="col-10">
                              <Input
                                type="select"
                                name="domain_id"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.domain_id || ""}
                                invalid={validation.touched.domain_id && validation.errors.domain_id ? true : false}
                              >
                                <option value="" disabled>Select an option</option> {/* Placeholder */}
                                {
                                  userAgentSlections?.map((item, key) => {
                                    return <option value={`${item?.id}`} key={key} >{`${item?.name}`}</option>
                                  })
                                }
                              </Input>
                              {validation.touched.domain_id && validation.errors.domain_id ? (
                                <FormFeedback type="invalid">{validation.errors.domain_id}</FormFeedback>
                              ) : null}
                            </Col>
                            <Col className="col-2">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => { 
                                    setModal(true)
                                  
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Domain List</Label>
                          <Input
                            type="select"
                            name="domain_id"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.domain_id || ""}
                            invalid={validation.touched.domain_id && validation.errors.domain_id ? true : false}
                          >
                            <option value="" disabled>Select an option</option> {/* Placeholder */}
                            {
                              domainSlections?.map((item, key) => {
                                return <option value={`${item?.id}`} key={key} >{`${item?.name}`}</option>
                              })
                            }
                          </Input>
                          {validation.touched.domain_id && validation.errors.domain_id ? (
                            <FormFeedback type="invalid">{validation.errors.domain_id}</FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Path/Slug</Label>
                          <TagsInput
                            value={selected}
                            onChange={setSelected}
                            name="slug"
                            placeHolder="Enter Slug"
                          />
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Label className="form-label">Params</Label>
                          <Row>
                            <Col className="col-5">
                              <Label className="form-label">KEY</Label>
                            </Col>
                            <Col className="col-5">
                              <Label className="form-label">VALUE</Label>
                            </Col>
                            <Col className="col-2">
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-5">
                              <Input
                                name="paramKey"
                                type="text"
                                // value={event ? event.title : ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.paramKey || ""}
                              />
                            </Col>
                            <Col className="col-5">
                              <Input
                                name="paramValue"
                                type="text"
                                // value={event ? event.title : ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.paramValue || ""}
                              />
                            </Col>
                            <Col className="col-2">
                              <div className="mb-3">
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => { 
                                    const data = paramsList
                                    data?.push({id: paramsList?.length, name: validation.values.paramKey, value: validation.values.paramValue})
                                    setParamsList(data)

                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="col-10">
                              {paramsList?.length ? <StyledTable style={{ backgroundColor: "white" }}>
                                <thead>
                                  <tr>
                                    <th>KEY</th>
                                    <th>VALUE</th>
                                    <th className="small-column"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    paramsList?.map((item, k) => {
                                      return <tr key={k}>
                                        <td>{item?.name}</td>
                                        <td>{item?.value}</td>
                                        <td className="small-column"> <button size="sm"
                                          type="button"
                                          className="btn btn-primary btn-sm"
                                          onClick={() => { 
                                            const updatedData = paramsList.filter(value => value.id !== item?.id);

                                            setParamsList(updatedData)
                                            
                                          }}
                                        >
                                          <i className="bx  bx-trash" id="deletetooltip" ></i>

                                        </button></td>
                                      </tr>
                                    })
                                  }
                                </tbody>
                              </StyledTable> : null
                              }
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col className="col-6">

                      </Col>
                      <Col className="col-6 text-end">
                        <button
                          type="button"
                          className="btn btn-light me-2"
                          onClick={() => { }}
                        >
                          Clear
                        </button>
                        <Button type="submit"
                          className="btn btn-success"
                          id="btn-save-event"
                        >
                          Send
                        </Button>
                      </Col>
                    </Row>
                  </Form>

                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <Card style={{ height: "100%" }} >
                <CardBody>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="my-2 h2">Test Response</div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <EditModal
        toggle={toggle}
        isOpen={modal}
        onSubmit={async(values)=>{
          
          console.log("Value", values)

          const data = userAgentSlections
          data?.push({id: userAgentSlections?.length, name: values.agent_name, value: values.agent_string})
          setUserAgentSlections(data)

          setModal(false)
        }}
      />
    </React.Fragment>
  );
};

DomainConfig.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {


  return {

  };
}

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(DomainConfig)));

