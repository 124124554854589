class Logger {

	static logger = console.log;

	static async removeLogger() {
        this.logger = console.log
        // console.log = ()=>{}
	}

}

export default Logger;
