import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { EVENT_SIGNUP_USER } from "./actionTypes"
import {
  RequestSignUpUser, RequestSignUpUserSuccess, RequestSignUpUserFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  dispositionSignUpApi,
} from "../../../helpers/fakebackend_helper";

function* singUpUser({ payload: { user, history } }) { // Nuevo
  try {
    console.log("data----", { user, history })
    yield put(RequestSignUpUser())

    console.log("RequestSignUpUser")
    const response = yield call(dispositionSignUpApi, { ...user });
    console.log("response::", response)

    yield put(RequestSignUpUserSuccess(response));

    window.location.href = "/login"

    // history?.push("/login");
    
  } catch (error) {
    console.log("error", error)
    const { response } = error

    console.log("response", response)
    if(response){
      const { data } = response
      console.log("Error: data", data)
      yield put(RequestSignUpUserFail(data?.message))
    } else {
      yield put(RequestSignUpUserFail(error?.message));
    }

  }
}

function* signUpSaga() {
  yield takeEvery(EVENT_SIGNUP_USER, singUpUser);
}

export default signUpSaga;
