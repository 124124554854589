import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Collapse, Box } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

const ExpandableRow = ({ row }) => {

    const [headList] = useState(row || null);

    const propertyNames = headList?.length ? Object.keys(headList[0]) : []
    console.log("propertyNames", propertyNames)

    return (
        <>
            <TableRow style={{ backgroundColor: '#f0f0f0', padding: 0 }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={true} timeout="auto" unmountOnExit>
                        <Table>
                            <TableHead style={{ backgroundColor: '#f0f0f0' }}>
                                <TableRow>
                                    {
                                        propertyNames?.map((item, key) => (
                                            <TableCell key={key} style={{ padding: '8px', fontWeight: 'bold' }}>{item}</TableCell>
                                        ))
                                    }
                                    {/* ... */}
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ backgroundColor: '#f0f0f0' }}>
                                {row?.map((item, key) => (
                                    <React.Fragment key={key}>
                                        <TableRow>
                                            {propertyNames?.map((name, nameKey) => (
                                                <TableCell key={nameKey} style={{ padding: '8px' }}>{item[name]}</TableCell>
                                            ))}
                                        </TableRow>
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const DataTable = ({ data }) => {
    const [expandedRow, setExpandedRow] = useState(null);

    const handleRowClick = (rowId) => {
        setExpandedRow(expandedRow === rowId ? null : rowId);
    };

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '1%' }} />
                        {/* Add your other header cells here */}
                        <TableCell style={{ width: '1%' }}>id</TableCell>
                        {/* <TableCell style={{ width: '5%' }}>Actions</TableCell> */}
                        <TableCell style={{ width: '10%' }}>Domain</TableCell>
                        <TableCell style={{ width: '20%' }}>Total</TableCell>

                        {/* <TableCell>Column 2</TableCell> */}
                        {/* ... */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <React.Fragment key={row.id}>
                            <TableRow onClick={() => handleRowClick(row.id)}>
                                <TableCell style={{ width: '1%' }} >
                                    <IconButton size="small">
                                        {expandedRow === row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                    </IconButton>
                                </TableCell>
                                {/* Add your other row cells here */}
                                <TableCell style={{ width: '5%' }}>{row.id}</TableCell>
                                {/* <TableCell style={{ width: '5%' }}>{""}</TableCell> */}
                                <TableCell style={{ width: '10%' }}>{row.Domain}</TableCell>
                                <TableCell style={{ width: '20%' }}>{row?.expandedContent?.length}</TableCell>

                                {/* <TableCell>{row.column2}</TableCell> */}
                                {/* ... */}
                            </TableRow>
                            {expandedRow === row.id && <ExpandableRow row={row?.expandedContent} />}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default DataTable;
