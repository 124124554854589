import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import TableContainer from "../../components/Common/TableContainer";

import {
  Actions,
  DomainId,
  AllowDesktop,
  AllowMobile,
  AllowParams,
  DncNumberPoolScript,
  DncNumberToReplace,
  DsPlatform,
  PublisherId,
  PublisherName,
  NumberPoolScriptUrl,
  NumberToReplace,
  Slug,
  Vertical
} from "./LatestTranactionCol";

import CheckBox from "./checkBox";


// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { TagsInput } from "react-tag-input-component";

//i18n
import { withTranslation } from "react-i18next";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";

import Spinner from '../../components/Common/spinner/Spin';
// import { latestTransaction } from "../../common/data/dashboard";
import { useFormik } from "formik";

import * as Yup from "yup";

import {
  Form,
  FormFeedback,
  Label,
} from "reactstrap";

function tieneFormatoDeDominio(texto) {
  // Utilizamos una expresión regular para verificar si el texto sigue el formato de un dominio
  var regex = /^[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  // Probamos si la expresión regular coincide con el texto
  return regex.test(texto);
}

const EditModal = (props) => {

  const { toggle, isOpen, domain, isEdit, onSubmit, domainList, publisherList, isLoading } = props

  const [hostedCheckBox, setHostedCheckBox] = useState((domain?.hosted == 1) || false);
  const [checkBox, setCheckBox] = useState((domain?.hosted == 1) || false);
  const [checkBoxPub_exclusive, setCheckBoxPub_exclusive] = useState((domain?.hosted == 1) || false);

  const [domainNames, setDomainNames] = useState(null);
  const [domainNamesError, setDomainNamesError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setHostedCheckBox((domain?.hosted == 1))
  }, [domain])


  const getPublisherName = (id)=>{
    
    const publisherName = publisherList?.filter((item)=>(item?.id == id))[0]?.name
    return publisherName

  }

  useEffect(() => {
    const domainNameList = domainList?.map(item => item.domain_name);

    const domainNames = domainNameList?.filter(name => (name != domain?.domain_name))
    setDomainNames(domainNames)
    console.log("domain?", domain)
    console.log("domain?.domain_name", domain?.domain_name)
  }, [domain])

  // events validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      domain_name: domain?.domain_name || '',
      hosted: (domain?.hosted == 1) || false,
      pub_exclusive: (domain?.pub_exclusive == 1) || false,
      publisher_id: domain?.publisher_id || '',
      registrar: domain?.registrar || '',
    },
    validationSchema: Yup.object({
      domain_name: Yup.string().required("Please Enter a domain_name"),
      publisher_id: Yup.string().required("Please Enter a publisher_id"),
      registrar: Yup.string().required("Please Enter a registrar"),
    }),
    onSubmit: (values) => {
      const publisher_name = getPublisherName(values?.publisher_id);

      // console.log("publisherList", publisherList)


      const value = {
        id: domain?.id,
        ...values,
        publisher_name: publisher_name,
        hosted: values?.hosted ? 1 : 0,
        pub_exclusive: values?.pub_exclusive ? 1 : 0,
      };

      const domain_name = values?.domain_name

      console.log("domain?", domainNames)

      if (domainNames?.includes(domain_name) && !isEdit) {
        setErrorMessage("This domain name already exist")
        setDomainNamesError(true)
        return
      }

      const isError = !tieneFormatoDeDominio(domain_name)

      console.log("isError:", isError)

      if (isError) {
        setErrorMessage("The domain format is not in the correct")
        setDomainNamesError(true)
        return
      }

      setDomainNamesError(false)
      onSubmit(value)
      // toggle();
    },
  });

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      className={props.className}
      centered
    >
      <ModalHeader toggle={toggle} tag="h5" className="py-3 px-4 border-bottom-0">
        {!!isEdit ? "Edit Domain" : "Add Domain"}
      </ModalHeader>
      <ModalBody className="p-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Domain Name</Label>
                <Input
                  name="domain_name"
                  type="text"
                  // value={event ? event.title : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.domain_name || ""}
                  invalid={
                    ((validation.touched.domain_name && validation.errors.domain_name) || domainNamesError) ? true : false
                  }
                />
                {validation.touched.domain_name && validation.errors.domain_name ? (
                  <FormFeedback type="invalid">{validation.errors.domain_name}</FormFeedback>
                ) : null}
                {
                  domainNamesError ? (
                    <FormFeedback type="invalid">{errorMessage}</FormFeedback>
                  ) : null
                }
              </div>
            </Col>
            <Col >
              <div className="form-check" >
                <CheckBox
                  className="form-check-input"
                  id="hostedCheckbox"
                  name="hosted"
                  label={"Hosted"}
                  defaultChecked={validation?.values.hosted}
                  checked={validation?.values.hosted}
                  onChange={({ name, value }) => {
                    validation?.setFieldValue(name, value)
                  }}
                />
              </div>
            </Col>
            <Col >
              <div className="form-check">
                <CheckBox
                  className="form-check-input"
                  id="pubExclusiveCheckbox"
                  name="pub_exclusive"
                  label={"pub_exclusive"}
                  defaultChecked={validation?.values.pub_exclusive}
                  checked={validation?.values.pub_exclusive}
                  onChange={({ name, value }) => {
                    validation?.setFieldValue(name, value)
                  }}
                />
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">Publisher</Label>
                <Input
                  type="select"
                  name="publisher_id"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation?.values?.publisher_id || ""}
                  invalid={validation.touched.publisher_id && validation.errors.publisher_id ? true : false}
                >
                  <option value="" disabled>Select an option</option> {/* Placeholder */}
                  {
                    publisherList?.map((item, key) => {
                      return <option value={`${item?.id}`} key={key} >{`${item?.name}`}</option>
                    })
                  }
                </Input>
                {validation.touched.publisher_id && validation.errors.publisher_id ? (
                  <FormFeedback type="invalid">{validation.errors.publisher_id}</FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col className="col-12">
              <div className="mb-3">
                <Label className="form-label">registrar</Label>
                <Input
                  name="registrar"
                  type="text"
                  // value={event ? event.title : ""}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.registrar || ""}
                  invalid={
                    validation.touched.registrar && validation.errors.registrar ? true : false
                  }
                />
                {validation.touched.registrar && validation.errors.registrar ? (
                  <FormFeedback type="invalid">{validation.errors.registrar}</FormFeedback>
                ) : null}
              </div>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-6">
              {/*{!!isEdit && (
                <button
                  type="button"
                  className="btn btn-danger me-2"
                  onClick={()=>{ deleteModal(domain) }}
                >
                  Delete
                </button>
              )}*/}
            </Col>
            <Col className="col-6 text-end">
              <button
                type="button"
                className="btn btn-light me-2"
                onClick={toggle}
              >
                Close
              </button>
              <Button type="submit"
                className="btn btn-success"
                id="btn-save-event"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>

  );
};

EditModal.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {


  return {

  };
}

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(EditModal)));

