import React from "react";
import Papa from "papaparse";


const CSVExportButton = ({ data, filename, publisherList }) => {

  const getPublisherName = (publisherId) => {
    // Buscar el objeto en publisherList con el mismo ID y devolver el nombre
    const matchingPublisher = publisherList.find(publisher => publisher.id === publisherId);
    return matchingPublisher ? matchingPublisher.name : '';
  };

  const transformData = (originalData) => {
    // 1. Unir las columnas 'domain_name' y 'slug' en 'URL'
    const transformedData = originalData.map((row) => ({
      ...row,
      URL: `${row.domain_name}/${row.slug}`,
      domain: row.domain ? JSON.stringify(row.domain) : "",
      publisher_name: getPublisherName(row.publisher_id),
    }));

    // 3. Eliminar la columna 'update_at'
    transformedData.forEach((row) => {
      delete row.update_at;
    });

    // 4. Mover la columna 'create_at' al final
    transformedData.forEach((row) => {
      const createdAt = row.create_at;
      delete row.create_at;
      row.create_at = createdAt;
    });

    // 5. Convertir valores booleanos a 'SÍ' y 'NO'
    transformedData.forEach((row) => {
      Object.keys(row).forEach((key) => {
        if (row[key] == 1 || row[key] == 0) {
          row[key] = row[key] ? "YES" : "NO";
        }
      });
    });

    return transformedData;
  };

  const exportCSV = () => {
    // Apply transformations to the data
    const transformedData = transformData(data);

    // 2. Cambiar el orden de las columnas, colocando 'vertical' como segunda columna
    const reorderedColumns = [
      "id", 
      "vertical", 
      "URL", 
      "hosted", 
      "pub_exclusive", 
      "publisher_name", 
      "publisher_id", 
      "registrar", 
      "available", 
      "domain_id", 
      "allow_desktop", 
      "allow_mobile", 
      "allow_params", 
      "dnc_number_pool_script_url", 
      "dnc_number_to_replace", 
      "ds_platform", 
      "hours_open", 
      "hours_closed", 
      "param_list", 
      "number_pool_script_url", 
      "number_to_replace", 
      "slug", 
      "domain_name",
      "ACA", 
      "domain", 
      "created_at"
    ];

    // Create a new array of objects with the reordered columns
    const csvData = transformedData.map((row) => {
      const newRow = {};
      reorderedColumns.forEach((col) => {
        newRow[col] = row[col];
      });
      return newRow;
    });

    const csv = Papa.unparse(csvData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename || "data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <button onClick={exportCSV} className="btn btn-primary btn-sm">
      Export as CSV
    </button>
  );
};

export default CSVExportButton;
