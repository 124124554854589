module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  config: {
    // BACKEND_URL: (process.env.NODE_ENV !== 'production') ? 'http://localhost:8083' : (process.env.API_URL || 'https://workspace.policybind.com/api'),
    BACKEND_URL: (process.env.NODE_ENV !== 'production') ? 'https://policybind-api-panel.ngrok.io' : (process.env.API_URL || 'https://workspace.policybind.com/api'),
    RTB_BACKEND_URL: (process.env.NODE_ENV !== 'production') ? 'http://localhost:8001/api' : (process.env.API_URL || 'https://srv4.policybind.com/api'),
  }
}
