import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import DropZone from "../../components/Common/Import/DropZone"; 

import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Badge,
} from "reactstrap";

import axios from "axios";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import Select from 'react-select'

import { withTranslation } from "react-i18next";

const ScreenB = props => {
  const [buyerList, setBuyerList] = useState([]);

  //meta title
  document.title = "Home | React Admin";

  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' }
  ]


  const getBuyers = async () => {
    let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
    let url = "https://api.ringba.com/v2/RA27f335147c2d487fba01c2732feaecb0/Buyers"
    let { data } = await axios.get(url, {
      headers: {
        'Authorization': `Token ${token}`
      }
    })

    let { buyers } = data

    // console.log("getBuyer:", buyers)

    let buyerList = buyers.map(({ accountId, name }) => {

      return { value: accountId, label: name }
    })

    setBuyerList(buyerList)

  }


  useEffect(() => {

    getBuyers()

  }, []);

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '100vh',
          border: '1px solid black'
        }}
      >        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Homes")}
            breadcrumbItem={props.t("Home")}
          />

          <Row style={{ margin: 10 }}>
            <Col lg="4">
              <Select options={buyerList} />
            </Col>
          </Row>

          <DropZone />
          
        </Container>
      </div>

    </React.Fragment>
  );
};

ScreenB.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withTranslation()(withRouter(ScreenB));
