import {
  EVENT_GET_FILE_LIST,
  REQUEST_FILE_LIST,
  REQUEST_FILE_LIST_FAIL,
  REQUEST_FILE_LIST_SUCCESS,

  EVENT_PROCESS,
  REQUEST_PROCESS,
  REQUEST_PROCESS_FAIL,
  REQUEST_PROCESS_SUCCESS,


  EVENT_GET_FILE_LIST_SCROLL,
  REQUEST_FILE_LIST_SCROLL,
  REQUEST_FILE_LIST_SCROLL_FAIL,
  REQUEST_FILE_LIST_SCROLL_SUCCESS,

  EVENT_GET_LIST_DATA,
  REQUEST_LIST_DATA ,
  REQUEST_LIST_DATA_FAIL,
  REQUEST_LIST_DATA_SUCCESS,

  EVENT_SET_DATE_FILTER,

  SET_DATE_FILTER,

  EVENT_GET_DATA_DATE,
  REQUEST_ONE_DATA_DATE,
  REQUEST_ONE_DATA_DATE_FAIL,
  REQUEST_ONE_DATA_DATE_SUCCESS,

  EVENT_DELETE_FILE,
  SET_DELETE_FILE,


  EVENT_PROCESS_NEW,
  REQUEST_PROCESS_NEW,
  REQUEST_PROCESS_NEW_FAIL,
  REQUEST_PROCESS_NEW_SUCCESS,

} from "./actionTypes";


// export const getBuyers = () => ({ type: EVENT_BUYERS });
// export const requestBuyers = () => ({ type: REQUEST_BUYERS });
// export const requestBuyersFail = (payload) => ({ type: REQUEST_BUYERS_FAIL, payload });
// export const requestBuyersSuccess = (payload) => ({ type: REQUEST_BUYERS_SUCCESS, payload });


export const getFileHistory = (payload) => ({ type: EVENT_GET_FILE_LIST, payload });

export const requestFileHistory = () => ({ type: REQUEST_FILE_LIST });
export const requestFileHistoryFail = (payload) => ({ type: REQUEST_FILE_LIST_FAIL, payload });
export const requestFileHistorySuccess = (payload) => ({ type: REQUEST_FILE_LIST_SUCCESS, payload });

export const setProcess = (payload) => ({ type: EVENT_PROCESS, payload  });
export const requestProcess = () => ({ type: REQUEST_PROCESS });
export const requestProcessFail = (payload) => ({ type: REQUEST_PROCESS_FAIL, payload });
export const requestProcessSuccess = (payload) => ({ type: REQUEST_PROCESS_SUCCESS, payload });

export const getFileHistoryScroll = (payload) => ({ type: EVENT_GET_FILE_LIST_SCROLL, payload });
export const requestFileHistoryScroll = () => ({ type: REQUEST_FILE_LIST_SCROLL });
export const requestFileHistoryScrollFail = (payload) => ({ type: REQUEST_FILE_LIST_SCROLL_FAIL, payload });
export const requestFileHistoryScrollSuccess = (payload) => ({ type: REQUEST_FILE_LIST_SCROLL_SUCCESS, payload });

export const getListData = (payload) => ({ type: EVENT_GET_LIST_DATA, payload });
export const requestListData = () => ({ type: REQUEST_LIST_DATA });
export const requestListDataFail = (payload) => ({ type: REQUEST_LIST_DATA_FAIL, payload });
export const requestListDataSuccess = (payload) => ({ type: REQUEST_LIST_DATA_SUCCESS, payload });


export const setDateFilter = (payload) => ({ type: EVENT_SET_DATE_FILTER, payload });

export const requestSetDateFilter = (payload) => ({ type: SET_DATE_FILTER, payload });

export const getOneDataDate = (payload) => ({ type: EVENT_GET_DATA_DATE, payload });
export const requestOneDataDate = () => ({ type: REQUEST_ONE_DATA_DATE });
export const requestOneDataDateFail = (payload) => ({ type: REQUEST_ONE_DATA_DATE_FAIL, payload });
export const requestOneDataDateSuccess = (payload) => ({ type: REQUEST_ONE_DATA_DATE_SUCCESS, payload });


export const deleteFile = (payload) => ({ type: EVENT_DELETE_FILE, payload });
export const setDeleteFile = (payload) => ({ type: SET_DELETE_FILE, payload });




export const setProcessNew = (payload) => ({ type: EVENT_PROCESS_NEW, payload  });
export const requestProcessNew = () => ({ type: REQUEST_PROCESS_NEW });
export const requestProcessNewFail = (payload) => ({ type: REQUEST_PROCESS_NEW_FAIL, payload });
export const requestProcessNewSuccess = (payload) => ({ type: REQUEST_PROCESS_NEW_SUCCESS, payload });