import {
  EVENT_GET_LINE_CHART,
  REQUEST_LINE_CHART,
  REQUEST_LINE_CHART_FAIL,
  REQUEST_LINE_CHART_SUCCESS,

} from "./actionTypes";

export const getLineChart = () => ({ type: EVENT_GET_LINE_CHART });
export const requestLineChart = () => ({ type: REQUEST_LINE_CHART });
export const requestLineChartFail = (payload) => ({ type: REQUEST_LINE_CHART_FAIL, payload });
export const requestLineChartSuccess = (payload) => ({ type: REQUEST_LINE_CHART_SUCCESS, payload });




