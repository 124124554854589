import React from 'react';
import {
    Input,
    Label,
    FormFeedback
} from "reactstrap";

function DropDown(props) {
    const { onChange, value, invalid, disabled, data, onBlur, label, name } = props;
    
    return <div className="mb-3">
        <Label className="form-label">{label}</Label>
        <Input
            type="select"
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value || ""}
            invalid={invalid ? true : false}
        >
            <option value="" disabled>Select an option</option> {/* Placeholder */}
            {
                data?.map((item, key) => {
                    return <option value={`${item?.id}`} key={key} >{`${item?.name}`}</option>
                })
            }
        </Input>
        {invalid ? (
            <FormFeedback type="invalid">{validation.errors.publisher_id}</FormFeedback>
        ) : null}
    </div>
}

export default DropDown;