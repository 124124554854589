import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import DataTable from "./dataTable";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import TableContainer from "../../components/Common/TableContainer";
import CSVExportButton from "./CSVExportButton";

import {
  Actions,
  DomainName,
  DncNumber,
  DsPlatform,
  PublisherName,
  Number,
  Slug,
  Vertical,
  ParamList,
  Options,
  Time
} from "./LatestTranactionCol";

// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { config } from "../../config";

//i18n
import { withTranslation } from "react-i18next";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";

import Spinner from '../../components/Common/spinner/Spin';
// import { latestTransaction } from "../../common/data/dashboard";
import { useFormik } from "formik";
import * as Yup from "yup";

import EditModal from './EditModal';


import {
  Form,
  FormFeedback,
  Label,
} from "reactstrap";

const LanderConfig = (props) => {

  const { buyers, fileList, lineChart, dispLoading } = props

  const { BACKEND_URL } = config

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [lander, setLander] = useState();

  const [landerList, setLanderList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [domainList, setDomainList] = useState([]);
  const [newLanderList, setNewLanderList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const [search, setSearch] = useState('');


  const toggle = () => setModal(!modal);

  const getData = async () => {
    // let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
    let url = `${BACKEND_URL}/lander/get-list`
    let { data } = await axios.get(url)

    if (data?.success) {
      const { landerList } = data
      console.log("landerList", landerList)

      setLanderList(landerList)
    }

    // setLanderList
  }

  const getDomainList = async () => {
    let url = `${BACKEND_URL}/domain/get-selections`
    let { data } = await axios.get(url)

    if (data?.success) {
      const { domainSlections } = data

      setDomainList(domainSlections)
    }

  }

  const onCreate = async (value) => {
    let url = `${BACKEND_URL}/lander/create`
    let { data } = await axios.post(url, value)

    if (data?.success) {
      await getData()
    }
    // setLander(null)
    // setDomainList
  }
  const onUpdate = async (values) => {
    const { id } = values
    let url = `${BACKEND_URL}/lander/update/${id}`
    let { data } = await axios.put(url, values)

    if (data?.success) {
      await getData()
    }
    setLander(null)
  }

  const deleteById = async (id) => {
    let url = `${BACKEND_URL}/lander/delete/${id}`
    let { data } = await axios.delete(url)

    if (data?.success) {
      await getData()
    }

  }

  const getPublisherList = async () => {
    let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
    let url = `https://api.ringba.com/v2/RA27f335147c2d487fba01c2732feaecb0/Publishers`
    let { data } = await axios.get(url, {
      headers: {
        'Authorization': `Token ${token}`
      }
    })

    if (data) {
      const { publishers } = data
      setPublisherList(publishers)
    }

  }

  const addModal = async (data) => {
    if (data) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
    setLander(data)
    setModal(true)
  }

  const deleteModal = async (data) => {
    await deleteById(data?.id)
    setModal(false)
  }

  useEffect(() => {
    getData()
    getDomainList()
  }, [])

  useEffect(() => {

    console.log("publisherList::", publisherList);

  }, [publisherList])

  useEffect(() => {

    console.log("publisherList::", publisherList);

    const landerUpdated = landerList?.map((item)=>{
      const publisherName = publisherList?.filter((obj)=> obj?.id==item?.publisher_id)[0]?.name

      return {
        ...item, 
        publisher_name: publisherName
      }
    })

    setLanderList(landerUpdated)
  }, [publisherList])

 

  useEffect(() => {
    if(publisherList?.length == 0){
      getPublisherList()
    }
  }, [landerList])

  const handleSearchChange = (event) => {
    const newValue = event.target.value;
    setSearch(newValue);
    // console.log("data:", domainList[0]);
    // Filtrar la lista basándose en el campo 'domain_name'
    const filteredList = landerList.filter(item => {

      const available = (item?.available) ? "Available" : "Not Available"
      const subtextos = JSON.parse(item?.param_list);
      const regex = new RegExp(subtextos.join("|"), "i");

      return item?.domain_name?.toLowerCase().includes(newValue.toLowerCase())
        || item?.slug?.toLowerCase().includes(newValue.toLowerCase())
        || item?.number_to_replace?.toLowerCase().includes(newValue.toLowerCase())
        || item?.number_pool_script_url?.toLowerCase().includes(newValue.toLowerCase())
        || item?.dnc_number_to_replace?.toLowerCase().includes(newValue.toLowerCase())
        || item?.dnc_number_pool_script_url?.toLowerCase().includes(newValue.toLowerCase())
        || item?.vertical?.toLowerCase().includes(newValue.toLowerCase())
        || item?.ds_platform?.toLowerCase().includes(newValue.toLowerCase())
        || item?.dnc_number_pool_script_url?.toLowerCase().includes(newValue.toLowerCase())
        || item?.publisher_name?.toLowerCase().includes(newValue.toLowerCase())
        || regex.test(item?.params?.toLowerCase());
    }
    );

    // Actualizar la lista filtrada
    setFilteredList(filteredList);
  };


  useEffect(() => {

    console.log("filteredList", filteredList)

    let count = 0
    const transformedArray = filteredList.reduce((result, item) => {
      const domainName = item.domain_name;

      // Verificar si ya existe una entrada para el domain_name
      const existingEntry = result.find(entry => entry.Domain === domainName);
      const cellProps = { row: { original: item } }
      if (existingEntry) {
        // Si existe, agregar las columnas al arreglo de expandedContent
        existingEntry.expandedContent.push({
          l_id: item?.id,
          slug: item?.slug,
          Time: <Time {...cellProps} />,
          Options: <Options {...cellProps} />,
          // allow_mobile: item.allow_mobile,
          // allow_params: item.allow_params,
          "Dnc Number": <DncNumber {...cellProps} />,
          "Param List": <ParamList {...cellProps} />,
          "DS Platform": <DsPlatform {...{ value: item?.ds_platform }} />,
          "Publisher Name": item?.publisher_name,
          "Number": <Number {...cellProps} />,
          vertical: <Vertical {...{ value: item?.vertical }} />,
          available: item.available,
          // created_at: item.created_at,
          // d_id: item.d_id,
        });

        // const length = existingEntry.expandedContent?.length
        // existingEntry?.count = length;
      } else {
        // Si no existe, crear una nueva entrada con el domain_name y las columnas en expandedContent
        count++;
        const newEntry = {
          id: count,
          Domain: domainName,
          count: 1,
          expandedContent: [
            {
              l_id: item?.id,
              slug: item?.slug,
              Time: <Time {...cellProps} />,
              Options: <Options {...cellProps} />,
              // allow_mobile: item.allow_mobile,
              // allow_params: item.allow_params,
              "Dnc Number": <DncNumber {...cellProps} />,
              "Param List": <ParamList {...cellProps} />,
              "DS Platform": <DsPlatform {...{ value: item?.ds_platform }} />,
              "Publisher Name": item?.publisher_name,
              "Number": <Number {...cellProps} />,
              vertical: <Vertical {...{ value: item?.vertical }} />,
              available: item.available,
              // created_at: item.created_at,
              // d_id: item.d_id,
            }
          ]
        };

        // Agregar la nueva entrada al resultado
        result.push(newEntry);
      }

      return result;
    }, []);

    setNewLanderList(transformedArray)

  }, [filteredList])


  useEffect(() => {

    if (search == "") {
      setFilteredList(landerList);
    }

  }, [landerList, search, publisherList])

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   },
      // },
      {
        Header: "Id",
        accessor: "id",
        disableFilters: true,
        hidden: true,
        Cell: cellProps => {
          const { row } = cellProps?.cell
          const { values } = row

          console.log("cellProps", cellProps)

          return <>{values?.id}</>;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return <Actions {...cellProps} onEdit={addModal} onDelete={deleteById} />;
        },
      },
      {
        Header: "Domain Name",
        accessor: "domain_id",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <DomainName {...cellProps} />;
        },
      },
      {
        Header: "slug",
        accessor: "slug",
        disableFilters: true,
        Cell: cellProps => {
          return <Slug {...cellProps} />;
        },
      },
      {
        Header: "Time",
        // accessor: "slug",
        disableFilters: true,
        Cell: cellProps => {
          return <Time {...cellProps} />;
        },
      },
      {
        Header: "Options",
        // accessor: "allow_desktop",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Options {...cellProps} />;
        },
      },
      {
        Header: "Dnc Number",
        accessor: "dnc_number_pool_script",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DncNumber {...cellProps} />;
        },
      },
      {
        Header: "Param List",
        accessor: "param_list",
        disableFilters: true,
        Cell: cellProps => {
          return <ParamList {...cellProps} />;
        },
      },
      {
        Header: "DS Platform",
        accessor: "ds_platform",
        disableFilters: true,
        Cell: cellProps => {
          return <DsPlatform {...cellProps} />;
        },
      },
      // {
      //   Header: "Publisher",
      //   accessor: "publisher_id",
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <PublisherId {...cellProps} />;
      //   },
      // },
      {
        Header: "Publisher Name",
        accessor: "publisher_id",
        disableFilters: true,
        Cell: cellProps => {
          return <PublisherName {...cellProps} />;
        },
      },
      {
        Header: "Number",
        accessor: "number_pool_script_url",
        disableFilters: true,
        Cell: cellProps => {
          return <Number {...cellProps} />;
        },
      },
      {
        Header: "vertical",
        accessor: "vertical",
        disableFilters: true,
        Cell: cellProps => {
          return <Vertical {...cellProps} />;
        },
      },
    ],
    []
  );

  // const sampleData = [
  //   {
  //     id: 1, Domain: 'Value 1A', count: 3,
  //     expandedContent: [
  //       { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F', col6: 'Value 1F', col7: 'Value 1F', col8: 'Value 1F', col9: 'Value 1F' },
  //       { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F', col6: 'Value 1F', col7: 'Value 1F', col8: 'Value 1F', col9: 'Value 1F' },
  //       { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F', col6: 'Value 1F', col7: 'Value 1F', col8: 'Value 1F', col9: 'Value 1F' }]
  //   },
  //   {
  //     id: 2, Domain: 'Value 2A', count: 3,
  //     expandedContent: [
  //       { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F', col6: 'Value 1F', col7: 'Value 1F', col8: 'Value 1F', col9: 'Value 1F' },
  //       { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F', col6: 'Value 1F', col7: 'Value 1F', col8: 'Value 1F', col9: 'Value 1F' },
  //       { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F', col6: 'Value 1F', col7: 'Value 1F', col8: 'Value 1F', col9: 'Value 1F' }]
  //   },
  //   { id: 3, Domain: 'Value 3A' },
  //   { id: 4, Domain: 'Value 3A' },
  //   { id: 5, Domain: 'Value 3A' },
  //   { id: 6, Domain: 'Value 3A' },
  //   { id: 7, Domain: 'Value 3A' },
  //   { id: 8, Domain: 'Value 3A' },
  //   { id: 9, Domain: 'Value 3A' },
  //   { id: 10, Domain: 'Value 3A' },
  //   { id: 11, Domain: 'Value 3A' },
  //   { id: 12, Domain: 'Value 3A' },
  //   { id: 13, Domain: 'Value 3A' },
  //   { id: 14, Domain: 'Value 3A' },
  //   { id: 15, Domain: 'Value 3A' },
  //   // ... puedes agregar más filas según sea necesario
  // ];

  const sampleData = [
    {
      "id": 1, "Domain": "seniorsavingsalerts.com",
      "expandedContent": [{ "allow_desktop": 0, "allow_mobile": 1, "allow_params": 1, "available": 0, "created_at": "2023-08-29T20:23:53.000Z", "d_id": 14 }]
    },
    {
      id: 2, Domain: 'Value 2A', count: 3,
      expandedContent: [
        { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F', col6: 'Value 1F', col7: 'Value 1F', col8: 'Value 1F', col9: 'Value 1F' },
        { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F', col6: 'Value 1F', col7: 'Value 1F', col8: 'Value 1F', col9: 'Value 1F' },
        { col1: 'Value 1C', col2: 'Value 1D', col3: 'Value 1E', col4: 'Value 1F', col5: 'Value 1F', col6: 'Value 1F', col7: 'Value 1F', col8: 'Value 1F', col9: 'Value 1F' }]
    },
    // { id: 3, Domain: 'Value 3A' },
    // { id: 4, Domain: 'Value 3A' },
    // { id: 5, Domain: 'Value 3A' },
    // { id: 6, Domain: 'Value 3A' },
    // { id: 7, Domain: 'Value 3A' },
    // { id: 8, Domain: 'Value 3A' },
    // { id: 9, Domain: 'Value 3A' },
    // { id: 10, Domain: 'Value 3A' },
    // { id: 11, Domain: 'Value 3A' },
    // { id: 12, Domain: 'Value 3A' },
    // { id: 13, Domain: 'Value 3A' },
    // { id: 14, Domain: 'Value 3A' },
    // { id: 15, Domain: 'Value 3A' },
    // ... puedes agregar más filas según sea necesario
  ];


  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '100vh',
          // border: '1px solid black',
          // overflow: 'hidden'
        }}
      >
        <Container fluid style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '80vh',
          // border: '1px solid black'
        }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Landers config")}
            breadcrumbItem={props.t("Process")}
          />
          <Row style={{ margin: 10 }}>
            <Col sm="12">
              <Card>
                <CardBody>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="my-2 h2" style={{ flex: 4 }} >Landers List</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>

                      <CSVExportButton data={landerList} filename={"Landers List"} publisherList={publisherList} />

                      <Button style={{ madtinRigth: "20px", backgroundColor: "#d74247", borderColor: "#d74247" }}
                        onClick={() => { addModal() }}
                      >
                        <i className='bx bxs-plus-circle' ></i>{"Add New Lander"}
                      </Button>
                    </div>
                  </div>
                  <Row className="mb-3">
                    <Col sm="4">
                      <div>
                        <label
                          htmlFor="example-search-input"
                          className="col-md-2 col-form-label"
                        >
                          Search
                        </label>
                        <div className="col-md-10">
                          <input
                            className="form-control"
                            type="search"
                            value={search}  // Usamos el valor del estado para controlar el input
                            onChange={handleSearchChange}  // Llamamos a la función para manejar el cambio
                          />
                        </div>
                      </div>
                    </Col>
                    <Col sm="4"> {/* Agrega una columna adicional vacía con sm="4" para crear un espacio */}
                      {/* Este espacio de 4 columnas actúa como un espacio entre las dos columnas */}
                    </Col>
                    <Col className="text-right"> {/* Utiliza la clase text-right para alinear a la derecha */}
                      <div>
                      </div>
                    </Col>
                  </Row>
                  {/* <TableContainer
                    columns={columns}
                    data={landerList}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={6}
                  /> */}

                  <DataTable data={newLanderList} />

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <EditModal
        toggle={toggle}
        isOpen={modal}
        lander={lander}
        publisherList={publisherList}
        isEdit={isEdit}
        onSubmit={async (value) => {
          if (isEdit) {
            await onUpdate(value)
            setLander(null)
          } else {
            await onCreate(value)
          }
          setModal(false)
        }}
      />

    </React.Fragment>
  );
};

LanderConfig.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {

  const buyerLoading = Buyer?.loading

  const dispLoading = Disposition?.loading

  // console.log("lineChart", Chart?.lineChart)

  return {
    buyers: Buyer?.buyers,
    fileList: Disposition?.fileList,
    lineChart: Chart?.lineChart,
    dispLoading: dispLoading
  };
}

const mapDispatchToProps = (dispatch) => ({
  onGetBuyers: () => dispatch(getBuyers()),
  onCheckinLogin: () => dispatch(checkinLogin()),
  onGetFileHistory: () => dispatch(getFileHistory()),
  onSetProcess: (body) => dispatch(setProcess(body)),
  onGetLineChart: () => dispatch(getLineChart())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(LanderConfig)));

