import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import TableContainer from "../../components/Common/TableContainer";
import ContainerWithScroll from "../../components/Common/scrollContent/ContainerWithScroll";

import {
  Actions,
  DomainName,
  DncNumber,
  DsPlatform,
  PublisherName,
  Number,
  Slug,
  Vertical,
  ParamList,
  Options,
  Time
} from "./LatestTranactionCol";

// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { config } from "../../config";

import moment from "moment";

//i18n
import { withTranslation } from "react-i18next";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";

import Spinner from '../../components/Common/spinner/Spin';
// import { latestTransaction } from "../../common/data/dashboard";
import { useFormik } from "formik";
import * as Yup from "yup";

import EditModal from './EditModal';

import styled from '@emotion/styled'

import {
  Form,
  FormFeedback,
  Label,
} from "reactstrap";

import RTBTable from './RTBTable'


//===================================

import { getFileHistoryScroll, getListData, getOneDataDate } from "../../store/disposition/actions";

import Select from 'react-select'

import Table from "../../components/Common/Tabla/BostrapTable";

import LineChart from "../../components/Common/Charts/LineChart";

import ScreenA from "./ScreenA"

import { css } from '@emotion/react'
import Logger from "../../helpers/Logger/Logger";

//===================================

const SCard = styled(Card)`
  ${({ selected }) =>
    selected ?
      css`
        color: #10c772;
        outline: none; /* Para quitar el contorno predeterminado del enfoque 
        border: 2px solid green;*/
        box-shadow: 0 0 5px 2px rgba(0, 255, 0, 0.5);
      `:
      `box-shadow: 0 0 5px 2px rgba(128, 128, 128, 0.2);`
  }
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
  
  /* New animation for click */
  transition: transform 0.2s;
  &.click-animation {
    transform: scale(0.95);
  }
`;

const CardHeader = ({ title, onDelete, isMaster, status }) => {

  return (<div className="mx-3 mt-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
    <p className="card-title">{title ? title : "None"}</p>
    {isMaster && <button
      onClick={(e) => {
        e.stopPropagation();
        onDelete()
        // onDelete(key);
      }}
      style={{
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
        color: 'red',
      }}
    >

      <i className="fa fa-times" aria-hidden="true"></i>
    </button>
    }
  </div>
  )
}

export const RTBFileScreen = (props) => {

  const { buyers, fileList, lineChart, dispLoading, data } = props

  const { setDateRange, dateRange, chart, dateFilter, onGetLogsFileList, logFiles, publisherDataList,
    onGetRtbFileList, isMaster, onGetFileHistoryScroll, onGetOneDataDate, onGetLogsPublisher,
    loading_srcoll, onGetListData, loading_list_data } = props

  //----------------
  const [selectedFile, setSelectedFile] = useState({ id: 0, ...data[0] } || [])
  const [selectedId, setSelectedId] = useState(0)
  const [selected_Id, setSelected_Id] = useState(data[0]?._id)
  const [dataLength, setDataLength] = useState(data[0]?.dataLength)

  const [dataList, setDataList] = useState([])
  const [chartData, setChartData] = useState([])
  const [selectedInfo, setSelectedInfo] = useState(null)

  const [currentPage, setCurrentPage] = useState(null)
  const [totalPages, setTotalPages] = useState(null)

  const [outOfRangeCount, setOutOfRangeCount] = useState(0)

  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState(0);

  const [tableIndex, setTableIndex] = useState(1);
  //-------------------


  const { BACKEND_URL, RTB_BACKEND_URL } = config

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [lander, setLander] = useState();

  const [landerList, setLanderList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [domainList, setDomainList] = useState([]);

  const [filter, setFilter] = useState(null);


  const scrollViewRef = useRef(null);


  useEffect(() => {
    // onCheckinLogin()
    onGetRtbFileList()
  }, []);

  const toggle = () => setModal(!modal);

  const getData = async () => {
    // let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
    let url = `${BACKEND_URL}/lander/get-list`
    let { data } = await axios.get(url)

    if (data?.success) {
      const { landerList } = data
      console.log("landerList", landerList)

      setLanderList(landerList)
    }
  }

  const getDomainList = async () => {
    let url = `${BACKEND_URL}/domain/get-selections`
    let { data } = await axios.get(url)

    if (data?.success) {
      const { domainSlections } = data
      setDomainList(domainSlections)
    }

  }

  const onCreate = async (value) => {
    let url = `${BACKEND_URL}/lander/create`
    let { data } = await axios.post(url, value)

    if (data?.success) {
      await getData()
    }
    setLander(null)
    // setDomainList
  }
  const onUpdate = async (values) => {
    const { id } = values
    let url = `${BACKEND_URL}/lander/update/${id}`
    let { data } = await axios.put(url, values)

    if (data?.success) {
      await getData()
    }
    setLander(null)
  }

  const deleteById = async (id) => {
    let url = `${BACKEND_URL}/lander/delete/${id}`
    let { data } = await axios.delete(url)

    if (data?.success) {
      await getData()
    }

  }

  const getPublisherList = async () => {
    let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
    let url = `https://api.ringba.com/v2/RA27f335147c2d487fba01c2732feaecb0/Publishers`
    let { data } = await axios.get(url, {
      headers: {
        'Authorization': `Token ${token}`
      }
    })

    if (data) {
      const { publishers } = data
      setPublisherList(publishers)
    }

  }

  const addModal = async (data) => {
    if (data) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
    setLander(data)
    setModal(true)
  }

  const deleteModal = async (data) => {
    await deleteById(data?.id)
    setModal(false)
  }

  useEffect(() => {
    getData()
    getPublisherList()
    getDomainList()
  }, [])

  useEffect(() => {
    console.log("REQUEST_LIST_DATA:: dataxx", data?.length)
    console.log("REQUEST_LIST_DATA:: dataxx", data)
    console.log("REQUEST_LIST_DATA:: data[0]", { id: 0, ...data[0] })
    setSelectedFile({ id: 0, ...data[0] })
  }, [data])

  useEffect(() => {
    console.log("REQUEST_LIST_DATA:: selectedFile", selectedFile)
  }, [selectedFile])

  useEffect(() => {
    if (selectedFile) {
      const id = selectedFile?._id;

      if (id) {
        const queryFilter = filter ? `&publisher=${filter}` : ""
        const query = `?id=${id}${queryFilter}`;

        console.log("onGetRtbLogFileList::--")
        console.log("onGetRtbLogFileList::query", query)
        if (!filter) {
          onGetLogsPublisher(query)
        }
        onGetLogsFileList(query);
      }
    }

  }, [selectedFile, filter])

  useEffect(() => {
    console.log("onGetRtbLogFileList::--")
    console.log("onGetRtbLogFileList:: logFiles", logFiles)

  }, [logFiles])

  // const fetchData = useCallback(async () => {
  //   // if (isLoading) return;
  //   if (loading_srcoll) return;

  //   // alert("Listo")

  //   setIsLoading(true);

  //   // onGetFileHistory(`?offset=${index}0&limit=2`)
  //   console.log("onGetFileHistoryScroll000...")
  //   Logger?.logger("onGetFileHistoryScroll000...")

  //   onGetFileHistoryScroll(`?offset=${index * 5}&limit=5`)
  //   console.log("onGetFileHistoryScroll...")
  //   Logger?.logger("onGetFileHistoryScroll000...")

  //   setIndex((prevIndex) => prevIndex + 1);

  //   setIsLoading(false);

  //   console.log("Completo...")
  //   Logger?.logger("Completo...")


  // }, [index, isLoading, loading_srcoll]);

  // useEffect(() => {
  //   const scrollElement = scrollViewRef.current;

  //   const handleScroll = () => {
  //     const { scrollTop, clientHeight, scrollHeight } = scrollElement;

  //     if (scrollTop + clientHeight >= scrollHeight - 20) {
  //       fetchData();
  //     }
  //   };

  //   scrollElement.addEventListener("scroll", handleScroll);
  //   return () => {
  //     scrollElement.removeEventListener("scroll", handleScroll);
  //   };
  // }, [fetchData]);

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '100vh',
          // border: '1px solid black',
          // overflow: 'hidden'
        }}
      >
        <Container fluid style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '80vh',
          // border: '1px solid black'
        }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Landers config")}
            breadcrumbItem={props.t("Landers config")}
          />
          <Row style={{
            height: "80vh",
            //  borderWidth: 1, borderColor: 'black', borderStyle: 'solid',
          }}>
            <Col sm="3" style={{
              height: "105%",
              // borderWidth: "1px", flex: 1 
            }}>
              <ContainerWithScroll ref={scrollViewRef}>
                <Row>
                  <Col>
                    {
                      data && data?.map((item, key) => {

                        const { name, userName, updatedAt, state_status } = item

                        let selected = false;

                        if (key == selectedFile?.id) // Aqui
                          selected = true

                        return <SCard key={key} selected={selected} onClick={() => {
                          setSelectedFile({ id: key, ...item })
                        }} style={{ cursor: 'pointer' }}>
                          <CardHeader title={name} onDelete={() => { onDelete(item?._id) }}
                            isMaster={true}
                          // isMaster={isMaster} 
                          />
                          <CardBody className="pt-1">
                            <p className="card-title-desc mb-2">
                              {moment(updatedAt).format('DD/MM/YYYY hh:mm')}{" "}
                              {(state_status == "Completed") ? "[Completed]" : "[Pending]"}
                            </p>
                            <Row className="icon-demo-content" id="newIcons"></Row>
                          </CardBody>
                        </SCard>
                      })
                    }

                    {loading_srcoll ? <Row>
                      <Col className="text-center">
                        <div className="snippet" data-title="dot-elastic">
                          <div className="stage">
                            <div className="dot-elastic"></div>
                          </div>
                        </div>
                      </Col>
                    </Row> : null}

                  </Col>
                </Row>
              </ContainerWithScroll>
            </Col>
            <Col sm="8">
              <RTBTable logFiles={logFiles} selectedFile={selectedFile} publisherDataList={publisherDataList}
                onFilter={(data) => {
                  console.log("value::", data)
                  setFilter(data?.value)
                }}

                onExport={() => {
                  const id = selectedFile?._id;
                  if (id) {
                    let url = `${RTB_BACKEND_URL}/download-csv`

                    const queryFilter = filter ? `&publisher=${filter}` : ""
                    const query = `?id=${id}${queryFilter}`;
                    const externalURL = `${url}${query}`;
                    window.open(externalURL, '_blank');
                  }
  
                }}

              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

RTBFileScreen.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};
