import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import TableContainer from "../../components/Common/TableContainer";

import {
  Actions,
  DomainName,
  DncNumber,
  DsPlatform,
  DownloadURL,
  PublisherName,
  Number,
  Slug,
  Vertical,
  ParamList,
  Options,
  Time
} from "./LatestTranactionCol";

// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { config } from "../../config";

//i18n
import { withTranslation } from "react-i18next";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";

import Spinner from '../../components/Common/spinner/Spin';
// import { latestTransaction } from "../../common/data/dashboard";
import { useFormik } from "formik";
import * as Yup from "yup";

import EditModal from './EditModal';

import {
  Form,
  FormFeedback,
  Label,
} from "reactstrap";

const PolicyFile = (props) => {

  const { buyers, fileList, lineChart, dispLoading } = props

  const {BACKEND_URL} = config

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [policyFile, setPolicyFile] = useState();

  const [policyList, setPolicyList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);

  const toggle = () => setModal(!modal);

  const getData = async () => {
    // let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
    let url = `${BACKEND_URL}/policyfile/get_list`
    let { data } = await axios.get(url)
    
    if (data?.success) {
      const { policyFileUser } = data
      console.log("data::", data)
      console.log("policyList", policyFileUser)

      setPolicyList(policyFileUser)
    }

    // setPolicyFileList
  }

  const onCreate = async (value) => {
    let url = `${BACKEND_URL}/policyfile/build_token`
    let { data } = await axios.post(url, value)

    if (data?.success) {
      await getData()
    }
    setPolicyFile(null)
  }
  const onUpdate = async (values) => {
    const { id } = values
    let url = `${BACKEND_URL}/policyfile/edit_token/${id}`
    let { data } = await axios.put(url, values)

    if (data?.success) {
      await getData()
    }
    setPolicyFile(null)
  }

  const deleteById = async (id) => {
    let url = `${BACKEND_URL}/lander/delete/${id}`
    let { data } = await axios.delete(url)

    if (data?.success) {
      await getData()
    }

  }

  const getPublisherList = async () => {
    let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
    let url = `https://api.ringba.com/v2/RA27f335147c2d487fba01c2732feaecb0/Publishers`
    let { data } = await axios.get(url, {
      headers: {
        'Authorization': `Token ${token}`
      }
    })

    if (data) {
      const { publishers } = data
      setPublisherList(publishers)
    }

  }

  const addModal = async (data) => {
    if (data) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
    setPolicyFile(data)
    setModal(true)
  }

  const deleteModal = async (data) => {
    await deleteById(data?.id)
    setModal(false)
  }

  useEffect(() => {
    getData()
    getPublisherList()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return <Actions {...cellProps} onEdit={addModal} onDelete={deleteById} />;
        },
      },
      {
        Header: "Full Name",
        accessor: "fullName",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <DsPlatform {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <DsPlatform {...cellProps} />
        },
      },
      {
        Header: "publisher ID",
        accessor: "publisherID",
        disableFilters: true,
        Cell: cellProps => {
          return <DsPlatform {...cellProps} />
        },
      },
      {
        Header: "Token",
        accessor: "token",
        disableFilters: true,
        Cell: cellProps => {
          return <DsPlatform {...cellProps} />;
        },
      },
      {
        Header: "Download URL",
        accessor: "download_url",
        disableFilters: true,
        Cell: cellProps => {
          return <DownloadURL {...cellProps} />;
        },
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '100vh',
          // border: '1px solid black',
          // overflow: 'hidden'
        }}
      >
        <Container fluid style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '80vh',
          // border: '1px solid black'
        }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Policy Files")}
            breadcrumbItem={props.t("Policy Files")}
          />
          <Row style={{ margin: 10 }}>
            <Col sm="12">
              <Card>
                <CardBody>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="my-2 h2">Policy Files List</div>
                    <Button style={{ madtinRigth: "20px", backgroundColor: "#d74247", borderColor: "#d74247" }}
                      onClick={() => { addModal() }}
                    >
                      <i className='bx bxs-plus-circle' ></i>{"Add New Lander"}
                    </Button>
                  </div>
                  <TableContainer
                    columns={columns}
                    data={policyList}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={6}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <EditModal
        toggle={toggle}
        isOpen={modal}
        policyFile={policyFile}
        publisherList={publisherList}
        isEdit={isEdit}
        onSubmit={async (value) => {
          if (isEdit) {
            await onUpdate(value)
          } else {
            await onCreate(value)
          }
          setPolicyFile(null)
          setModal(false)
        }}
      />

    </React.Fragment>
  );
};

PolicyFile.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {

  const buyerLoading = Buyer?.loading

  const dispLoading = Disposition?.loading

  // console.log("lineChart", Chart?.lineChart)

  return {
    buyers: Buyer?.buyers,
    fileList: Disposition?.fileList,
    lineChart: Chart?.lineChart,
    dispLoading: dispLoading
  };
}

const mapDispatchToProps = (dispatch) => ({
  onGetBuyers: () => dispatch(getBuyers()),
  onCheckinLogin: () => dispatch(checkinLogin()),
  onGetFileHistory: () => dispatch(getFileHistory()),
  onSetProcess: (body) => dispatch(setProcess(body)),
  onGetLineChart: () => dispatch(getLineChart())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(PolicyFile)));

