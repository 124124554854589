import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";

//i18n
import { withTranslation } from "react-i18next";

import Spinner from '../../components/Common/spinner/Spin';
// import { latestTransaction } from "../../common/data/dashboard";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

const Checkbox = (props) => {

  const { label, className, id, name, onChange, onBlur, defaultChecked, checked } = props

  return (
    <>
      <Input
        className={className}
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onClick={() => { onChange({ name: name, value: !checked }) }}
        onBlur={onBlur}
      />
      <label className="form-check-label" htmlFor="allowMobilCheckbox">
        {label}
      </label>
    </>
  );
};

Checkbox.propTypes = {
  t: PropTypes.any,
  label: PropTypes.any,
  className: PropTypes.any,
  id: PropTypes.any,
  name: PropTypes.any,
  defaultChecked: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

export default withTranslation()(Checkbox);

