import React, { useEffect, useState }  from 'react';
import styles from './card.module.css'; // Ruta relativa al archivo CSS
import { ReactComponent as IconName } from './xls-svgrepo-com.svg';

const FCard = ({ title, description, imageUrl, clear }) => {

  const [fileName, setFileName] = useState(null);


  useEffect(()=>{
    const shortenedFileName = title.substring(0, 30);

    // Obtén la extensión del archivo
    const fileExtension = title.split('.').pop();

    // Crea el nombre de archivo final con los primeros 30 caracteres y la extensión
    const displayFileName = shortenedFileName + "..." + fileExtension;

    setFileName(displayFileName)
  }, [title])

  return (
    <div className="card"
      style={{
        // width: "60%",
        marginTop: "10px",
        margin: "auto",
        border: "1px solid #ccc",
        borderRadius: "4px",
        paddingRight: "0",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
      }}
    >
      <div style={{ display: 'flex' }}>
        {
          // imageUrl && <div style={{ flex: 0.8, height: "100px", borderRight: "1px solid #ccc", margin: "auto 0" }}>
          //   <img className={styles['card-image']} src={imageUrl} alt={title} />
          // </div>
        }

        <IconName style={{ margin: "10px auto", height: "80px", width: "80px", marginLeft: "10px" }} />

        <div style={{ flex: 3, margin: "auto 0" }}>
          {fileName && <p style={{
            fontSize: "20px",
            margin: "10px 20px",
            letterSpacing: "2px",
            lineHeight: "26px",
            fontWeight: 400,
          }}>{fileName}</p>}
          {description && <p className={styles['card-description']}>{description}</p>}
        </div>
        {clear && <div style={{
          width: "60px", backgroundColor: '#FF6961', cursor: "pointer", margin: "0 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
          onClick={clear}
        >
          <i className="dripicons-trash" style={{
            fontSize: "20px",
            letterSpacing: "2px",
            lineHeight: "26px",
            color: "white",
            fontWeight: 400,
          }} />
        </div>}
      </div>

    </div>
  );
}

FCard.propTypes = {

}

export default FCard
