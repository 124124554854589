/* EVENTS */
export const EVENT_GET_RTB_FILE_LIST = "EVENT_GET_RTB_FILE_LIST";
export const REQUEST_RTB_FILE_LIST = "REQUEST_RTB_FILE_LIST";
export const REQUEST_RTB_FILE_LIST_FAIL = "REQUEST_RTB_FILE_LIST_FAIL";
export const REQUEST_RTB_FILE_LIST_SUCCESS = "REQUEST_RTB_FILE_LIST_SUCCESS";


export const EVENT_GET_RTB_LOGS_FILE_LIST = "EVENT_GET_RTB_LOGS_FILE_LIST";
export const REQUEST_RTB_LOGS_FILE_LIST = "REQUEST_RTB_LOGS_FILE_LIST";
export const REQUEST_RTB_LOGS_FILE_LIST_FAIL = "REQUEST_RTB_LOGS_FILE_LIST_FAIL";
export const REQUEST_RTB_LOGS_FILE_LIST_SUCCESS = "REQUEST_RTB_LOGS_FILE_LIST_SUCCESS";


export const EVENT_GET_RTB_LOGS_PUBLISHER = "EVENT_GET_RTB_LOGS_PUBLISHER";
export const REQUEST_RTB_LOGS_PUBLISHER = "REQUEST_RTB_LOGS_PUBLISHER";
export const REQUEST_RTB_LOGS_PUBLISHER_FAIL = "REQUEST_RTB_LOGS_PUBLISHER_FAIL";
export const REQUEST_RTB_LOGS_PUBLISHER_SUCCESS = "REQUEST_RTB_LOGS_PUBLISHER_SUCCESS";

