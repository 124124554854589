import { takeEvery, put, call } from "redux-saga/effects"

import {
  EVENT_GET_RTB_FILE_LIST,
  EVENT_GET_RTB_LOGS_FILE_LIST,
  EVENT_GET_RTB_LOGS_PUBLISHER,
} from "./actionTypes";

import {
  requestFileList,
  requestFileListFail,
  requestFileListSuccess,

  requestLogsFileList,
  requestLogsFileListFail,
  requestLogsFileListSuccess,

  requestLogsPublisher,
  requestLogsPublisherFail,
  requestLogsPublisherSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getRtbFileListApi,
  getRtbLogsFileListApi,
  getRtbLogsPublisherApi
} from "../../helpers/fakebackend_helper"

function* onGetRtbFileList({ payload: body }) {
  try {
    yield put(requestFileList())
    const query = body ? body : ""

    console.log("onGetFileList::", query)

    const response = yield call(getRtbFileListApi, query)
    yield put(requestFileListSuccess(response))
  } catch (error) {
    yield put(requestFileListFail(error))
  }
}

function* onGetRtbLogFileList({ payload: body }) {
  try {
    yield put(requestLogsFileList())
    const query = body ? body : ""

    console.log("onGetRtbLogFileList::", query)

    const response = yield call(getRtbLogsFileListApi, query)
    yield put(requestLogsFileListSuccess(response))
  } catch (error) {
    yield put(requestLogsFileListFail(error))
  }
}

function* onGetRtbLogLogsPublisher({ payload: body }) {
  try {
    yield put(requestLogsPublisher())
    const query = body ? body : ""

    console.log("onGetLogsPublisher::", query)

    const response = yield call(getRtbLogsPublisherApi, query)
    yield put(requestLogsPublisherSuccess(response))
  } catch (error) {
    yield put(requestLogsPublisherFail(error))
  }
}

function* rtbFileSaga() {
  yield takeEvery(EVENT_GET_RTB_FILE_LIST, onGetRtbFileList);
  yield takeEvery(EVENT_GET_RTB_LOGS_FILE_LIST, onGetRtbLogFileList);
  yield takeEvery(EVENT_GET_RTB_LOGS_PUBLISHER, onGetRtbLogLogsPublisher);

  
}

export default rtbFileSaga
