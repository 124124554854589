export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"

export const SOCIAL_LOGIN = "SOCIAL_LOGIN"




export const EVENT_LOGIN_USER = "EVENT_LOGIN_USER"
export const REQUEST_LOGIN_USER = "REQUEST_LOGIN_USER"
export const REQUEST_LOGIN_SUCCESS = "REQUEST_LOGIN_SUCCESS"
export const REQUEST_LOGIN_FAIL = "REQUEST_LOGIN_FAIL"

export const EVENT_LOGOUT_USER = "EVENT_LOGOUT_USER"
export const REQUEST_LOGOUT_SUCCESS = "REQUEST_LOGOUT_SUCCESS"
export const REQUEST_LOGOUT_FAIL = "REQUEST_LOGOUT_FAIL"

//Checkin Login
export const CHECKIN_LOGIN = "CHECKIN_LOGIN"
export const CHECKIN_LOGIN_SUCCESS = "CHECKIN_LOGIN_SUCCESS"
export const CHECKIN_LOGIN_FAIL = "CHECKIN_LOGIN_FAIL"
