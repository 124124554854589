import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import TableContainer from "../../components/Common/TableContainer";

import {
  Actions,
  DomainName,
  DncNumber,
  DsPlatform,
  PublisherName,
  Number,
  Slug,
  Vertical,
  ParamList,
  Options,
  Time
} from "./LatestTranactionCol";

// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { config } from "../../config";

//i18n
import { withTranslation } from "react-i18next";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";

import Spinner from '../../components/Common/spinner/Spin';
// import { latestTransaction } from "../../common/data/dashboard";
import { useFormik } from "formik";
import * as Yup from "yup";

import EditModal from './EditModal';


import {
  Form,
  FormFeedback,
  Label,
} from "reactstrap";

import DropDown from '../../components/Common/DropDown/DropDown';


const LanderConfig = (props) => {

  const { buyers, fileList, lineChart, dispLoading } = props

  const { BACKEND_URL } = config

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [lander, setLander] = useState();

  const [landerList, setLanderList] = useState([]);
  const [publisherList, setPublisherList] = useState([]);
  const [domainList, setDomainList] = useState([]);

  const toggle = () => setModal(!modal);

  const getData = async () => {
    // let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
    let url = `${BACKEND_URL}/lander/get-list`
    let { data } = await axios.get(url)

    if (data?.success) {
      const { landerList } = data
      console.log("landerList", landerList)

      setLanderList(landerList)
    }

    // setLanderList
  }

  const getDomainList = async () => {
    let url = `${BACKEND_URL}/domain/get-selections`
    let { data } = await axios.get(url)

    if (data?.success) {
      const { domainSlections } = data

      setDomainList(domainSlections)
    }

  }

  const onCreate = async (value) => {
    let url = `${BACKEND_URL}/lander/create`
    let { data } = await axios.post(url, value)

    if (data?.success) {
      await getData()
    }
    setLander(null)
    // setDomainList
  }
  const onUpdate = async (values) => {
    const { id } = values
    let url = `${BACKEND_URL}/lander/update/${id}`
    let { data } = await axios.put(url, values)

    if (data?.success) {
      await getData()
    }
    setLander(null)
  }

  const deleteById = async (id) => {
    let url = `${BACKEND_URL}/lander/delete/${id}`
    let { data } = await axios.delete(url)

    if (data?.success) {
      await getData()
    }

  }

  const getPublisherList = async () => {
    let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
    let url = `https://api.ringba.com/v2/RA27f335147c2d487fba01c2732feaecb0/Publishers`
    let { data } = await axios.get(url, {
      headers: {
        'Authorization': `Token ${token}`
      }
    })

    if (data) {
      const { publishers } = data
      setPublisherList(publishers)
    }

  }

  const addModal = async (data) => {
    if (data) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
    setLander(data)
    setModal(true)
  }

  const deleteModal = async (data) => {
    await deleteById(data?.id)
    setModal(false)
  }

  useEffect(() => {
    getData()
    getPublisherList()
    getDomainList()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "Audit",
        accessor: "audit",
        disableFilters: true,
        Cell: cellProps => {
          return "Audit";
        },
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '100vh',
          // border: '1px solid black',
          // overflow: 'hidden'
        }}
      >
        <Container fluid style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '80vh',
          // border: '1px solid black'
        }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Audit")}
            breadcrumbItem={props.t("Audit")}
          />
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="my-2 h2">Audit</div>
                  </div>
                  <Row>
                    <Col sm="3">
                      <DropDown label={"Distpositions"} />
                    </Col>
                    <Col sm="3">
                      <DropDown label={"Actios"} data={[{ id: "test", name: "test" }]} />
                    </Col>
                    <Col sm="3">
                      <Button style={{ marginRigth: "20px", marginTop: "25px", backgroundColor: "#d74247", borderColor: "#d74247" }}
                        onClick={() => { }}
                      >
                        <i className='bx bx-cog'></i>{" config"}
                      </Button>
                      <Button style={{ marginLeft: "20px", marginTop: "25px" }}
                        onClick={() => { }}
                      >
                        {"Send"}
                      </Button>
                    </Col>

                  </Row>
                  <Row>
                    <Col sm="6">
                      <TableContainer
                        columns={columns}
                        data={[]}
                        isGlobalFilter={false}
                        isAddOptions={false}
                        customPageSize={6}
                      />
                    </Col>
                    <Col sm="6">
                      <TableContainer
                        columns={columns}
                        data={[]}
                        isGlobalFilter={false}
                        isAddOptions={false}
                        customPageSize={6}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <EditModal
        toggle={toggle}
        isOpen={modal}
        lander={lander}
        publisherList={publisherList}
        isEdit={isEdit}
        onSubmit={async (value) => {
          if (isEdit) {
            await onUpdate(value)
          } else {
            await onCreate(value)
          }
          setLander(null)
          setModal(false)
        }}
      />

    </React.Fragment>
  );
};

LanderConfig.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ Buyer, Disposition, Chart }) => {

  const buyerLoading = Buyer?.loading

  const dispLoading = Disposition?.loading

  // console.log("lineChart", Chart?.lineChart)

  return {
    buyers: Buyer?.buyers,
    fileList: Disposition?.fileList,
    lineChart: Chart?.lineChart,
    dispLoading: dispLoading
  };
}

const mapDispatchToProps = (dispatch) => ({
  onGetBuyers: () => dispatch(getBuyers()),
  onCheckinLogin: () => dispatch(checkinLogin()),
  onGetFileHistory: (body) => dispatch(getFileHistory(body)),
  onSetProcess: (body) => dispatch(setProcess(body)),
  onGetLineChart: () => dispatch(getLineChart())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(LanderConfig)));

