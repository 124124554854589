import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import { EVENT_LOGIN_USER, EVENT_LOGOUT_USER, CHECKIN_LOGIN } from "./actionTypes"
import {
  RequestLoginUser, RequestLoginUserSuccess, RequestLoginUserFail,
  RequestLogoutUserSuccess, RequestLogoutUserFail,
  RequestCheckinLoginSuccess, RequestCheckinLoginFail
} from "./actions"


//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
  dispositionLogin,
  checkinLoginApi
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) { // Nuevo
  try {
    console.log("user", user)
    yield put(RequestLoginUser())

    console.log("RequestLoginUser")
    const response = yield call(dispositionLogin, { ...user });
    console.log("response::", response?.data)
    localStorage.setItem("authUser", JSON.stringify(response?.data?.user));
    localStorage.setItem("token", response?.data?.token);
    console.log("token::", response)
    yield put(RequestLoginUserSuccess(response));

    // history.push("/home");
    window.location.href = "/home"
    
  } catch (error) {
    console.log("error", error)
    const { response } = error

    console.log("response", response)
    if(response){
      const { data } = response
      console.log("Error: data", data)
      yield put(RequestLoginUserFail(data?.message))
    } else {
      yield put(RequestLoginUserFail(error?.message));
    }

  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      );
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* onCheckinLogin() {
  try {
    console.log("Entroxxxx")
    const response = yield call(checkinLoginApi)

    console.log("responsezzz", response)

    // console.log("onCheckinLogin::")

    // console.log("response::", response)

    // localStorage.setItem("authUser", JSON.stringify(response.data.user));
    // yield put(RequestCheckinLoginSuccess(response.data, response.message))
    
  } catch (error) {
    console.log("error", error)
    yield put(RequestCheckinLoginFail(error.message))
    window.location.href = "/login"

  }
}

function* authSaga() {
  yield takeEvery(CHECKIN_LOGIN, onCheckinLogin)

  yield takeEvery(EVENT_LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);

}

export default authSaga;
