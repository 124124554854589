import PropTypes from "prop-types";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { useSelector, useDispatch, connect } from "react-redux";

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import axios from "axios";
import { withRouter } from "react-router-dom"
import csv from 'csvtojson';

import TableContainer from "../../components/Common/TableContainer";

import {
  Actions,
  ProcessName,
  PercentageCompleted,
  CompletedFiles,
  TotalFiles,
  Status,
  CreationDate,
  CompletionDate,
  Error,
  Retriies
} from "./LatestTranactionCol";

// import Card from 'react-bootstrap/Card';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { config } from "../../config";

//i18n
import { withTranslation } from "react-i18next";

import { checkinLogin } from "../../store/auth/login/actions";
import { getBuyers } from "../../store/buyer/actions";
import { getFileHistory, setProcess } from "../../store/disposition/actions";
import { getLineChart } from "../../store/chart/actions";
import { getProcessList, getProcessListSilent } from "../../store/processMonitor/actions";



import Spinner from '../../components/Common/spinner/Spin';
// import { latestTransaction } from "../../common/data/dashboard";
import { useFormik } from "formik";
import * as Yup from "yup";

import EditModal from './EditModal';


import {
  Form,
  FormFeedback,
  Label,
} from "reactstrap";

const LanderConfig = (props) => {

  const { onGetProcessList, _processList, onGetProcessListSilent } = props

  const { BACKEND_URL } = config

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [lander, setLander] = useState();

  const [processList, setProcessList] = useState([]);

  const toggle = () => setModal(!modal);

  // const getPublisherList = async () => {
  //   let token = "09f0c9f0935283360f607645be5cf09d69c6980b3c2e3f86743a2d79fc6e021d54423ff19114aaab42fe0bd55529dd5810439aae0dca4fb7717bff93aead079acac71b0127106846e75833b0f860141ec8fc9b8e92ec57f5cd9745505f7bad0ce61dc6a04d1962c94d4f6486b4ad82aec9d60d12"
  //   let url = `https://api.ringba.com/v2/RA27f335147c2d487fba01c2732feaecb0/Publishers`
  //   let { data } = await axios.get(url, {
  //     headers: {
  //       'Authorization': `Token ${token}`
  //     }
  //   })

  //   if (data) {
  //     const { publishers } = data
  //     setPublisherList(publishers)
  //   }

  // }

  const deleteById = async (id) => {
    let url = `${BACKEND_URL}/lander/delete/${id}`
    let { data } = await axios.delete(url)

    if (data?.success) {
      await getData()
    }

  }


  const clearDB = async () => {
    let url = `${BACKEND_URL}/feed-db/clear`
    let { data } = await axios.get(url)

    if (data?.success) {
      console.log("Clear:", data)
      // await getData()
    }

  }

  const addModal = async (data) => {
    if (data) {
      setIsEdit(true)
    } else {
      setIsEdit(false)
    }
    setLander(data)
    setModal(true)
  }

  const deleteModal = async (data) => {
    await deleteById(data?.id)
    setModal(false)
  }

  const onProcessInit = () => {
    onGetProcessList();

    onGetProcessListSilent()

  }

  // onGetProcessListSilent

  useEffect(() => {
    const onGetProcessListSilentInterval = setInterval(() => {
      onGetProcessListSilent();
    }, 1000);

    // Llamar a la función inicial
    onGetProcessList();

    // Limpiar el intervalo cuando el componente se desmonta
    return () => {
      clearInterval(onGetProcessListSilentInterval);
    };
  }, []);


  useEffect(() => {

    if (!processList?.length) {
      const mapProcessList = _processList?.filter(item => item.processName === 'Feed_DataBase')?.map((item) => {
        const { processName, info, status, done, createdAt, updatedAt, completionDate, _id } = item;
        const { completed, total, type, progressPercentage, progressTotal, progresscount } = info

        return {
          _id,
          processName,
          completed,
          total,
          status,
          done,
          createdAt,
          updatedAt,
          completionDate,
          type,
          progressPercentage,
          progressTotal,
          progresscount,
          info
        }

      })

      // const filteredProcessList = mapProcessList?.

      // console.log("mapProcessList::", mapProcessList)
      // console.log("_processList::", _processList)

      setProcessList(mapProcessList)
    }
    // }, [_processList])
  }, [_processList])

  const columns = useMemo(
    () => [
      // {
      //   Header: "#",
      //   filterable: false,
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     return <input type="checkbox" className="form-check-input" />;
      //   },
      // },
      {
        Header: "Id",
        accessor: "id",
        disableFilters: true,
        hidden: true,
        Cell: cellProps => {
          const { row } = cellProps?.cell
          const { values } = row

          // console.log("cellProps", cellProps)

          return <>{values?.id}</>;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return <Actions {...cellProps} onEdit={addModal} onDelete={deleteById} />;
        },
      },
      {
        Header: "Process Name",
        accessor: "processName",
        disableFilters: true,
        Cell: cellProps => {
          return <ProcessName {...cellProps} />;
        },
      },
      {
        Header: "Status",
        // accessor: "allow_desktop",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Process start time",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <CreationDate {...cellProps} />;
        },
      },
      {
        Header: "Process end time",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <CompletionDate {...cellProps} />;
        },
      },
      {
        Header: "inserted records",
        accessor: "completedFiles",
        disableFilters: true,
        Cell: cellProps => {
          return <CompletedFiles {...cellProps} />;
        },
      },
      {
        Header: "updated records",
        // accessor: "slug",
        disableFilters: true,
        Cell: cellProps => {
          return <TotalFiles {...cellProps} />;
        },
      },
      {
        Header: "errors",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Error {...cellProps} />;
        },
      },
      {
        Header: "retries",
        disableFilters: true,
        filterable: false,
        Cell: cellProps => {
          return <Retriies {...cellProps} />;
        },
      }
    ],
    []
  );

  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '100vh',
          // border: '1px solid black',
          // overflow: 'hidden'
        }}
      >
        <Container fluid style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'stretch',
          minHeight: '80vh',
          // border: '1px solid black'
        }}>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Logs FeedDatabase")}
            breadcrumbItem={props.t("Logs FeedDatabase")}
          />

          {/* <Button onClick={()=>{ 
            clearDB()
          }}>
            Clear
          </Button>  */}
          <Row style={{ margin: 10 }}>
            <Col sm="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={processList}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={6}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <EditModal
        toggle={toggle}
        isOpen={modal}
        lander={lander}
        publisherList={publisherList}
        isEdit={isEdit}
        onSubmit={async (value) => {
          if (isEdit) {
            await onUpdate(value)
            setLander(null)
          } else {
            await onCreate(value)
          }
          setModal(false)
        }}
      /> */}

    </React.Fragment>
  );
};

LanderConfig.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
};

const mapStateToProps = ({ ProcessMonitor }) => {

  const _processList = ProcessMonitor?.processList

  return {
    _processList,
  };
}

const mapDispatchToProps = (dispatch) => ({
  onGetProcessListSilent: () => dispatch(getProcessListSilent()),
  onGetProcessList: () => dispatch(getProcessList())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(LanderConfig)));

