import React, { useState, useEffect, forwardRef } from 'react';
import './ContainerWithScroll.css'; // Archivo de estilos

const ContainerWithScroll = forwardRef(({ children }, ref) => {
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    console.log("isHovered", isHovered)
  }, [isHovered])

  return (
    <div className="container-with-scroll"
      ref={ref}
      onMouseEnter={() => { setIsHovered(true) }}
      onMouseLeave={() => { setIsHovered(false) }}
    >
      {children}
    </div>
  );
});

ContainerWithScroll.displayName = 'ContainerWithScroll';

export default ContainerWithScroll;