import {
  EVENT_GET_RTB_FILE_LIST,
  REQUEST_RTB_FILE_LIST,
  REQUEST_RTB_FILE_LIST_FAIL,
  REQUEST_RTB_FILE_LIST_SUCCESS,

  EVENT_GET_RTB_LOGS_FILE_LIST,
  REQUEST_RTB_LOGS_FILE_LIST,
  REQUEST_RTB_LOGS_FILE_LIST_FAIL,
  REQUEST_RTB_LOGS_FILE_LIST_SUCCESS,

  EVENT_GET_RTB_LOGS_PUBLISHER,
  REQUEST_RTB_LOGS_PUBLISHER,
  REQUEST_RTB_LOGS_PUBLISHER_FAIL,
  REQUEST_RTB_LOGS_PUBLISHER_SUCCESS,

} from "./actionTypes";

export const getFileList = (payload) => ({ type: EVENT_GET_RTB_FILE_LIST, payload });

export const requestFileList = () => ({ type: REQUEST_RTB_FILE_LIST });
export const requestFileListFail = (payload) => ({ type: REQUEST_RTB_FILE_LIST_FAIL, payload });
export const requestFileListSuccess = (payload) => ({ type: REQUEST_RTB_FILE_LIST_SUCCESS, payload });


export const getLogsFileList = (payload) => ({ type: EVENT_GET_RTB_LOGS_FILE_LIST, payload });

export const requestLogsFileList = () => ({ type: REQUEST_RTB_LOGS_FILE_LIST });
export const requestLogsFileListFail = (payload) => ({ type: REQUEST_RTB_LOGS_FILE_LIST_FAIL, payload });
export const requestLogsFileListSuccess = (payload) => ({ type: REQUEST_RTB_LOGS_FILE_LIST_SUCCESS, payload });


export const getLogsPublisher = (payload) => ({ type: EVENT_GET_RTB_LOGS_PUBLISHER, payload });

export const requestLogsPublisher = () => ({ type: REQUEST_RTB_LOGS_PUBLISHER });
export const requestLogsPublisherFail = (payload) => ({ type: REQUEST_RTB_LOGS_PUBLISHER_FAIL, payload });
export const requestLogsPublisherSuccess = (payload) => ({ type: REQUEST_RTB_LOGS_PUBLISHER_SUCCESS, payload });