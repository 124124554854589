import React, { useEffect, useState, useMemo } from 'react';
import { Chart } from 'react-charts';

const LineChart = ({ chart }) => {

    const data = [
        {
            label: 'Sales Data',
            data: chart
        },
    ]

    const axes = [
        { primary: true, type: 'ordinal', position: 'bottom', show: true },
        { type: 'linear', position: 'left' },
    ]

    return (
        <div
            style={{ width: '90%', height: '80%' }}
        >
            <h2>Weekly</h2>
            <Chart data={data} axes={axes} />
        </div>
    );
};

export default LineChart;
