import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap";

import Table from "../../components/Common/Tabla/BostrapTable";

import { withTranslation } from "react-i18next";

import styled from '@emotion/styled'

import { css } from '@emotion/react'


const SCard = styled(Card)`
${({ selected }) =>
  selected ?
  css`
    color: #10c772;
    outline: none; /* Para quitar el contorno predeterminado del enfoque 
    border: 2px solid green;*/
    box-shadow: 0 0 5px 2px rgba(0, 255, 0, 0.5);
  `: 
  `box-shadow: 0 0 5px 2px rgba(128, 128, 128, 0.2);`
  }
  transition: transform 0.2s;
  &:hover {
  transform: scale(1.05);
  }
  cursor: pointer; /* Cambio del cursor a puntero */
  /* New animation for click */
  transition: transform 0.2s;
  &.click-animation {
  transform: scale(0.95);
  }
`;

const ScreenA = ({ data, onSelected }) => {

  const [dataObj, setDataObj] = useState({})
  const [objectToArray, setObjectToArray] = useState([])
  const [selectedData, setSelectedData] = useState([])

  useEffect(() => {
    if (data) {
      setDataObj(data)
    }
  }, [data])

  useEffect(() => {
    const objectToArray = Object.entries(dataObj);
    setObjectToArray(objectToArray)
    console.log("data", dataObj)
  }, [dataObj])


  useEffect(() => {

    if (selectedData) {

      console.log("dataxx--", selectedData)
    }

  }, [selectedData])


  const checkSelected = (data) => {
    for (const item of selectedData) {
      if (item.title === data) {
        return true;
      }
    }

    return false
  }

  return (
    <>
      {data && <>
        <Row>
          {
            objectToArray?.map(([key, item]) => (
              <Col lg="2" key={key}>
                <SCard selected={(checkSelected(item?.title))} onClick={() => {
                  if (!checkSelected(item?.title)) {
                    const data = selectedData
                    setSelectedData([...data, item])
                    onSelected([...data, item])
                  } else {
                    const newData = selectedData.filter(obj => obj.title !== item?.title);
                    setSelectedData(newData)
                    onSelected(newData)
                  }
                }}>
                  <CardBody>
                    <h4 className="card-title">{item?.title}</h4>
                    <p className="card-title-desc mb-1" style={{ fontSize: 20 }}>
                      {item?.value}
                    </p>
                  </CardBody>
                </SCard>
              </Col>
            ))
          }
        </Row>
      </>
      }
    </>
  );
};

ScreenA.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
};

export default withTranslation()(ScreenA);

// export default withTranslation()(withRouter(ScreenA));
